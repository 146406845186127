const Contact: React.FC = () => {
  return (
    <div style={{ marginBottom: "1em" }}>
      <div className="blog">
        <p>
          Need <strong>help</strong> with something or have a <strong>question</strong> about one of our products?
        </p>
        <p>
          Use the <strong>chat widget</strong> in the <strong>bottom right</strong> corner of the screen or send an
          email to <a href="mailto:support@datastrategypros.com">Customer Support</a>.
        </p>
      </div>
    </div>
  );
};

export default Contact;
