import navStyles from "../navStyles";

import customSelector from "../../../data/customSelector";

const styles: { [key: string]: React.CSSProperties } = {
  navbarMenu: {
    textAlign: "right",
    width: 220,
    top: -13.5,
    right: 98,
    fontSize: "16px",
    padding: "0.2rem 0.2rem",
    lineHeight: 0,
    position: "relative",
    backgroundColor: "white",
    cursor: "pointer",
    borderRadius: "0.25rem 0 0.25rem 0.25rem",
    zIndex: 1000,
  },
};

const ShopBurger = ({ shopBurgerIsActive, setShopBurgerIsActive }) => {
  const titles = customSelector["products"].map((product) => product.title);
  const anchors = customSelector["products"].map((product) => product.anchor);

  return (
    <div style={{ position: "absolute", zIndex: 1000 }} onMouseLeave={() => setShopBurgerIsActive(false)}>
      {shopBurgerIsActive && (
        <div id="nav-item-burger" style={styles.navbarMenu} className="box-shadow">
          {titles.map((title, idx) => {
            return (
              <p
                className="resources-menu-item"
                onClick={() => {
                  window.open(`https://www.datastrategypros.com/products/#${anchors[idx]}`, "_self");
                  setShopBurgerIsActive(false);
                }}
                key={idx}
                style={navStyles.navbarItem}
              >
                {title}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ShopBurger;
