import { useState, useEffect } from "react";
import { Tooltip, useMediaQuery } from "@mui/material";

const USDFlag: React.FC<{ sticky?: boolean }> = ({ sticky = false }) => {
  const hide = useMediaQuery("(max-width: 350px)");

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100 && sticky) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {!hide && (
        <Tooltip
          title={
            <span style={{ fontSize: 13, textWrap: "balance" }}>
              Although we welcome customers worldwide, checkout is only available in USD.
            </span>
          }
          placement="bottom-end"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -12],
                  },
                },
              ],
            },
          }}
        >
          {scrolled ? (
            <div
              style={{
                position: "fixed",
                top: 20,
                right: 20,
                backgroundColor: "#c1c1c1",
                borderRadius: 16,
                padding: "4px 12px",
                zIndex: 1,
                cursor: "pointer",
              }}
            >
              🇺🇸&nbsp; Prices in USD
            </div>
          ) : (
            <div
              style={{
                position: "absolute",
                top: 120,
                right: 20,
                backgroundColor: "#c1c1c1",
                borderRadius: 16,
                padding: "4px 12px",
                zIndex: 1,
                cursor: "pointer",
              }}
            >
              🇺🇸&nbsp; Prices in USD
            </div>
          )}
        </Tooltip>
      )}
    </>
  );
};

export default USDFlag;
