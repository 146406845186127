import { useHistory } from "react-router-dom";

const AIGFCDownloads: React.FC = () => {
  const history = useHistory();

  return (
    <div style={{ display: "flex", justifyContent: "center", maxWidth: 1000, margin: "2em 0", gap: 20 }}>
      <button
        className="btn btn-link"
        style={{ width: 200 }}
        onClick={() => window.open("/landing/aigfc/slides", "_blank")}
      >
        Download Slides
      </button>
      <button
        className="btn btn-link"
        style={{ width: 200 }}
        onClick={() => window.open("/resources?topic=all&series=aigfc", "_blank")}
      >
        Read the Series
      </button>
    </div>
  );
};

export default AIGFCDownloads;
