const DMMAModal = ({ handleShowClick }) => {
  return (
    <div style={{ width: "100vw", height: "100vh", position: "fixed", top: 0, zIndex: 998 }} onClick={handleShowClick}>
      <dialog className="box-shadow" style={{ width: "80vw", height: "80vh" }}>
        <span className="close-icon" onClick={handleShowClick} style={{ right: 10, top: 10 }}>
          x
        </span>
        <object
          width="100%"
          height="100%"
          data="https://d30td5txvfbjf0.cloudfront.net/aigfc.pdf"
          type="application/pdf"
        >
          <p>
            This browser does not support PDFs. Please download the PDF to view it:{" "}
            <a href="https://d30td5txvfbjf0.cloudfront.net/aigfc.pdf">Download PDF</a>.
          </p>
        </object>
      </dialog>
    </div>
  );
};

export default DMMAModal;
