import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import CustomHelmet from "../CustomHelmet";
import ByLine from "./ByLine";
import PostAuthorCard from "./PostAuthorCard";
import RelatedPostsCarousel from "./related-posts-carousel/RelatedPostsCarousel";
import CustomSelector from "../CustomSelector";
import AboutDSP from "./AboutDSP";

import posts from "../../data/blog/posts";
import series from "../../data/blog/series";

const Post: React.FC<{
  postName: postNickname;
  rest: any;
}> = ({ postName, rest }) => {
  const post = posts.find((post) => post.nickname === postName);
  const seriesInfo = series[post.series] ?? null;

  const desktop = useMediaQuery("(min-width:770px)");
  const [value, setValue] = useState("");

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    console.log("POST", post);

    if (location.hash === "") {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (value) {
      history.push(`/resources/${post.series}/${value}`);
    }
  }, [value]);

  return (
    <>
      <CustomHelmet
        description={post.subtitle}
        author={post.author.map((author) => author).join(", ")}
        name={post.title}
        link={post.link}
        img={post.image.previewImage ?? post.image.img}
        alt={post.image.alt}
      />
      <div className="home-layout">
        <div className="blog">
          <div style={{ position: "relative" }}>
            <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
            {desktop && seriesInfo && (
              <CustomSelector pageType="post" value={value} setValue={setValue} sections={seriesInfo} />
            )}
          </div>
          <h3 dangerouslySetInnerHTML={{ __html: post.subtitle }} />
          <ByLine author={post.author} postDate={post.postDate} updateDate={post.updateDate} />
          {rest}
          {post.showAbout && <AboutDSP />}
        </div>
        <PostAuthorCard authors={post.author} />
        <RelatedPostsCarousel postNicknames={post.related} />
      </div>
    </>
  );
};

export default Post;
