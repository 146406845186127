import { bestPractices, colors } from "../../data/blog/bestPractices";

const BestPracticeHeader: React.FC<{ idx: number }> = ({ idx }) => {
  return (
    <>
      <h6 dangerouslySetInnerHTML={{ __html: bestPractices[idx].bestPractice }} />
      <p style={{ marginBottom: 0 }}>
        <em>Key theme</em>:&nbsp;{" "}
        <span
          style={{
            backgroundColor: colors.find((item) => item.topic === bestPractices[idx].topic).color,
            borderRadius: 16,
            padding: "4px 8px",
          }}
        >
          {bestPractices[idx].topic}
        </span>
      </p>
    </>
  );
};

export default BestPracticeHeader;
