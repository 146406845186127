import aiSystemLifecycle from "../../img/blog/aigfc/ai-system-lifecycle.webp";

const AISystemLifecycle: React.FC<{ figureNumber: string }> = ({ figureNumber }) => {
  return (
    <figure>
      <img
        src={aiSystemLifecycle}
        alt="Diagram of the AI System Lifecycle, showing phases: Design, Verify, Deploy, and Operate"
        onClick={() =>
          window.open(
            "https://www.oecd.org/content/dam/oecd/en/publications/reports/2019/06/artificial-intelligence-in-society_c0054fa1/eedfee77-en.pdf",
            "_blank"
          )
        }
        style={{ cursor: "pointer" }}
      />
      <figcaption>
        Figure {figureNumber} – "AI system lifecycle" from "
        <a
          href="https://www.oecd.org/content/dam/oecd/en/publications/reports/2019/06/artificial-intelligence-in-society_c0054fa1/eedfee77-en.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Artificial Intelligence in Society
        </a>
        ,"{" "}
        <a href="https://www.oecd.org/" target="_blank" rel="noreferrer">
          OECD
        </a>
      </figcaption>
    </figure>
  );
};

export default AISystemLifecycle;
