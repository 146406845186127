import { Link } from "react-router-dom";

import Post from "../../components/blog/Post";
import PostImage from "../../components/blog/PostImage";
import AIGFCDownloads from "./components/AIGFCDownloads";

const POSTNAME: postNickname = "aigfc-methodology";

const AIGFCMethodology: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default AIGFCMethodology;

const Rest = () => {
  const contents = [
    { anchor: "limitations", text: "Limitations" },
    { anchor: "wider-research", text: "Wider Research" },
    { anchor: "framework-assessments", text: "Framework Assessments" },
    { anchor: "framework-investigation-methodology", text: "Framework Investigation Methodology" },
    { anchor: "framework-profiles", text: "Framework Profiles" },
    { anchor: "introduction-and-content-summary", text: "Introduction and Content Summary" },
    { anchor: "best-practice-highlights", text: "Best Practice Highlights" },
    { anchor: "assessments-of-value-potential", text: "Assessments of Value Potential" },
    { anchor: "assessment-methodology", text: "Assessment Methodology" },
    { anchor: "assessment-categories", text: "Assessment Categories" },
    { anchor: "assessment-criteria", text: "Assessment Criteria" },
    { anchor: "overall-assessments", text: "Overall Assessments" },
    { anchor: "ai-assistant-disclaimer", text: "AI Assistant Disclaimer" },
    { anchor: "conclusion", text: "Conclusion" },
    { anchor: "related-posts", text: "Related Posts" },
  ];

  return (
    <div className="blog">
      <p>
        This post details the research approach, assessment criteria, and analytical methods for the{" "}
        <Link to="/resources?topic=all&series=aigfc" target="_blank">
          AIGFC series
        </Link>
        . It aims to transparently explain our methodology and the limitations identified with it.
      </p>
      <p>
        In particular, this post outlines what framework profiles aimed to achieve and how frameworks were assessed
        using standardized criteria. Documenting these processes provides critical context for interpreting the
        conclusions drawn in this series.
      </p>
      <AIGFCDownloads />
      <PostImage postName={POSTNAME} />
      <h4>Contents</h4>
      <ul>
        <li>
          <a href={"#" + contents[0].anchor}>{contents[0].text}</a>
          {contents.slice(1, 3).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>
        <li>
          <a href={"#" + contents[3].anchor}>{contents[3].text}</a>
          <li>
            <a href={"#" + contents[4].anchor}>{contents[4].text}</a>
            {contents.slice(5, 8).map((content) => (
              <li>
                <a href={"#" + content.anchor}>{content.text}</a>
              </li>
            ))}
          </li>
          <li>
            <a href={"#" + contents[8].anchor}>{contents[8].text}</a>
            {contents.slice(9, 12).map((content) => (
              <li>
                <a href={"#" + content.anchor}>{content.text}</a>
              </li>
            ))}
          </li>
        </li>
        <li>
          <a href={"#" + contents[12].anchor}>{contents[12].text}</a>
        </li>
        <li>
          <a href={"#" + contents[13].anchor}>{contents[13].text}</a>
        </li>
        <li>
          <a href={"#" + contents[14].anchor}>{contents[14].text}</a>
        </li>
      </ul>

      <h2 id={contents[0].anchor}>{contents[0].text}</h2>
      <p>We aim to be as transparent as possible with our reasoning and processes.</p>

      <h4 id={contents[1].anchor}>{contents[1].text}</h4>
      <ul>
        <li>
          <strong>Finding sources</strong>:&nbsp; most sources were found through either a keyword search or other
          sources; while many high-quality sources were found, the AI Governance field remains disparate, so many
          valuable resources, including surveys and models, are likely not accounted for in this report
        </li>
        <li>
          <strong>Quality of evidence analyzed</strong>:&nbsp; most evidence for AI and AI Governance adoption trends
          among businesses comes from survey data, which is useful for understanding how businesses view AI and AI
          Governance. However, due to mistaken or dishonest responses, it may fail to capture reliable information about
          what businesses are doing with AI and AI Governance. In contrast, evidence that more directly gauges what
          businesses are doing, such as publicly released AI Governance Frameworks and independent audits, currently has
          limited availability.{" "}
        </li>
        <li>
          <strong>Accounting for source dates</strong>:&nbsp; some effort was made to discuss survey results within the
          context of their release date, but we did so inconsistently across analyses. For example, the key barriers to
          AI adoption section doesn't explicitly consider survey dates when making inferences about the most relevant
          barriers. Furthermore, there's a lack of historical data for many subjects, which limits what can be said
          about how they've changed or where they're trending.
        </li>
        <li>
          <strong>Accounting for variance in source methodology</strong>:&nbsp; when multiple surveys would be discussed
          as supporting data points for some argument, e.g., what the key barriers to AI adoption are, the degree of
          variance in sample size, audience, question-wording, and other survey features were often not thoroughly
          accounted for in inferences made from each source. This issue risks misinterpreting results and making claims
          not supported by the evidence.
        </li>
      </ul>

      <h4 id={contents[2].anchor}>{contents[2].text}</h4>
      <ul>
        <li>
          <strong>Framework comparability</strong>:&nbsp; there is high variance in the subject matter, format, and
          intended use among the frameworks investigated; a standardized assessment method was applied, which attempted
          to account for different broad characteristics of each framework, but comparisons between frameworks can only
          be reasonably limited to what they offer on risk mitigation and Data Management
        </li>
        <li>
          <strong>Assessment categories and benchmarks</strong>:&nbsp; the two categories identified don't represent the
          full extent of AI Governance assessment categories. For example, operational tasks and guidance on
          implementing a framework are essential to AI Governance but couldn't easily be assessed. However, they are
          each important to AI Governance and our audience and could be assessed using reputable taxonomies of key
          practices as benchmarks for classifying guidance. These taxonomies are not currently used as standard
          benchmarks for classifying guidance (see more on assessment validity below).
        </li>
        <li>
          <strong>Assessments focus on describing framework content</strong>:&nbsp; we were able to assess the type and
          amount of guidance offered in frameworks but not its quality; our assessments do not evaluate the accuracy or
          effectiveness of a framework's guidance and cannot anticipate what guidance most aligns with a business's
          objectives
        </li>
        <li>
          <strong>Depth of investigations</strong>:&nbsp; frameworks' breadth, depth, and practicality could only be
          assessed at a moderate depth of investigation for each framework; as such, there will likely be a substantial
          amount of guidance we haven't factored into our assessments
        </li>
        <li>
          <strong>Validity of assessment methodology</strong>:&nbsp; the assessment methodology used in this report,
          from the assessment categories it assessed to the criteria it used to assess guidance, was novel; no testing
          or expert consultation on its development occurred before it was used
        </li>
        <li>
          <strong>Reliability of assessments</strong>:&nbsp; assessments were often made in ad-hoc steps, such as how
          deeply each framework was investigated, how many times a framework was revisited, when and what type of notes
          were taken, etc., which did not control for a consistent treatment of each framework; furthermore, each
          criterion was subjectively assessed by a non-expert, meaning that assessments may not effectively represent a
          framework's information value or diverge significantly from how others assess each framework
        </li>
        <li>
          <strong>Public release of practice-based frameworks</strong>:&nbsp; very few large businesses, including those
          whose currently released framework guidance we assessed, have publicly released information on their AI
          Governance frameworks; this situation means there's a limited pool of resources to draw insights
        </li>
      </ul>

      <h2 id={contents[3].anchor}>{contents[3].text}</h2>
      <h4 id={contents[4].anchor}>{contents[4].text}</h4>
      <h6 id={contents[5].anchor}>{contents[5].text}</h6>
      <p>
        While the Introduction provides basic background details on a framework, the Content Summary explains its key
        contents. The key details in the Introduction include who created the framework and when, its aims, who its
        target audience is, and what plans for its future development are. Content Summaries focused on outlining the
        key content categories and the type of information available on each, e.g., actions, definitions, etc.
      </p>

      <h6 id={contents[6].anchor}>{contents[6].text}</h6>
      <p>
        Highlights aim to make useful guidance from each framework highly accessible to businesses and indicate the
        quality and type of additional guidance a framework may offer. We exclusively identified risk mitigation and
        Data Management highlights in alignment with our framework assessment methodology (outlined below). The final
        examples of guidance highlighted are directly taken from each source with minimal editing.
      </p>

      <h6 id={contents[7].anchor}>{contents[7].text}</h6>
      <p>
        In addition to describing specific details for each framework, we assessed several general qualities of its
        content to indicate how potentially valuable it could be for businesses to investigate it further. Assessments
        involved subjective ratings for the breadth, depth, and practicality of a framework's guidance on risk
        mitigation and Data Management during the deployment or operation phase of the AI lifecycle.
      </p>
      <p>
        We used the AI Claude to help assess the breadth criterion. Specifically, we used Claude to parse each framework
        and identify any substantive guidance for each assessment subcategory. It used the descriptions of and
        explanations for each subcategory as outlined in{" "}
        <Link to="/resources/aigfc/frameworks#nist-framework" target="_blank">
          NIST's AI RMF
        </Link>{" "}
        and DAMA's{" "}
        <em>
          <a href="http://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            DMBOK
          </a>
        </em>{" "}
        for risk mitigation and Data Management guidance, respectively. For each suggestion, Claude provided page
        numbers for the referenced content and a brief explanation of what makes its suggested guidance qualify for a
        given subcategory. The researcher ultimately determined whether each piece of guidance qualified for a
        particular subcategory or not.
      </p>
      <p>
        In addition to these quantitative assessments, we offered high-level guidance on the value potential of
        supplementary resources and what each framework was best for. Many supplementary resources meaningfully increase
        a core framework's overall value potential
      </p>
      <h4 id={contents[8].anchor}>{contents[8].text}</h4>

      <h6 id={contents[9].anchor}>{contents[9].text}</h6>
      <p>
        From among the types of practices used in AI Governance identified, only risk mitigation and Data Management
        could be effectively operationalized using reputable taxonomies of practices for each category as benchmarks for
        assessments.
      </p>

      <strong>Risk Mitigation</strong>
      <p>
        This assessment category identifies measures explicitly to mitigate risks using the seven subcategories of AI
        trustworthiness and risk identified in{" "}
        <Link to="/resources/aigfc/frameworks#nist-framework" target="_blank">
          NIST's AI RMF
        </Link>{" "}
        as a benchmark.
      </p>
      <p>Subcategories:</p>
      <ul>
        <li>Valid and reliable</li>
        <li>Safe</li>
        <li>Secure and resilient</li>
        <li>Accountable and transparent</li>
        <li>Explainable and interpretable</li>
        <li>Privacy-enhanced</li>
        <li>Fair – with harmful bias managed</li>
      </ul>

      <strong>Data Management</strong>
      <p>
        This assessment category identifies guidance on data practices using the eleven subcategories of Data Management
        classified in DAMA's{" "}
        <em>
          <a href="http://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            DMBOK
          </a>
        </em>{" "}
        were used as a benchmark.
      </p>
      <p>Subcategories:</p>
      <ul>
        <li>Data Governance</li>
        <li>Data Warehousing & Business Intelligence</li>
        <li>Reference & Master Data</li>
        <li>Document & Content Management</li>
        <li>Data Integration & Interoperability</li>
        <li>Data Security</li>
        <li>Data Storage & Operations</li>
        <li>Data Modeling & Design</li>
        <li>Data Architecture</li>
        <li>Data Quality</li>
        <li>Metadata</li>
      </ul>
      <h6 id={contents[10].anchor}>{contents[10].text}</h6>

      <strong>Breadth</strong>
      <ul>
        <li>
          <strong>Definition</strong>:&nbsp; rough indication of the range of risk mitigation and Data Management
          practices that a framework offers guidance on
        </li>
        <li>
          <strong>Operationalization</strong>:&nbsp; the total number of assessment subcategories for which a framework
          includes at least one piece of substantive guidance, i.e., more than the mentioned risk. As the number of
          subcategories per assessment category is not 10, to be consistent with how thoroughness and practicality are
          also rated, ratings are normalized to be a score out of 10. However, should a framework offer no relevant
          guidance, a score of 0 will be assigned, which would then be reflected in in-depth and practicality ratings.{" "}
        </li>
      </ul>

      <strong>Depth</strong>
      <ul>
        <li>
          <strong>Definition</strong>:&nbsp; the specificity and volume of detail of a framework's guidancee.g., defined
          terminology and thorough explanations
        </li>
        <li>
          <strong>Operationalization</strong>:&nbsp; level of detailed guidance on risk mitigation and Data Management
          ranges from <em>very low (1-2)</em>
          <strong> </strong>to <em>very high (9-10)</em>. A 1 would offer no useful information, and 10 would provide
          all the details a business would need to understand best practices. A rating of 0 is possible if and only if
          breadth is rated as a 0. The researcher subjectively applies this rating and briefly shares the reasoning
          behind the conclusion.
        </li>
      </ul>

      <strong>Practicality</strong>
      <ul>
        <li>
          <strong>Definition</strong>:&nbsp; the degree to which a framework's guidance is explicit on how to conduct AI
          Governance in practice, e.g., specific step-by-step procedures
        </li>

        <li>
          <strong>Operationalization</strong>:&nbsp; overall practicality of risk mitigation and Data Management
          guidance ranges <em>from very low (1-2)</em> to <em>very high (9-10)</em>. A 1 offers no guidance that
          businesses could put into practice as is, while a 10 thoroughly lays out the governance actions businesses
          need to take and how to take them. A rating of 0 is possible if and only if breadth is rated as a 0. The
          researcher subjectively applies this rating and briefly shares the reasoning behind the conclusion.
        </li>
      </ul>

      <h6 id={contents[11].anchor}>{contents[11].text}</h6>

      <strong>Value Assessment Ratings</strong>

      <p>
        Most importantly, because the number of subcategories of risk mitigation and Data Management used to measure
        breadth aren't 10 each, whereas depth and practicality are subjectively rated out of 10, the raw rating for
        breadth is normalized to become a score out of 10.
      </p>
      <p>
        Once the breadth rating has been normalized, the mean of all three ratings is taken as the overall assessment
        rating for each assessment category.
      </p>

      <strong>Best For</strong>
      <p>
        Acknowledges the specific use case(s) the framework best suits. This indicator is included to specify the more
        nuanced value proposition of each framework outside of our standardized assessments. It's possible for the
        specific aims and strengths of a given framework to align with a given business's AI Governance needs despite it
        being rated poorly in our assessments.
      </p>

      <strong>Value of Supplementary Resources</strong>
      <p>
        This rating of low, moderate, or high roughly indicates how much value supplementary resources are estimated to
        add to the core framework assessed in full. However, the rating may be weighted heavily on a single, especially
        high-value resource, such as for{" "}
        <Link to="/resources/aigfc/frameworks#nist-framework" target="_blank">
          NIST
        </Link>{" "}
        and{" "}
        <Link to="/resources/aigfc/frameworks#aws-framework" target="_blank">
          AWS
        </Link>{" "}
        frameworks.
      </p>
      <p>
        Only shallow research was done into supplementary resources, including a review of their table of contents, when
        available, and skim-reading a selection of relevant sections.
      </p>

      <h2 id={contents[12].anchor}>{contents[12].text}</h2>
      <p>
        AI was used to help produce this document throughout its development. Below is a brief list of specific ways in
        which it was used and the measures we took to maximize its reliability:&nbsp;
      </p>
      <ul>
        <li>
          <strong>During research to find sources</strong>:&nbsp; requiring that sources be hyperlinked or URLs provided
          before reviewing the validity of all sources used
        </li>
        <li>
          <strong>Writing</strong>:&nbsp; reviewing and editing written outputs for accuracy and quality of writing
        </li>
        <li>
          <strong>Feedback on human-produced writing and ideation</strong>:&nbsp; requiring high reasoning transparency
          for how it came to its conclusions
        </li>
        <li>
          <strong>Finding key details in AI Governance frameworks</strong>:&nbsp; requiring the page numbers for facts
          extracted from documents then verifying the accuracy of facts
        </li>
        <li>
          <strong>Retroactively organizing endnotes</strong>:&nbsp; reviewing and editing endnote details and updating
          endnotes based on a review of all sources used per section
        </li>
      </ul>

      <h2 id={contents[13].anchor}>{contents[13].text}</h2>
      <p>
        This post concludes the{" "}
        <Link to="/resources?topic=all&series=aigfc" target="_blank">
          AIGFC series
        </Link>
        . The series covers the need for AI Governance among businesses adopting AI before assessing seven frameworks on
        key criteria for effective AI Governance. The research methodology used provides both targeted insights on the
        value potential of leading AI Governance frameworks for data practitioners and C-level executives and an
        approach for efficiently researching key insights about future AI Governance frameworks.
      </p>
      <p>
        Businesses seeking to implement AI Governance frameworks should consider this project's findings as informative
        rather than prescriptive, using them as one input among many when determining which approaches best align with
        their specific needs.
      </p>
    </div>
  );
};
