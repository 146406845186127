import { Link } from "react-router-dom";
import { Table, TableContainer, TableRow, TableCell } from "@mui/material";

import Post from "../../components/blog/Post";
import PostImage from "../../components/blog/PostImage";
import AIGFCDownloads from "./components/AIGFCDownloads";
import ExtendedDescription from "../../components/ExtendedDescription";
import EndnoteSource from "../../components/blog/EndnoteSource";
import { AssessmentTable, AssessmentComparisonTable, TableHeader } from "./components/frameworks/AIGFCTables";

import turing from "../../img/blog/aigfc/assessments/turing.webp";
import microsoft from "../../img/blog/aigfc/assessments/microsoft.webp";
import aws from "../../img/blog/aigfc/assessments/aws.webp";
import google from "../../img/blog/aigfc/assessments/google.webp";
import oecd from "../../img/blog/aigfc/assessments/oecd.webp";
import nist from "../../img/blog/aigfc/assessments/nist.webp";
import singapore from "../../img/blog/aigfc/assessments/singapore.webp";

import tableStyles from "../../components/tableStyles";

import aigfcSources from "../../data/blog/aigfcSources";

const POSTNAME: postNickname = "aigfc-frameworks";

const AIGFCFrameworks: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default AIGFCFrameworks;

const Rest = () => {
  const contents = [
    { anchor: "overview", text: "Overview" },
    { anchor: "selection", text: "Framework Selection Methodology" },
    { anchor: "principle-based-frameworks", text: "Principle-based Frameworks" },
    { anchor: "singapore-framework", text: "Singapore's Model AI Governance Framework" },
    { anchor: "oecd-framework", text: "OECD Framework for the Classification of AI Systems" },
    { anchor: "nist-framework", text: "NIST AI Risk Management Framework" },
    { anchor: "huderaf-framework", text: "The Council of Europe's HUDERAF for AI Systems" },
    { anchor: "vendor-based-frameworks", text: "Vendor-based Frameworks" },
    { anchor: "microsoft-framework", text: "Microsoft's Responsible AI Standards" },
    { anchor: "aws-framework", text: "AWS Cloud Adoption Framework for AI, ML, and Gen AI" },
    { anchor: "google-framework", text: "Google's Secure AI Framework Approach" },
    { anchor: "key-takeaways", text: "Key Takeaways" },
    { anchor: "discussion", text: "Discussion" },
    { anchor: "conclusion", text: "Conclusion" },
    { anchor: "related-posts", text: "Related Posts" },
  ];

  return (
    <div className="blog">
      <p>
        Navigating the complex landscape of AI Governance frameworks has become essential for businesses seeking to
        deploy AI responsibly. As organizations increasingly integrate AI into their operations, the need for structured
        guidance on managing AI risks and ensuring responsible development has never been more pressing. Yet the
        proliferation of AI Governance frameworks from various sectors presents a challenge:&nbsp; which frameworks
        provide the most value for specific organizational needs?
      </p>
      <p>
        The AI Governance discipline presents frameworks from diverse sources – public sector institutions, technology
        vendors, and industry consortia – each with different strengths and focuses. According to a 2023 IAPP and EY{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://iapp.org/media/pdf/resource_center/iapp_ey_professionalizing_organizational_ai_governance_report.pdf"
        >
          survey
        </a>
        , while NIST's AI Risk Management Framework is utilized by 50% of North American businesses, many organizations
        in Asia and Europe either develop internal frameworks (57% in Asia) or haven't adopted any formal framework at
        all (57% in Europe).<sup>6</sup> This variation underscores both the evolving nature of AI Governance and the
        need for businesses to make informed choices about which frameworks best align with their specific requirements.
      </p>

      <PostImage postName={POSTNAME} />

      <p>
        This post provides a comprehensive assessment of seven leading AI Governance frameworks, analyzing their
        relative strengths in risk mitigation and Data Management. Research indicates that effective risk mitigation and
        Data Management are among the highest priorities for AI Governance. By evaluating the breadth, depth, and
        practicality of each framework's guidance, businesses can better identify which frameworks might be of highest
        value for them given their particular needs.
      </p>

      <AIGFCDownloads />

      <h4>Contents</h4>
      <ul>
        <li>
          <a href={"#" + contents[0].anchor}>{contents[0].text}</a>
          <li>
            <a href={"#" + contents[1].anchor}>{contents[1].text}</a>
          </li>
        </li>
        <li>
          <a href={"#" + contents[2].anchor}>{contents[2].text}</a>
          {contents.slice(3, 7).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>
        <li>
          <a href={"#" + contents[7].anchor}>{contents[7].text}</a>
          {contents.slice(8, 11).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>
        <li>
          <a href={"#" + contents[11].anchor}>{contents[11].text}</a>
          <li>
            <a href={"#" + contents[12].anchor}>{contents[12].text}</a>
          </li>
        </li>
        <li>
          <a href={"#" + contents[13].anchor}>{contents[13].text}</a>
        </li>
      </ul>

      <h2 id={contents[0].anchor}>{contents[0].text}</h2>
      <p>
        AI Governance is defined in this post as the set of policies and mechanisms that help ensure AI systems remain
        ethical and safe throughout the AI lifecycle. To establish the AI Governance expertise needed for effective AI
        system deployments, following AI Governance framework can be very helpful.
      </p>
      <p>
        Several prominent public and private sector institutions have produced potentially useful – if often complicated
        and densely written – AI Governance frameworks. Given that the field lacks agreed-upon standards, these
        frameworks vary in terms of their content and format.
      </p>
      <p>
        Profiles for each framework outline key insights about a framework's basic details and potential value to
        businesses. The goal of these framework profiles is to help data practitioners and C-level executives broadly
        understand what each framework offers them.
      </p>
      <p>
        First, the profiles provide clear, concise descriptions of each framework, including its focus, aims, main
        ideas, and specific examples of guidance it offers. Second, the profiles include basic assessments of a
        framework's breadth, depth, and practicality across risk mitigation and Data Management practices. These
        assessments partly take supplementary resources for each framework into account.
      </p>

      <h4 id={contents[1].anchor}>{contents[1].text}</h4>

      <p>
        We assessed seven leading frameworks:&nbsp; four public sector frameworks and three vendor-based frameworks. We
        selected the four public sector frameworks from IAPP-FTI Consulting's{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://iapp.org/media/pdf/resource_center/ai_governance_in_practice_report_2024.pdf"
        >
          2024 report
        </a>
        .<sup>1</sup> We investigated AI Governance frameworks from the three leading cloud service vendors:&nbsp;
        Microsoft, AWS, and Google. The private sector frameworks offer insight into recommended best practices to
        navigate the novel challenges of AI Governance.
      </p>

      <h2 id={contents[2].anchor}>{contents[2].text}</h2>

      <h4 id="oecd-framework">OECD Framework for the Classification of AI Systems</h4>
      <figure>
        <img src={oecd} alt="oecd framework" />
      </figure>

      <h6>Introduction</h6>
      <p>
        The Organisation for Economic Co-operation and Development's (OECD) AI in Work, Innovation, Productivity, and
        Skills (AI-WIPS) program created the{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.oecd.org/en/publications/oecd-framework-for-the-classification-of-ai-systems_cb6d9eca-en.html"
        >
          OECD Framework for the Classification of AI systems
        </a>{" "}
        with the support of over 60 experts in their Experts Working Group. The framework was released in February 2022.
        <sup>8</sup>
      </p>
      <p>
        This framework offers high-level guidance about which dimensions AI will most impact, which risks threaten each
        dimension, and how risks can start to be approached responsibly, as outlined in the content summary below.
        Definitions, concepts, and criteria are designed to be adaptable to future classifications of AI. Although the
        framework is primarily targeted at policymakers, regulators, and legislators, businesses can use the framework
        to understand what systems need to be governed, what risks AI presents, and how businesses might start
        mitigating these risks.
      </p>
      <p>
        The Experts Working Group's next steps produce an actionable AI system risk methodology, including refined
        classification criteria based on actual AI systems, metrics to assess subjective AI harm-related criteria, and a
        common AI incident reporting framework.
      </p>
      <h6>Stated Aims</h6>
      <ul>
        <li>Promote a common understanding of AI</li>
        <li>Inform registries or inventories</li>
        <li>Support sector-specific frameworks</li>
        <li>Support risk assessment</li>
        <li>Support risk management</li>
      </ul>

      <h6>Content Summary</h6>
      <p>
        The "OECD Framework for the Classification of AI systems" is a comprehensive framework that helps assess AI
        systems. Specifically, the framework links AI system characteristics with the{" "}
        <a target="_blank" rel="noreferrer" href="https://oecd.ai/en/ai-principles">
          OECD's AI Principles
        </a>{" "}
        and outlines case studies for how they might be implemented for each dimension.<sup>9</sup>
      </p>
      <p>
        The framework primarily consists of two sections. The first explains the dimensions and characteristics used for
        assessment, while the second provides several case studies on how assessments work in practice.
      </p>
      <p>
        The framework classifies AI systems using five dimensions:&nbsp; People & Planet, Economic Context, Data &
        Input, AI Model, and Task & Output. Core characteristics are identified and explained for each dimension, with
        notes about why each characteristic matters and how it relates to the OECD's AI Principles. Particular attention
        is paid to the policy implications of each characteristic, though many are also relevant to how businesses
        deploy and operate AI.
      </p>
      <p>
        These characteristics are then used to classify the application of AI systems in specific, real-world contexts.
        Various organizations were invited to test the framework's usability and robustness through an online survey and
        public consultations. Key conclusions from the survey include that the framework is best suited to specific AI
        system applications over generic AI systems and that respondents were more consistent at classifying People &
        Planet and Economic Context criteria than other dimensions.
      </p>

      <h6>Best Practice Highlights</h6>
      <div className="table">
        <TableContainer style={tableStyles.tableContainer}>
          <Table size="small">
            <TableHeader
              tableNumber="4.1"
              tableTitle="Best Practices"
              framework="OECD Framework for the Classification of AI Systems"
              subtitle="Risk Management"
            />
            <TableRow>
              <TableCell>
                <strong>Identifiability of personal data </strong>(Privacy-enhanced, p. 38-39)
                <p>
                  Personal data taxonomies differentiate between different categories of personal data. ISO/IEC 19441
                  (2017) distinguishes five categories, or "states," of data identifiability:
                </p>
                <ul>
                  <li>
                    Identified data:&nbsp; data that can be unambiguously associated with a specific person because they
                    contain personally identifiable information
                  </li>
                  <li>
                    Pseudonymized data:&nbsp; data for which aliases substitute all personal identifiers. The alias
                    assignment is such that it cannot be reversed by reasonable efforts except for the party that
                    performed the assignment
                  </li>
                  <li>
                    Unlinked pseudonymized data:&nbsp; data for which aliases irreversibly erase or substitute all
                    personal identifiers; linkage cannot be re-established by reasonable efforts, including by the party
                    that performed the assignment
                  </li>
                  <li>
                    Anonymized data:&nbsp; data that are not linked to attributes that can be altered (i.e., attributes'
                    values are randomized or generalized) in such a way that there is a reasonable level of confidence
                    that a person cannot be identified, directly or indirectly, by the data alone or in combination with
                    other data
                  </li>
                  <li>
                    Aggregated data:&nbsp; statistical data that do not contain individual-level entries and are
                    combined with information about enough different persons that individual-level attributes are not
                    identifiable
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Model transparency and explainability</strong> (Accountability and Transparency, Explainable and
                Interpretable, p. 49)
                <p>
                  Different AI models can exhibit different degrees of transparency and explainability. Among other
                  things, this entails determining whether meaningful and easy-to-understand information is made
                  available to:
                </p>
                <ul>
                  <li>Make stakeholders aware of their interactions with AI systems, including in the workplace</li>
                  <li>
                    Enable those affected by an AI system to understand and challenge the outcome and how it was
                    produced by the AI model (e.g., by setting the weights of the AI model's components)
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow style={{ ...tableStyles.centerText, ...tableStyles.lightTealBackground }}>
              <TableCell>
                <strong>Data Management</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Data Quality and appropriateness</strong> (Data Quality, p. 39)
                <p>
                  Data appropriateness (or qualification) involves defining criteria to ensure that the data are
                  appropriate for use in a project, fit for purpose, and relevant to the system or process following
                  standard practice in the industry sector. For example, in clinical trials to evaluate drug efficiency,
                  criteria for using patient data must include patients' clinical history – previous treatments,
                  surgery, etc. Data quality also plays a key role in AI systems, as do the standards and procedures to
                  manage data quality and appropriateness.
                </p>
                <p>An AI application or system applies standard criteria or industry-defined criteria to assess:</p>
                <ul>
                  <li>
                    Data appropriateness:&nbsp; data are appropriate for the purpose for which they are to be used,
                    following standard practice in the industry sector
                  </li>
                  <li>
                    Sample representativeness:&nbsp; selected variables and training or evaluation data accurately
                    depict/reflect the population in the AI system environment
                  </li>
                  <li>
                    Adequate sample size:&nbsp; sample size displays an appropriate level of granularity, coverage, and
                    sufficiency of data
                  </li>
                  <li>
                    Completeness and coherence of sample:&nbsp; sample is complete, with minimal missing or partial
                    values; outliers must not affect the quality of data
                  </li>
                  <li>
                    Low data "noise":&nbsp; data is infrequently incorrect, corrupted, or distorted (e.g., intentional
                    or unintentional mistakes in survey data, data from defective sensors)
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Provenance of data and input</strong> (Metadata, Data Quality, p. 36)
                <p>
                  The following list draws on the data provenance categorization made by Abrams (Abrams, 2014[16]) and
                  the OECD (OECD, 2019[15]) of data collected with decreasing levels of awareness. It should be noted
                  that these categories can overlap, and most systems will combine data from different sources. Here, we
                  broaden the original categorization that focused on personal data to also cover expert input and
                  non-personal data, as well as synthetically generated data.
                </p>
                <ul>
                  <li>
                    Expert input:&nbsp; human knowledge that is codified into rules and structures such as ontologies
                    (concepts and properties), knowledge graphs, and analytical functions (e.g., the objective function
                    or rewards an AI model will optimize for)
                  </li>
                  <li>
                    Provided data:&nbsp; data that originate from actions by individuals or organizations that are aware
                    of the data being provided; they include "initiated" (e.g., a license application), "transactional"
                    (e.g., bills paid), and "posted" (e.g., social networking posts) data
                  </li>
                  <li>
                    Observed data:&nbsp; collected through human observation of a behavior or activity or using
                    automated instruments or sensors; examples include website visitor provenance and browsing patterns
                    observed by a website administrator; observed data include sounds, scents, temperature, GPS
                    position, and soil acidity; observed data about individuals can be "engaged" (e.g., voluntarily
                    accepting cookie tracking on a website), "unanticipated" (e.g., the tracking of seconds spent
                    looking at a specific image online), or "passive" (e.g., CCTV images of individuals)
                  </li>
                  <li>
                    Synthetic data:&nbsp; usually generated by computer simulations, including data collected through
                    reinforcement learning; synthetic data allow for the simulation of scenarios that are difficult to
                    observe or replicate in real life (e.g., a car accident) or are otherwise too expensive to collect
                    at scale (e.g., millions of miles of driving time for self-driving cars); they include most
                    applications of physical modeling, such as music synthesizers or flight simulators; AI system output
                    of synthetic data approximates reality but is generated algorithmically
                  </li>
                  <li>
                    Derived data:&nbsp; data taken from other data to become a new data element; derived data include
                    computational (e.g., a credit score) and categorical data (e.g., age group of a buyer); they can be
                    inferred (e.g., the product of a probability-based analytic process such as a fraud score or risk of
                    accident) or aggregated (e.g., abstracted from more fine-grained data); proprietary data are often
                    characterized as derived data
                  </li>
                </ul>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </div>

      <h6>Assessment Results</h6>
      <AssessmentTable framework="OECD Framework for the Classification of AI Systems" tableNumber="4.2" />
      <AssessmentTable framework="OECD Framework for the Classification of AI Systems" tableNumber="4.3" />

      <h6>Supplementary Resources</h6>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.oecd-ilibrary.org/science-and-technology/ai-data-governance-and-privacy_2476b1a4-en"
          >
            AI, data governance and privacy
          </a>
          :&nbsp; primarily useful for Data Privacy and privacy risks considerations<sup>10</sup>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.oecd-ilibrary.org/docserver/008232ec-en.pdf?expires=1730238317&id=id&accname=guest&checksum=BEB1A6DA71952C260493D2FE17BB1F81"
          >
            Tools for Trustworthy AI
          </a>
          :&nbsp; broadly useful for AI Governance implementation, especially towards trustworthy AI<sup>7</sup>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.oecd-ilibrary.org/docserver/73d417f9-en.pdf?expires=1730249315&id=id&accname=guest&checksum=66B72F897B14DED44B94D090B6F1E05D"
          >
            Using AI in the Workplace
          </a>
          :&nbsp; identifies the risks of using AI in the workplace and how they might be addressed<sup>11</sup>
        </li>
      </ul>
      <br />
      <h4 id="nist-framework">NIST AI Risk Management Framework</h4>
      <figure>
        <img src={nist} alt="nist framework" />
      </figure>

      <h6>Introduction</h6>
      <p>
        The US National Institute of Standards and Technology (NIST) produced the AI Risk Management Framework (
        <a target="_blank" rel="noreferrer" href="https://nvlpubs.nist.gov/nistpubs/ai/NIST.AI.100-1.pdf">
          AI RMF
        </a>
        ) in collaboration with the public and private sector, releasing the framework in January 2023.<sup>3</sup>
      </p>
      <p>
        The framework defines the types of risks that AI presents and proposes four functions that enable dialogue,
        understanding, and activities to manage AI risks and responsibly develop trustworthy AI systems. NIST
        deliberately designed it to be non-sector-specific and use-case agnostic so that all organizations and
        individuals – referred to as "AI actors" – who play an active role in the AI system lifecycle can benefit from
        its guidance.
      </p>
      <p>
        NIST will continue to update, expand, and improve the framework based on evolving technology, the global
        standards landscape, and AI community experience and feedback. For example, evaluations for the AI RMF's
        effectiveness in conjunction with the AI community, such as on ways to better measure bottom-line improvements
        in the trustworthiness of AI systems, will be a priority. NIST plans to conduct a formal review no later than
        2028.
      </p>

      <h6>Stated Aims</h6>
      <ul>
        <li>Equip organizations and individuals with approaches that increase the trustworthiness of AI systems</li>
        <li>Help foster the responsible design, development, deployment, and use of AI systems over time</li>
        <li>Be voluntary, rights-preserving, non-sector-specific, and use-case agnostic</li>
        <li>Be operationalized by organizations in varying degrees and capacities</li>
        <li>Address new risks as they emerge</li>
      </ul>

      <h6>Content Summary</h6>
      <p>
        The "NIST AI Risk Management Framework (AI RMF)" consists of two parts. Together, they offer a comprehensive and
        action-oriented introduction to mitigating AI risks.
      </p>
      <p>
        Part 1 provides foundational information about AI risks, trustworthiness characteristics, and framework context.
        The framework outlines trustworthiness properties, including AI that is valid and reliable, safe, secure and
        resilient, accountable and transparent, explainable and interpretable, privacy-enhanced, and fair with harmful
        bias managed.
      </p>
      <p>
        Part 2 details the core functions and profiles for implementing risk management practices. Four core functions
        form the basis of AI risk management activities:
      </p>
      <ul>
        <li>
          <strong>GOVERN</strong>:&nbsp; cultivates and implements risk management culture and policies
        </li>
        <li>
          <strong>MAP</strong>:&nbsp; establishes context and frames AI system risks
        </li>
        <li>
          <strong>MEASURE</strong>:&nbsp; analyzes, assesses, and tracks AI risks using quantitative and qualitative
          methods
        </li>
        <li>
          <strong>MANAGE</strong>:&nbsp; allocates resources to prioritize and address identified risks
        </li>
      </ul>
      <p>
        The accompanying AI RMF Playbook is an especially valuable supplementary resource. It provides substantially
        more detail on each action and outcome outlined in the AI RMF, making it critical for effective practical
        implementation.
      </p>

      <h6>Best Practice Highlights</h6>

      <div className="table">
        <TableContainer style={tableStyles.tableContainer}>
          <Table size="small">
            <TableHeader
              tableNumber="4.4"
              tableTitle="Best Practices"
              framework="NIST AI Risk Management Framework"
              subtitle="Risk Mitigation"
            />
            <TableRow>
              <TableCell>
                <strong>GOVERN 6 </strong>(all risks, p. 24)
                <p>
                  Policies and procedures are in place to address AI risks and benefits arising from third-party
                  software and data and other supply chain issues.
                </p>
                <ul>
                  <li>
                    <strong>GOVERN 6.1</strong>:&nbsp; policies and procedures are in place that address AI risks
                    associated with third-party entities, including risks of infringement of a third party's
                    intellectual property or other rights
                  </li>
                  <li>
                    <strong>GOVERN 6.2</strong>:&nbsp; contingency processes are in place to handle failures or
                    incidents in third-party data or AI systems deemed to be high-risk
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>MAP 5</strong> (all risks, p. 27-28)
                <p>Impacts on individuals, groups, communities, organizations, and society are characterized. </p>
                <ul>
                  <li>
                    <strong>MAP 5.1</strong>:&nbsp; likelihood and magnitude of each identified impact (both potentially
                    beneficial and harmful) based on expected use, past uses of AI systems in similar contexts, public
                    incident reports, feedback from those external to the team that developed or deployed the AI system,
                    or other data are identified and documented
                  </li>
                  <li>
                    <strong>MAP 5.2</strong>:&nbsp; practices and personnel for supporting regular engagement with
                    relevant AI actors and integrating feedback about positive, negative, and unanticipated impacts are
                    in place and documented
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>MEASURE 3 </strong>(all risks, p. 30-31)
                <p>Mechanisms for tracking identified AI risks over time are in place. </p>
                <ul>
                  <li>
                    <strong>MEASURE 3.1</strong>:&nbsp; approaches, personnel, and documentation are in place to
                    regularly identify and track existing, unanticipated, and emergent AI risks based on factors such as
                    intended and actual performance in deployed contexts
                  </li>
                  <li>
                    <strong>MEASURE 3.2</strong>:&nbsp; risk tracking approaches are considered for settings where AI
                    risks are difficult to assess using currently available measurement techniques or where metrics are
                    not yet available
                  </li>
                  <li>
                    <strong>MEASURE 3.3</strong>:&nbsp; feedback processes for end users and impacted communities to
                    report problems and appeal system outcomes are established and integrated into AI system evaluation
                    metric
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>MANAGE 1 </strong>(all risks, p. 32)
                <p>
                  AI risks based on assessments and other analytical output from the MAP and MEASURE functions are
                  prioritized, responded to, and managed.
                </p>
                <ul>
                  <li>
                    <strong>MANAGE 1.1:&nbsp; </strong> a determination is made as to whether the AI system achieves its
                    intended purposes and stated objectives and whether its development or deployment should proceed
                  </li>
                  <li>
                    <strong>MANAGE 1.2</strong>:&nbsp; treatment of documented AI risks is prioritized based on impact,
                    likelihood, and available resources or methods
                  </li>
                  <li>
                    <strong>MANAGE 1.3</strong>:&nbsp; responses to the AI risks deemed high priority, as identified by
                    the MAP function, are developed, planned, and documented. Risk response options can include
                    mitigating, transferring, avoiding, or accepting
                  </li>
                  <li>
                    <strong>MANAGE 1.4</strong>:&nbsp; negative residual risks (defined as the sum of all unmitigated
                    risks) to both downstream acquirers of AI systems and end users are documented
                  </li>
                </ul>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </div>

      <h6>Assessment Results</h6>
      <AssessmentTable framework="NIST AI Risk Management Framework" tableNumber="4.5" />
      <AssessmentTable framework="NIST AI Risk Management Framework" tableNumber="4.6" />

      <h6>Supplementary Resources</h6>
      <ul>
        <li>
          <a target="_blank" rel="noreferrer" href="https://airc.nist.gov/AI_RMF_Knowledge_Base/Playbook">
            NIST AI Risk Management Framework Playbook
          </a>
          :&nbsp; in-depth, highly practical guidance on the actions and outcomes of each core function<sup>12</sup>
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href="https://nvlpubs.nist.gov/nistpubs/ai/NIST.AI.600-1.pdf">
            Gen AI Intelligence profile
          </a>
          :&nbsp; overview of the risks unique to or exacerbated by gen AI<sup>13</sup>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.1270.pdf"
          >
            NIST Special Publication 1270:&nbsp; Towards a Standard for Identifying and Managing Bias in Artificial
            Intelligence
          </a>
          :&nbsp; in-depth exploration of the context and practical considerations for AI bias<sup>2</sup>
        </li>

        <li>
          <a target="_blank" rel="noreferrer" href="https://nvlpubs.nist.gov/nistpubs/CSWP/NIST.CSWP.29.pdf">
            NIST Cybersecurity Framework
          </a>
          :&nbsp; guidance with a lot of relevance to AI security and resilience risks<sup>14</sup>
        </li>
      </ul>
      <br />

      <h4 id="singapore-framework">Singapore's Model AI Governance Framework</h4>
      <figure>
        <img src={singapore} alt="singapore framework" />
      </figure>
      <h6>Introduction</h6>
      <p>
        Singapore's Info-communications Media Development Authority (IMDA) and Personal Data Protection Commission
        (PDPC) developed the{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.pdpc.gov.sg/-/media/Files/PDPC/PDF-Files/Resource-for-Organisation/AI/SGModelAIGovFramework2.pdf"
        >
          Model AI Governance Framework
        </a>{" "}
        in collaboration with industry stakeholders.<sup>4</sup> IMDA and PDPC first released it at the 2019 World
        Economic Forum in Davos, with a second edition in January 2020.
      </p>
      <p>
        The framework provides guidance for organizations deploying AI solutions at scale, translating ethical
        principles into concrete governance practices. Rather than prescribing rigid requirements, it offers flexible
        guidance that organizations can adapt to their needs, focusing on practical measures to address key ethical and
        governance issues in AI deployment. IMDA and PDPC deliberately designed it to be sector-agnostic,
        technology-neutral, and applicable to organizations of any size or business model.
      </p>
      <p>
        Unlike frameworks focused primarily on high-level principles or policy guidance, Singapore's approach emphasizes
        operational implementation – helping organizations build stakeholder confidence through responsible AI
        deployment while aligning their internal structures with accountability-based practices in Data Management and
        protection.
      </p>
      <p>
        The PDPC maintains this as a "living" framework, committing to periodic updates that reflect evolving AI
        technologies, ethical considerations, and governance challenges.
      </p>

      <h6>Stated Aims</h6>
      <ul>
        <li>
          Designed to be a practical, ready-to-use tool that organizations can adapt based on their specific needs while
          maintaining core principles of responsible AI deployment
        </li>
        <li>Algorithm-agnostic, Technology-agnostic, Sector-agnostic, Scale- and Business-model-agnostic</li>
        <li>Guidance on the key issues to be considered and measures that can be implemented</li>
      </ul>

      <h6>Content Summary</h6>
      <p>
        The Singapore Model AI Governance Framework is especially focused on practical implementation of AI Governance.
      </p>
      <p>
        It's organized around four key areas. Internal Governance Structures and Measures guides organizations in
        establishing oversight and accountability mechanisms for ethical AI deployment. The framework then covers
        Determining Human Involvement in AI-augmented Decision-making, introducing a risk-based methodology with three
        oversight models and a probability-severity matrix to guide implementation.
      </p>
      <p>
        Operations Management outlines essential practices for responsible AI development, focusing on data quality,
        algorithm governance, and model management through explainability, repeatability, and robustness. Finally,
        Stakeholder Interaction and Communication provides guidance on AI system transparency and stakeholder engagement
        practices.
      </p>
      <p>
        The framework emphasizes practical implementation throughout, supporting each area with real-world examples
        while maintaining flexibility for different organizational contexts.
      </p>

      <h6>Best Practice Highlights</h6>
      <div className="table">
        <TableContainer style={tableStyles.tableContainer}>
          <Table size="small">
            <TableHeader
              tableNumber="4.7"
              tableTitle="Best Practices"
              framework="Singapore Model AI Governance Framework"
              subtitle="Risk Mitigation"
            />
            <TableRow>
              <TableCell>
                <strong>Repeatability </strong>(valid and reliable, fair, explainable, and interpretable, p. 46)
                <p>
                  Where explainability cannot be practicably achieved given the state of technology, organizations can
                  consider documenting the repeatability of results produced by the AI model. Repeatability refers to
                  the ability to consistently perform an action or make a decision, given the same scenario. While
                  repeatability (of results) is not equivalent to explainability (of algorithm), some degree of
                  assurance of consistency in performance could provide AI users with a larger degree of confidence.{" "}
                </p>
                <p>Helpful practices include:</p>
                <ul>
                  <li>
                    Conducting repeatability assessments for commercial deployments in live environments to ensure that
                    deployments are repeatable
                  </li>
                  <li>
                    Performing counterfactual fairness testing; counterfactual fairness testing ensures that a model's
                    decisions are the same in both the real world and in a counterfactual world where attributes deemed
                    sensitive (such as race or gender) are altered
                  </li>
                  <li>
                    Assessing how exceptions can be identified and handled when decisions are not repeatable, e.g., when
                    randomness has been introduced by design
                  </li>
                  <li>
                    Ensuring exception handling is in line with organization's policies; in this regard, it may be
                    helpful to use AI models that can recognize when a given set of facts contains new variables not
                    previously considered and can highlight these new variables to a human
                  </li>
                  <li>
                    Identifying and accounting for changes over time to ensure that models trained on time-sensitive
                    data remain relevant
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Traceability</strong> (Accountability and Transparency, Explainable and Interpretable, p. 48-49)
                <p>
                  An AI model is considered to be traceable if (a) its decisions and (b) the datasets and processes that
                  yield the AI model's decision – including those of data gathering, data labeling, and the algorithms
                  used – are documented in an easily understandable way. The former refers to the traceability of
                  AI-augmented decisions, while the latter refers to traceability in model training. Traceability
                  facilitates transparency and explainability and is also helpful for other reasons.
                </p>
                <p>
                  First, the information might also be useful for troubleshooting or investigating how the model was
                  functioning or why a particular prediction was made. Second, the traceability record (in the form of
                  an audit log) can be a source of input data that can be used as a training dataset in the future.
                </p>
                <p>Practices that organizations may consider to promote traceability include:</p>
                <ul>
                  <li>Building an audit trail to document the model training and AI-augmented decision</li>

                  <li>
                    Implementing a black box recorder that captures all input data streams;for example, a black box
                    recorder in a self-driving car tracks the vehicle's position and records when and where the
                    self-driving system takes control of the vehicle, suffers a technical problem, or requests the
                    driver to take over the control of the vehicle
                  </li>

                  <li>
                    Ensuring that data relevant to traceability are stored appropriately to avoid degradation or
                    alteration and retained for durations relevant to the industry
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow style={{ ...tableStyles.centerText, ...tableStyles.lightTealBackground }}>
              <TableCell>
                <strong>Data Management</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Ensuring Data Quality </strong>(Data Quality, p. 38)
                <p>
                  Organizations are encouraged to understand and address factors that may affect the quality of data,
                  such as:
                </p>
                <ul>
                  <li>
                    The accuracy of the dataset, in terms of how well the values in the dataset match the true
                    characteristics of the entities described by the dataset
                  </li>
                  <li>The completeness of the dataset, both in terms of attributes and items</li>
                  <li>
                    The veracity of the dataset, which refers to how credible the data is, including whether the data
                    originated from a reliable source
                  </li>
                  <li>How recently the dataset was compiled or updated</li>
                  <li>
                    The relevance of the dataset and the context for data collection, as it may affect the
                    interpretation of and reliance on the data for the intended purpose
                  </li>
                  <li>
                    The integrity of the dataset that has been joined from multiple datasets which refers to how well
                    extraction and transformation have been performed
                  </li>
                  <li>
                    The usability of the dataset, including how well the dataset is structured in a
                    machine-understandable form
                  </li>
                  <li>Human interventions (e.g., if any human has filtered, applied labels, or edited the data)</li>
                </ul>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </div>

      <h6>Assessment Results</h6>
      <AssessmentTable framework="Singapore Model AI Governance Framework" tableNumber="4.8" />
      <AssessmentTable framework="Singapore Model AI Governance Framework" tableNumber="4.9" />

      <h6>Supplementary Resources</h6>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.pdpc.gov.sg/-/media/Files/PDPC/PDF-Files/Resource-for-Organisation/AI/SGIsago.pdf"
          >
            Implementation and Self-Assessment Guide for Organisations
          </a>
          :&nbsp; for implementing the Model AI Framework, including guiding questions and industry examples
          <sup>15</sup>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://aiverifyfoundation.sg/wp-content/uploads/2024/06/Model-AI-Governance-Framework-for-Generative-AI-19-June-2024.pdf"
          >
            Singapore AI Verify's Model AI Governance Framework for Generative AI
          </a>
          :&nbsp; guidance on a combination of AI risks and other considerations for trustworthy gen AI<sup>5</sup>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://aiverifyfoundation.sg/downloads/AI_Verify_Sample_Report.pdf"
          >
            Sample report using the test kit
          </a>
          :&nbsp; fictional case studies for how the test kit works in practice<sup>16</sup>
        </li>
      </ul>
      <br />

      <h4 id="huderaf-framework">The Alan Turing Institute's HUDERAF for AI Systems</h4>
      <figure>
        <img src={turing} alt="huder framework" />
      </figure>
      <h6>Introduction</h6>

      <p>
        The Alan Turing Institute (ATI) submitted the Human Rights, Democracy and the Rule of Law Assurance Framework (
        <a target="_blank" rel="noreferrer" href="https://arxiv.org/pdf/2202.02776">
          HUDERAF
        </a>
        ) for AI Systems to the Council of Europe (CoE) in September 2021, following the CoE's Ad Hoc Committee on
        Artificial Intelligence's December 2020 Feasibility Study.<sup>17</sup>
      </p>
      <p>
        The framework deliberately remains 'algorithm-neutral' and practice-based to include different AI applications
        and adapt to future developments. The framework helps designers, developers, and users of AI systems minimize
        risks to human rights, democracy, or the rule of law.
      </p>
      <p>
        The framework aims to deliver its features through a single digital platform with interactive templates and
        automated reporting tools. The HUDERAF model is designed to be dynamic, with regular reassessment and updates
        needed to address novel AI innovations, unforeseen applications, and emerging risks to fundamental rights and
        freedoms.
      </p>
      <p>
        Stakeholders will achieve this process through inclusive, multi-party evaluations and revisions of the
        framework. The framework's unique contribution is its integration of human rights due diligence practices with
        technical AI Governance mechanisms, providing practical tools for organizations to meet their human rights
        obligations while developing trustworthy AI systems.
      </p>

      <h6>Stated Aims</h6>
      <ul>
        <li>
          Provide an accessible and user-friendly set of mechanisms for facilitating compliance with a binding legal
          framework on AI
        </li>
        <li>
          Provide an innovation-enabling way to facilitate better, more responsible innovation practices that conform to
          human rights, democracy, and the rule of law so that these technologies can optimally promote individual,
          community, and planetary well-being
        </li>
        <li>
          To address the challenges put forward in CAHAI-PDG(2021)05rev, which involve various tools and methodologies
          to identify and evaluate AI risks to human rights, democracy, and the rule of law
        </li>
      </ul>

      <h6>Content Summary</h6>
      <p>
        ATI's HUDERAF consists of an integrated set of processes and templates that provide a comprehensive approach to
        assessing and managing AI risks to fundamental rights and freedoms. The framework is built around the SAFE-D
        goals that define key trustworthiness characteristics. These include safety, accountability, fairness,
        explainability, and data quality and protection.
      </p>
      <p>
        The primary content of the framework is organized into four sections. Each section represents a stage in the
        process to identify and evaluate risks:
      </p>
      <ul>
        <li>
          <strong>PCRA (Preliminary Context-Based Risk Analysis)</strong>:&nbsp; provides initial assessment of
          context-specific risks to human rights, democracy, and rule of law through structured questions and automated
          reporting
        </li>
        <li>
          <strong>SEP (Stakeholder Engagement Process)</strong>:&nbsp; guides identification and meaningful inclusion of
          affected stakeholders throughout the project lifecycle using proportionate engagement methods
        </li>
        <li>
          <strong>HUDERIA (Human Rights Impact Assessment)</strong>:&nbsp; enables collaborative evaluation of potential
          and actual impacts through stakeholder dialogue and detailed impact severity assessment
        </li>
        <li>
          <strong>HUDERAC (Assurance Case)</strong>:&nbsp; builds structured arguments demonstrating how claims about
          achieving framework goals are supported by evidence
        </li>
      </ul>
      <p>
        The framework is supported by detailed templates for each process that consolidate necessary questions, prompts,
        and actions. For example, the PCRA template features interactive elements that generate customized risk reports
        through conditional logic. Implementation is intended through a unified digital platform that makes all
        components accessible and user-friendly.
      </p>

      <h6>Best Practice Highlights</h6>
      <div className="table">
        <TableContainer style={tableStyles.tableContainer}>
          <Table size="small">
            <TableHeader
              tableNumber="4.10"
              tableTitle="Best Practices"
              framework="The Council of Europe's HUDERAF for AI Systems"
              subtitle="Risk Mitigation"
            />
            <TableRow>
              <TableCell>
                <strong>Accuracy and performance metrics </strong>(valid and reliable, p. 284)
                <p>
                  In machine learning, a model's accuracy is the proportion of examples for which it generates a correct
                  output. This performance measure is sometimes characterized conversely as an error rate or the
                  fraction of cases for which the model produces an incorrect output.
                </p>
                <p>
                  As a performance metric, accuracy should be a central component to establishing and enhancing the
                  approach to safe AI. Specifying a reasonable performance level for the system may also often require
                  refining or exchanging the measure of accuracy. For instance, if certain errors are more significant
                  or costly than others, a metric for total cost can be integrated into the model so that the cost of
                  one class of errors can be weighed against that of another.{" "}
                </p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Transparency </strong>(accountable and transparent, explainable and interpretable, p. 289)
                <p>
                  The transparency of AI systems can refer to several features, including their inner workings and
                  behaviors and the systems and processes that support them. An AI system is transparent when it is
                  possible to determine how it was designed, developed, and deployed.{" "}
                </p>
                <p>
                  This can include, among other things, a record of the data used to train the system or the parameters
                  of the model that transform the input (e.g., an image) into an output (e.g., a description of the
                  objects in the image). However, it can also refer to broader processes, such as whether there are
                  legal barriers that prevent individuals from accessing information that may be necessary to understand
                  fully how the system functions (e.g., intellectual property restrictions).
                </p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Source integrity and measurement accuracy </strong>(fair, p. 287)
                <p>
                  Effective bias mitigation begins at the commencement of data extraction and collection processes. Both
                  the sources and instruments of measurement may introduce discriminatory factors into a dataset. When
                  incorporated as inputs in the training data, biased prior human decisions and judgments – such as
                  prejudiced scoring, ranking, interview data, or evaluation – will become the 'ground truth' of the
                  model and replicate the bias in the outputs of the system in order to secure discriminatory non-harm,
                  as well as ensuring that the data sample has optimal source integrity. This involves securing or
                  confirming that the data-gathering processes involve suitable, reliable, and impartial sources of
                  measurement and sound methods of collection.
                </p>
              </TableCell>
            </TableRow>
            <TableRow style={{ ...tableStyles.centerText, ...tableStyles.lightTealBackground }}>
              <TableCell>
                <strong>Data Management</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Responsible Data Management</strong> (Data Management generally, p. 288)
                <p>
                  Responsible Data Management ensures that the team has been trained on managing data responsibly and
                  securely, identifying possible risks and threats to the system, and assigning roles and
                  responsibilities for how to deal with these risks if they were to occur. Policies on data storage and
                  public dissemination of results should be discussed within the team and with stakeholders and clearly
                  documented.
                </p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Data Security </strong> (Data Security, p. 288)
                <p>
                  Each Party shall provide that the controller, and, where applicable, the processor, takes appropriate
                  security measures against risks such as accidental or unauthorized access to, destruction, loss, use,
                  modification, or disclosure of personal data. Each Party shall provide that the controller notifies,
                  without delay, at least the competent supervisory authority within the meaning of Article 15 of this
                  Convention of those data breaches which may seriously interfere with the rights and fundamental
                  freedoms of data subjects.
                </p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Consent (or legitimate basis) for processing</strong> (Data Security, p. 288)
                <p>
                  Each Party shall provide that data processing can be carried out based on the free, specific,
                  informed, and unambiguous consent of the data subject or some other legitimate basis laid down by law.
                  The data subject must be informed of risks that could arise in the absence of appropriate safeguards.
                  Such consent must represent the free expression of an intentional choice, given either by a statement
                  – which can be written, including by electronic means, or oral – or by a clear affirmative action that
                  clearly indicates in this specific context the acceptance of the proposed processing of personal data.
                  Therefore, silence, inactivity, or pre-validated forms or boxes should not constitute consent.
                </p>
                <p>
                  No undue influence or pressure – which can be of an economic or other nature – whether direct or
                  indirect, may be exercised on the data subject, and consent should not be regarded as freely given
                  where the data subject has no genuine or free choice or is unable to refuse or withdraw consent
                  without prejudice. The data subject has the right to withdraw their consent at any time (which is to
                  be distinguished from the separate right to object to processing).
                </p>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </div>

      <h6>Assessment Results</h6>
      <AssessmentTable framework="The Council of Europe's HUDERAF for AI Systems" tableNumber="4.11" />
      <AssessmentTable framework="The Council of Europe's HUDERAF for AI Systems" tableNumber="4.12" />

      <h6>Supplementary Resources</h6>
      <ul>
        <li>
          <a target="_blank" rel="noreferrer" href="https://rm.coe.int/0900001680ab2279">
            Human rights by design
          </a>
          :&nbsp; explores the impact of AI on human rights and how organizations can implement 10 steps to protect
          human rights<sup>18</sup>
        </li>

        <li>
          <a target="_blank" rel="noreferrer" href="https://rm.coe.int/0900001680a1293a">
            Ad hoc Committee on Artificial Intelligence (CAHAI) meeting
          </a>
          :&nbsp; exploration of AI's impact and how risks might be addressed<sup>19</sup>
        </li>
      </ul>

      <h2 id={contents[7].anchor}>{contents[7].text}</h2>
      <h4 id={contents[8].anchor}>{contents[8].text}</h4>
      <figure>
        <img src={microsoft} alt="microsoft framework" />
      </figure>
      <h6>Introduction</h6>

      <p>
        Microsoft released version 1 of its{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://cdn-dynmedia-1.microsoft.com/is/content/microsoftcorp/microsoft/final/en-us/microsoft-brand/documents/Microsoft-Responsible-AI-Standard-General-Requirements.pdf?culture=en-us&country=us"
        >
          Responsible AI Standard
        </a>{" "}
        in September 2019, followed by v2 in June 2022.<sup>20</sup> Microsoft developed it as part of a multi-year
        effort to operationalize its six AI principles into concrete product development requirements. The standard was
        created in response to a recognized policy gap, where existing laws and norms had not kept pace with AI's unique
        risks and societal needs. Microsoft leveraged expertise from its research, policy, and engineering teams to
        develop this guidance.
      </p>
      <p>
        Microsoft primarily designed the framework for its product development teams but is making it publicly available
        to share learnings and contribute to broader discussions about AI Governance. Various stakeholders, including
        system owners, developers, customer support teams, and organizations using Microsoft's AI systems, can use it.
      </p>
      <p>
        Microsoft frames this as a "living document" that will continue to evolve based on new research, technologies,
        laws, and learnings from both inside and outside the company. They actively seek feedback through their
        ResponsibleAIQuestions portal and emphasize the importance of collaboration between industry, academia, civil
        society, and government to advance responsible AI practices.
      </p>
      <h6>Stated Aims</h6>
      <ul>
        <li>To operationalize Microsoft's six AI principles</li>
        <li>To provide "concrete and actionable guidance" for product development teams</li>
        <li>
          To fill the "policy gap" where "laws and norms had not caught up with AI's unique risks or society's needs"
        </li>
      </ul>

      <h6>Content Summary</h6>
      <p>
        The "Microsoft Responsible AI Standard v2" translates Microsoft's six AI principles into concrete requirements,
        tools, and practices for product development teams.
      </p>
      <p>
        The document consists of six core goal categories, each with detailed implementation requirements. The first
        section, Accountability Goals, focuses on impact assessment, oversight, and data governance, while subsequent
        sections address Transparency, Fairness, Reliability & Safety, Privacy & Security, and Inclusiveness.
      </p>
      <p>
        Each goal category contains specific requirements that must be met. For example, Fairness has three major goals:
        Quality of Service refers to ensuring similar performance across demographic groups, Allocation of Resources and
        Opportunities focuses on minimizing disparate outcomes, and Minimization of Stereotyping aims to prevent harmful
        bias. For each goal, the document provides detailed requirements along with recommended tools and practices for
        implementation.
      </p>
      <p>
        The framework places particular attention on accountability and documentation requirements, specifically
        identifying and mitigating risks before deployment and maintaining ongoing monitoring afterward. While some
        areas, such as security and privacy, aren't explicitly covered and instead reference separate Microsoft
        policies, other key risks are covered in moderately high detail.
      </p>

      <h6>Best Practice Highlights</h6>
      <div className="table">
        <TableContainer style={tableStyles.tableContainer}>
          <Table size="small">
            <TableHeader
              tableNumber="4.13"
              tableTitle="Best Practices"
              framework="Microsoft's Responsible AI Standard"
              subtitle="Risk Mitigation"
            />
            <TableRow>
              <TableCell>
                <strong>System intelligibility for decision-making </strong>(valid and reliable, explainable and
                interpretable, p. 9)
                <p>
                  Design the system, including, when possible, the system UX, features, reporting functions, and
                  educational materials, so that stakeholders identified in requirement T1.1 can:
                </p>
                <ul>
                  <li>Understand the system's intended uses</li>
                  <li>
                    Interpret relevant system behavior effectively, i.e., in a way that supports informed
                    decision-making
                  </li>
                  <li>
                    Remain aware of the possible tendency to over-rely on the system's outputs ("automation bias")
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Quality of service </strong>(accountable and transparent, fair, privacy-enhanced, p. 13)
                <p>Publish information for customers about:&nbsp; </p>
                <ul>
                  <li>
                    Identified demographic groups for which performance may not meet any target minimum performance
                    level
                  </li>
                  <li>
                    Any remaining performance disparities between identified demographic groups that may exceed the
                    target maximum
                  </li>
                  <li>Any justifiable factors that account for these performance levels and differences</li>
                  <li>
                    When the system is a platform service made available to external customers or partners, include this
                    information in the required Transparency Note.
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Failures and remediations </strong>(secure and resilient, safe, p. 23)
                <p>
                  For each case of a predictable failure likely to have an adverse impact on a stakeholder, document the
                  failure management approach:
                </p>
                <ul>
                  <li>
                    When possible, design and build the system to avoid this failure; describe the design solution;
                    estimate the time range for resolving predictable failures for each designed solution or indicate
                    that the design will prevent the failure
                  </li>
                  <li>
                    When a failure cannot be prevented by design, build a fallback option that may be used when this
                    failure occurs; describe the fallback option and document the estimated time required to invoke and
                    use the fallback option
                  </li>
                  <li>
                    Provide training and documentation for stakeholders accountable for system oversight that supports
                    their resolution of the failure; describe the documentation and training
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow style={{ ...tableStyles.centerText, ...tableStyles.lightTealBackground }}>
              <TableCell>
                <strong>Data Management</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Data Governance and Management</strong> (Data Governance, p. 7)
                <p>Microsoft AI systems are subject to appropriate Data Governance and Management practices.</p>
                <ul>
                  <li>
                    <strong>A4.1</strong> Define and document data requirements with respect to the system's intended
                    uses, stakeholders, and the geographic areas where the system will be deployed. Document these
                    requirements in the Impact Assessment.
                  </li>
                  <li>
                    <strong>A4.2</strong> Define and document procedures for collecting and processing data, including
                    annotation, labeling, cleaning, enrichment, and aggregation, where relevant.
                  </li>
                  <li>
                    <strong>A4.3</strong> If you plan to use existing data sets to train the system, assess the quantity
                    and suitability of available data sets the system will need in relation to the data requirements
                    defined in A4.1. Document this assessment in the Impact Assessment.
                  </li>
                  <li>
                    <strong>A4.4</strong> Define and document methods for evaluating data to be used by the system
                    against the requirements defined in A4.1.
                  </li>
                  <li>
                    <strong>A4.5</strong> Evaluate all data sets using the methods defined in requirement A4.4. Document
                    the results of the evaluation.
                  </li>
                </ul>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </div>

      <h6>Assessment Results</h6>
      <AssessmentTable framework="Microsoft's Responsible AI Standards" tableNumber="4.14" />
      <AssessmentTable framework="Microsoft's Responsible AI Standards" tableNumber="4.15" />

      <h6>Supplementary Resources</h6>
      <ul>
        <li>
          <a target="_blank" rel="noreferrer" href="https://query.prod.cms.rt.microsoft.com/cms/api/am/binary/RE4ZzOI">
            Microsoft Responsible AI Impact Assessment Guide
          </a>
          :&nbsp; guidance on how to identify AI's impacts on an organization <sup>21</sup>
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href="https://query.prod.cms.rt.microsoft.com/cms/api/am/binary/RW1l5BO">
            RAI Transparency report
          </a>
          :&nbsp; shares Microsoft's governance processes for developing and deploying gen AI applications <sup>22</sup>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://blogs.microsoft.com/on-the-issues/2023/10/26/microsofts-ai-safety-policies/"
          >
            Microsoft's AI Safety Policies
          </a>
          :&nbsp; AI Governance policies focused on AI safety prepared for the UK AI Safety Summit <sup>23</sup>
        </li>
      </ul>
      <br />

      <h4 id={contents[9].anchor}>{contents[9].text}</h4>
      <figure>
        <img src={aws} alt="aws framework" />
      </figure>

      <h6>Introduction</h6>
      <p>
        "AWS Cloud Adoption Framework for Artificial Intelligence, Machine Learning, and Generative AI (
        <a
          target="_blank"
          rel="noreferrer"
          href="https://docs.aws.amazon.com/pdfs/whitepapers/latest/aws-caf-for-ai/aws-caf-for-ai.pdf#governance-perspective-managing-an-aiml-driven-organization"
        >
          CAF-AI
        </a>
        )" was published in February 2024 through a collaborative effort between multiple AWS entities, including the
        Generative AI Innovation Center, Professional Services, Machine Learning Solutions Lab, and other specialized
        teams.<sup>24</sup>
      </p>
      <p>
        This framework provides organizations with a comprehensive mental model and prescriptive guidance for generating
        business value from AI technologies. It maps out the AI transformation journey, detailing how organizational
        capabilities mature over time and outlining the foundational capabilities needed to grow AI maturity. The
        framework is structured across six key perspectives – Business, People, Governance, Platform, Security, and
        Operations –each targeting specific stakeholder groups from C-suite executives to technical practitioners.
      </p>
      <p>
        While primarily designed for organizations seeking to implement enterprise-level AI adoption beyond proof of
        concept, CAF-AI serves multiple purposes. It acts as both a starting point and ongoing guide for AI, ML, and
        generative AI initiatives, facilitates strategic discussions within teams and with AWS Partners, and helps
        organizations align their cloud and AI transformation journeys.
      </p>
      <p>
        AWS designed CAF-AI to be dynamic and evolving, serving as a constantly growing index of considerations for
        enterprise AI adoption. As part of AWS's larger Cloud Adoption Framework, it enables organizations to think
        holistically about their cloud and AI transformations. AWS plans regular updates to reflect the rapidly evolving
        AI landscape.
      </p>
      <h6>Stated Aims</h6>
      <ul>
        <li>Describe the journey businesses experience as their organizational capabilities on AI and ML mature</li>
        <li>
          Prescriptive guidance on the target state of foundational capabilities and how to evolve them step by step to
          generate business value along the way
        </li>
        <li>Inspire and inform customers' mid-term planning and strategy for AI Governance</li>
      </ul>

      <h6>Content Summary</h6>
      <p>
        The AWS CAF-AI is organized into two main components. First, it briefly presents the AI cloud transformation
        value chain, which explains how AI capabilities lead to business outcomes before outlining a four-stage AI
        transformation journey – Envision, Align, Launch, Scale – that organizations progress through iteratively.
      </p>
      <p>
        Second, it details foundational capabilities across six key perspectives. The six perspectives forming the
        framework's core are:
      </p>
      <ul>
        <li>
          <strong>Business</strong>:&nbsp; ensures AI investments accelerate digital transformation and business
          outcomes
        </li>
        <li>
          <strong>People</strong>:&nbsp; bridges technology and business, evolving culture toward continual growth
        </li>
        <li>
          <strong>Governance</strong>:&nbsp; orchestrates AI initiatives while maximizing benefits and minimizing risks
        </li>
        <li>
          <strong>Platform</strong>:&nbsp; builds enterprise-grade, scalable cloud platforms for AI operations and
          development
        </li>
        <li>
          <strong>Security</strong>:&nbsp; achieves confidentiality, integrity, and availability of data and cloud
          workloads
        </li>
        <li>
          <strong>Operations</strong>:&nbsp; ensures AI services meet business needs and maintain reliability
        </li>
      </ul>
      <p>
        Each perspective contains several foundational capabilities that are either enriched from the original AWS Cloud
        Adoption Framework or newly introduced for AI-specific needs. For example, new capabilities include ML Fluency
        (People perspective) and Responsible Use of AI (Governance perspective), while existing capabilities such as
        Data Protection (Security perspective) are enhanced with AI-specific considerations. The framework provides
        detailed guidance on implementing each capability, complete with best practices, considerations, and
        implementation steps.
      </p>

      <h6>Best Practice Highlights</h6>

      <div className="table">
        <TableContainer style={tableStyles.tableContainer}>
          <Table size="small">
            <TableHeader
              tableNumber="4.16"
              tableTitle="Best Practices"
              framework="AWS Cloud Adoption Framework for AI, ML, and Gen AI"
              subtitle="Risk Mitigation"
            />
            <TableRow>
              <TableCell>
                <strong>Security assurance </strong>(secure and resilient, privacy-enhanced, valid and reliable, p. 43)
                <p>Continuously monitor and evaluate the three critical components of your workloads:</p>
                <ul>
                  <li>
                    <strong>The Input</strong>:&nbsp; because models often require vast amounts of data for training and
                    analysis, you need to validate that the type of data ingested is aligned to the model's goals and
                    outcomes; establish audit mechanisms to understand adherence to the established control framework{" "}
                  </li>
                  <li>
                    <strong>The Model</strong>:&nbsp; certify that the users understand the acceptable usage of AI in
                    alignment with organizational policies; implement policies and controls to validate that the
                    organization understands where it is appropriate to use AI and where it is not; establish audit
                    mechanisms to identify how the model uses data and where AI capabilities are used within the
                    organization{" "}
                  </li>
                  <li>
                    <strong>The Output</strong>:&nbsp; establish acceptable usage criteria for the output, paying
                    attention to where the data may be reused or reintroduced to additional AI models; establish
                    discovery or audit mechanisms to review output data to validate that generated data cannot be used
                    to infer or recreate sensitive or regulated data; create mechanisms for validating the authenticity
                    and origin of the output where trustworthiness is paramount, such as medical diagnoses{" "}
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Responsible use of AI </strong>(explainable and interpretable, fair, p. 28)
                <p>
                  Embed explainability by design into your AI lifecycle where possible and establish practices to
                  recognize and discover both intended and unintended biases. Consider using the right tools to help you
                  monitor the status quo and inform risk. Use best practices that enable a culture of responsible use of
                  AI and build or use systems to enable your teams to inspect these factors. While this cost accumulates
                  before the algorithms reach the production state, it pays off in the mid-term by mitigating damage.
                </p>
                <p>
                  Especially when you are planning to build, tune, or use a foundation model, inform yourself about new
                  emerging concerns such as hallucinations, copyright infringement, model data leakage, and model
                  jailbreaks. Ask if and how the original vendor or supplier has taken an RAI approach to the
                  development, as this trickles down directly into your business case.{" "}
                </p>
              </TableCell>
            </TableRow>
            <TableRow style={{ ...tableStyles.centerText, ...tableStyles.lightTealBackground }}>
              <TableCell>
                <strong>Data Management</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Data Engineering </strong>(Data Integration & Interoperability, Data Quality, Data Modeling &
                Design, p. 288)
                <p>
                  Automate data flows for AI development. As data is a first-level citizen of any AI strategy and
                  development process, data engineering becomes significantly more important and cannot be an
                  afterthought but a readily available capability within your organization and teams.{" "}
                </p>
                <p>
                  As data is used to actively shape the behavior of an AI system, engineering it properly is decisive.
                  Data preparation tools are an essential part of the development process. While the practice itself is
                  not changing fundamentally, its importance and need for continuous evolution are rising. Consider
                  integrating your data pipelines and practice directly into your AI development process and model
                  training through streamlined and seamless pre-processing. Consider transitioning from traditional
                  Extraction, Transformation, and Load (ETL) processes to a zero-ETL approach.{" "}
                </p>
                <p>
                  With such an approach to data engineering, you reduce the friction between your data and AI practices.
                  Enable and empower your AI team to combine data from multiple sources into a unified view as a
                  self-service capability. Couple this with visualization tools and techniques that help your AI and
                  data teams explore and understand their data visually.{" "}
                </p>
                <p>
                  When possible, focus on making your data accurate, complete, and reliable. Design data models or
                  transformations as part of your workflows, specifically for machine learning – normalized, consistent,
                  and well-documented – to facilitate efficient data handling and processing. This significantly
                  improves the performance of your AI applications and reduces friction in the development process.{" "}
                </p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Data Architecture</strong> (Data Architecture, p. 34)
                <p>
                  As data movement is becoming more important for AI systems, harden your architecture for data-movement
                  requirements:
                </p>
                <ul>
                  <li>
                    <strong>Inside-Out</strong>:&nbsp; data initially aggregates in the data lake from various sources –
                    structured like databases and well-structured spreadsheets or unstructured like media and text; a
                    subset is then moved to specialized stores for dedicated analytics, such as search analytics or
                    building knowledge graphs
                  </li>
                  <li>
                    <strong>Outside-In</strong>:&nbsp; data starts in specialized stores suited to specific
                    applications; for example, to support a game running in the cloud, the application might use a
                    specific store to maintain game states and leaderboards; this data is later moved into a data lake
                    where more comprehensive analytics can be conducted to enhance gaming experiences
                  </li>
                  <li>
                    <strong>Around the Perimeter</strong>:&nbsp; this involves moving data between specialized data
                    stores, such as from a relational database to a NoSQL database, to serve specific needs such as
                    reporting dashboards
                  </li>
                </ul>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </div>

      <h6>Assessment Results</h6>
      <AssessmentTable framework="AWS Cloud Adoption Framework for AI, ML, and Gen AI" tableNumber="4.17" />
      <AssessmentTable framework="AWS Cloud Adoption Framework for AI, ML, and Gen AI" tableNumber="4.18" />

      <h6>Supplementary Resources</h6>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.aws.amazon.com/pdfs/wellarchitected/latest/machine-learning-lens/wellarchitected-machine-learning-lens.pdf#machine-learning-lens"
          >
            AWS Well-Architected Framework Machine Learning Lens
          </a>
          :&nbsp; extensive guidance on different AI Governance best practices across the AI lifecycle<sup>25</sup>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://d1.awsstatic.com/responsible-machine-learning/AWS_Responsible_Use_of_ML_Whitepaper_1.2.pdf"
          >
            AWS' responsible use of machine learning
          </a>
          :&nbsp; guidance on how to deploy and operate ML systems<sup>26</sup>
        </li>
        <li>
          <a target="_blank" rel="noreferrer" href="https://aws.amazon.com/uki/cloud-services/uk-gov-ai-safety-summit/">
            AWS' AI Safety guidelines
          </a>
          :&nbsp; AI Governance policies focused on AI safety prepared for the UK AI Safety Summit<sup>27</sup>
        </li>
      </ul>
      <br />

      <h4 id={contents[10].anchor}>{contents[10].text}</h4>
      <figure>
        <img src={google} alt="google framework" />
      </figure>

      <h6>Introduction</h6>
      <p>
        Google released the Secure AI Framework (
        <a
          target="_blank"
          rel="noreferrer"
          href="https://services.google.com/fh/files/blogs/google_secure_ai_framework_approach.pdf"
        >
          SAIF
        </a>
        ) Approach in June 2023.28 The SAIF is "a conceptual framework for secure artificial intelligence (AI) systems"
        with strong influence from security best practices. Rather than the SAIF itself, this document offers guidance
        on how the SAIF may be applied while the SAIF itself isn't yet publicly accessible.
      </p>
      <p>
        Google's SAIF covers several priority elements for each of its six core elements that are relevant to security
        and risk professionals and cross-functional teams, including business owners, technical practitioners, legal
        experts, and ethics specialists.
      </p>
      <p>
        Google has committed to SAIF's ongoing development alongside customers, partners, industry stakeholders, and
        governments. Future development will include a deeper exploration of specific topics, aiming to enable
        secure-by-default AI advancement across the industry. Google has not yet indicated when it will publicly release
        SAIF.
      </p>

      <h6>Stated Aims</h6>
      <ul>
        <li>
          Provide high-level practical considerations on how organizations could go about building the SAIF approach
          into their existing or new adoptions of AI
        </li>
        <li>Outlining the priority elements that need to be addressed under each of the SAIF's six core elements </li>
      </ul>

      <h6>Content Summary</h6>
      <p>
        Google's "Secure AI Framework Approach" translates security best practices into a practical implementation guide
        organized around six core elements, with an emphasis on integrating security considerations throughout the AI
        lifecycle.
      </p>
      <p>
        The document is structured in four implementation steps followed by a detailed exploration of the six core
        elements. The initial three steps cover understanding AI use cases, assembling cross-functional teams,
        establishing baseline AI knowledge, and applying the framework elements.
      </p>
      <p>
        The six core elements then address expanding security foundations, extending detection and response
        capabilities, automating defenses, harmonizing platform controls, adapting control mechanisms, and
        contextualizing AI system risks. Each core element contains specific, actionable guidance. For example, "Expand
        strong security foundations" covers Data Governance, supply chain management, security control adaptation, and
        talent retention.
      </p>
      <p>
        The framework places particular attention on risk assessment and threat response, with specific emphasis on
        evolving AI security and resilience threats such as prompt injection and data poisoning. While the framework
        defers some technical details to future documentation, it comprehensively covers organizational, operational,
        and security considerations for AI implementation.
      </p>

      <h6>Best Practice Highlights</h6>
      <div className="table">
        <TableContainer style={tableStyles.tableContainer}>
          <Table size="small">
            <TableHeader
              tableNumber="4.19"
              tableTitle="Best Practices"
              framework="Google's Secure AI Framework Approach"
              subtitle="Risk Mitigation"
            />
            <TableRow>
              <TableCell>
                <strong>Extend detection and response </strong>(secure and resilient, p. 6)
                <p>
                  Develop an understanding of threats that matter for AI usage scenarios, the types of AI used, etc.
                  Organizations that use AI systems must understand the threats relevant to their specific AI usage
                  scenarios. This includes understanding the types of AI they use, the data they use to train AI
                  systems, and the potential consequences of a security breach. By taking these steps, organizations can
                  help protect their AI systems from attack.{" "}
                </p>
                <p>
                  Prepare to respond to attacks against AI and issues raised by AI output. Organizations that use AI
                  systems must have a plan for detecting and responding to security incidents and mitigating the risks
                  of AI systems making harmful or biased decisions. By taking these steps, organizations can help
                  protect their AI systems and users from harm.{" "}
                </p>
                <p>
                  Specifically focusing on AI output for gen AI prepare to enforce content safety policies. Gen AI is a
                  powerful tool for creating a variety of content, from text to images to videos. However, this power
                  also comes with the potential for abuse. For example, gen AI could be used to create harmful content,
                  such as hate speech or violent images. To mitigate these risks, it is important to prepare to enforce
                  content safety policies.{" "}
                </p>
                <p>
                  Adjust your abuse policy and incident response processes to AI-specific incident types, such as
                  malicious content creation or AI privacy violations. As AI systems become more complex and pervasive,
                  it is important to adjust your abuse policy to deal with abuse cases and adjust your incident response
                  processes to account for AI-specific incident types. These types of incidents can include malicious
                  content creation, AI privacy violations, AI bias, and general abuse of the system.
                </p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Adjust controls </strong>(secure and resilient, p. 8)
                <p>
                  Conduct Red Team exercises to improve safety and security for AI-powered products and capabilities.
                  Red Team exercises are a security testing method where a team of ethical hackers attempts to exploit
                  vulnerabilities in an organization's systems and applications. This can help organizations identify
                  and mitigate security risks in their AI systems before malicious actors can exploit them.{" "}
                </p>
                <p>
                  Stay on top of novel attacks, including prompt injection, data poisoning, and evasion attacks. These
                  attacks can exploit vulnerabilities in AI systems to cause harm, such as leaking sensitive data,
                  making incorrect predictions, or disrupting operations. By staying up-to-date on the latest attack
                  methods, organizations can take steps to mitigate these risks.{" "}
                </p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Automate defenses </strong>(secure and resilient, p. 7)
                <p>
                  Identify the list of AI security capabilities focused on securing AI systems, training data pipelines,
                  etc. AI security technologies can protect AI systems from a variety of threats, including data
                  breaches, malicious content creation, and AI bias. Some of these technologies include traditional data
                  encryption, access control, and auditing, which can be augmented with AI and newer technologies that
                  can perform training data protection, as well as model protection.
                </p>
                <p>
                  Use AI defenses to counter AI threats, but keep humans in the loop for decisions when necessary. AI
                  can be used to detect and respond to AI threats, such as data breaches, malicious content creation,
                  and AI bias. However, humans must remain in the loop for important decisions, such as determining what
                  constitutes a threat and how to respond. This is because AI systems can be biased or make mistakes,
                  and human oversight is necessary to ensure that AI systems are used ethically and responsibly.
                </p>
                <p>
                  Use AI to automate time-consuming tasks, reduce toil, and speed up defensive mechanisms. Although it
                  seems like a more simplistic point in light of the uses of AI, using AI to speed up time-consuming
                  tasks will ultimately lead to faster outcomes. For example, it can be time-consuming to reverse
                  engineer a malware binary. However, AI can quickly review the relevant code and provide an analyst
                  with actionable information. Using this information, the analyst could then ask the system to generate
                  a YARA rule looking for these actions. In this example, there is an immediate reduction of toil and
                  faster output for the defensive posture.
                </p>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </div>

      <h6>Assessment Results</h6>
      <AssessmentTable framework="Google's Secure AI Framework Approach" tableNumber="4.20" />
      <AssessmentTable framework="Google's Secure AI Framework Approach" tableNumber="4.21" />

      <h6>Supplementary Resources</h6>
      <ul>
        <li>
          <a target="_blank" rel="noreferrer" href="https://ai.google/responsibility/responsible-ai-practices/">
            AI principles
          </a>
          :&nbsp; list of principles, best practices, and AI responsibility reports <sup>29</sup>
        </li>

        <li>
          <a target="_blank" rel="noreferrer" href="https://deepmind.google/public-policy/ai-summit-policies/">
            Google Deepmind's safety approach
          </a>
          :&nbsp; AI Governance policies focused on AI safety prepared for the UK AI Safety Summit <sup>30</sup>
        </li>
      </ul>

      <h2 id={contents[11].anchor}>{contents[11].text}</h2>
      <AssessmentComparisonTable />

      <div className="table">
        <TableContainer style={tableStyles.tableContainer}>
          <Table size="small">
            <TableRow style={{ ...tableStyles.centerText, ...tableStyles.tealBackground }}>
              <TableCell colSpan={2}>
                <strong>Table 4.23 – Best Practice Highlights</strong>
              </TableCell>
            </TableRow>
            <TableRow style={tableStyles.lightGrayBackground}>
              <TableCell>
                <strong>Subcategories</strong>
              </TableCell>
              <TableCell>
                <strong>Highlights</strong>
              </TableCell>
            </TableRow>
            <TableRow style={{ ...tableStyles.centerText, ...tableStyles.lightTealBackground }}>
              <TableCell colSpan={2}>
                <strong>Risk Mitigation</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>All areas</TableCell>
              <TableCell>
                <ul>
                  <li>
                    <strong>NIST</strong>:&nbsp; GOVERN 6
                  </li>
                  <li>
                    <strong>NIST</strong>:&nbsp; MAP 5
                  </li>
                  <li>
                    <strong>NIST</strong>:&nbsp; MEASURE 3
                  </li>
                  <li>
                    <strong>NIST</strong>:&nbsp; MANAGE 1
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Secure and resilient</TableCell>
              <TableCell>
                <ul>
                  <li>
                    <strong>Microsoft</strong>:&nbsp; failures and remediations
                  </li>
                  <li>
                    <strong>AWS</strong>:&nbsp; security assurance
                  </li>
                  <li>
                    <strong>Google</strong>:&nbsp; extend detection and response
                  </li>
                  <li>
                    <strong>Google</strong>:&nbsp; adjust controls
                  </li>
                  <li>
                    <strong>Google</strong>:&nbsp; automate defenses
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Explainable and interpretable</TableCell>
              <TableCell>
                <ul>
                  <li>
                    <strong>OECD</strong>:&nbsp; model transparency and explainability
                  </li>
                  <li>
                    <strong>Singapore</strong>:&nbsp; repeatability
                  </li>
                  <li>
                    <strong>ATI</strong>:&nbsp; transparency{" "}
                  </li>
                  <li>
                    <strong>Microsoft</strong>:&nbsp; system intelligibility for decision-making
                  </li>
                  <li>
                    <strong>AWS</strong>:&nbsp; responsible use of AI
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Valid and reliable</TableCell>
              <TableCell>
                <ul>
                  <li>
                    <strong>Singapore</strong>:&nbsp; repeatability
                  </li>
                  <li>
                    <strong>ATI</strong>:&nbsp; accuracy and performance metrics
                  </li>
                  <li>
                    <strong>Microsoft</strong>:&nbsp; system intelligibility for decision-making
                  </li>
                  <li>
                    <strong>AWS</strong>:&nbsp; security assurance
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Accountable and transparent</TableCell>
              <TableCell>
                <ul>
                  <li>
                    <strong>OECD</strong>:&nbsp; model transparency and explainability
                  </li>
                  <li>
                    <strong>Singapore</strong>:&nbsp; traceability
                  </li>
                  <li>
                    <strong>ATI</strong>:&nbsp; transparency{" "}
                  </li>
                  <li>
                    <strong>Microsoft</strong>:&nbsp; quality of service
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Privacy-enhanced</TableCell>
              <TableCell>
                <ul>
                  <li>
                    <strong>OECD</strong>:&nbsp; identifiability of personal data
                  </li>
                  <li>
                    <strong>Microsoft</strong>:&nbsp; system intelligibility for decision-making
                  </li>
                  <li>
                    <strong>AWS</strong>:&nbsp; security assurance
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Fair – with harmful bias managed</TableCell>
              <TableCell>
                <ul>
                  <li>
                    <strong>ATI</strong>:&nbsp; source integrity and measurement accuracy
                  </li>
                  <li>
                    <strong>Microsoft</strong>:&nbsp; system intelligibility for decision-making
                  </li>
                  <li>
                    <strong>AWS</strong>:&nbsp; responsible use of AI
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Safe</TableCell>
              <TableCell>
                <ul>
                  <li>
                    <strong>Microsoft</strong>:&nbsp; failures and remediations
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow style={{ ...tableStyles.centerText, ...tableStyles.lightTealBackground }}>
              <TableCell colSpan={2}>
                <strong>Data Management</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>All areas</TableCell>
              <TableCell>
                <ul>
                  <li>
                    <strong>ATI</strong>:&nbsp; responsible Data Management
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Data Quality</TableCell>
              <TableCell>
                <ul>
                  <li>
                    <strong>OECD</strong>:&nbsp; Data Quality and appropriateness
                  </li>
                  <li>
                    <strong>OECD</strong>:&nbsp; the provenance of data and input
                  </li>
                  <li>
                    <strong>Singapore</strong>:&nbsp; Ensuring Data Quality
                  </li>
                  <li>
                    <strong>AWS</strong>:&nbsp; Data Engineering
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Data Security</TableCell>
              <TableCell>
                <ul>
                  <li>
                    <strong>ATI</strong>:&nbsp; Data Security
                  </li>
                  <li>
                    <strong>ATI</strong>:&nbsp; consent (or legitimate basis) for processing
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Metadata</TableCell>
              <TableCell>
                <ul>
                  <li>
                    <strong>OECD</strong>:&nbsp; the provenance of data and input
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Data Governance</TableCell>
              <TableCell>
                <ul>
                  <li>
                    <strong>Microsoft</strong>:&nbsp; Data Governance and management{" "}
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Data Integration & Interoperability</TableCell>
              <TableCell>
                <ul>
                  <li>
                    <strong>AWS</strong>:&nbsp; Data Engineering
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Data Modeling & Design</TableCell>
              <TableCell>
                <ul>
                  <li>
                    <strong>AWS</strong>:&nbsp; Data Engineering
                  </li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Data Architecture</TableCell>
              <TableCell>
                <ul>
                  <li>
                    <strong>AWS</strong>:&nbsp; Data Architecture
                  </li>
                </ul>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </div>

      <h4 id={contents[12].anchor}>{contents[12].text}</h4>
      <p>
        The most evident pattern from our assessments is that leading public sector and vendor-based AI Governance
        frameworks disproportionately guide risk mitigation compared with Data Management. The mean overall risk
        mitigation rating for frameworks is 6.8, while the mean overall Data Management rating is 3.5.
      </p>
      <p>
        This pattern holds across all three criteria, although there's more variance for risk mitigation guidance. The
        range in mean ratings between Data Management criteria was 0.6 between breadth (3.3) and depth (3.9), far more
        narrow than a range of 2.4 for risk mitigation, also between breadth (8.4) and depth (6) but with breadth being
        higher than depth, unlike for Data Management.
      </p>
      <p>
        With a difference of 5.1, the difference in mean breadth between risk mitigation and Data Management guidance
        has an outsized impact on the variance in overall ratings. However, the difference in depth (2.1) and
        practicality (2.7) ratings remain moderately large, indicating that the difference in coverage of risk
        mitigation and Data Management guidance is likely informative about what businesses should expect from these
        frameworks and isn't simply the result of variance in the breadth of subcategories covered.
      </p>
      <p>
        Looking at the aggregated highlights by subcategory, we can see that the average number of relevant highlights
        per subcategory is far higher for risk mitigation (3.6) than for Data Management (1.6). Indeed, half of DAMA's{" "}
        <em>
          <a target="_blank" rel="noreferrer" href="http://amzn.to/32oK8hH">
            DMBOK
          </a>
        </em>{" "}
        Data Management practice areas aren't featured at all, and of those that do, half of the highlights recorded
        relate to data quality.<sup>31</sup> Highlights were selected without regard for the subcategories they related
        to.
      </p>
      <p>
        Although the sample size is very small and the number of practices highlighted per framework varies, the skew in
        our highlights towards data quality guidance is likely representative of the concentrated relevance of a small
        number of data practices to AI Governance, including data quality. This difference, on top of the large
        difference in breadth ratings between best practice categories, might indicate how limited a benchmark the Data
        Management subcategories from the <em>DMBOK</em> are for fairly rating the breadth of relevant data practices AI
        Governance frameworks provide guidance on.
      </p>
      <p>
        The possibility that there is only a limited set of Data Management practices, alongside the centrality of risk
        mitigation to AI Governance as a whole, might explain the large differences in breadth, depth, and practicality
        assessments between best practice categories. However, it's important to note that the range of best practice
        categories relevant to effective AI Governance is wider than just risk mitigation and Data Management.
      </p>
      <p>
        For example, guidance on operational tasks and how frameworks may be practically implemented are each essential
        to a successful AI Governance framework. However, as discussed across our Limitations and Methodology sections,
        measuring each of these ended up outside this project's scope. Let alone limitations in our assessments of risk
        mitigation and Data Management guidance, our assessments thus also do not reflect the full extent of value
        businesses could gain from investigating them further.
      </p>
      <p>
        Overall, our framework profiles reveal the value propositions of each framework for two key AI Governance best
        practice categories, in particular, the generally greater breadth, depth, and practicality of risk mitigation
        guidance over Data Management guidance. However, limitations, including how the breadth of Data Management
        guidance was measured and the breadth of AI Governance best practice categories assessed, mean that each
        framework's overall general value proposition may be far higher than our assessments indicate. Each business
        will gain more or less value from guidance from certain organizations or on certain best practice categories
        depending on its objectives.
      </p>

      <h2 id={contents[13].anchor}>{contents[13].text}</h2>
      <p>
        Our assessment of seven leading AI governance frameworks reveals a clear pattern:&nbsp; while frameworks
        generally provide robust guidance on risk mitigation, they offer substantially less comprehensive coverage of
        Data Management practices. This imbalance suggests that organizations may need to combine insights from multiple
        frameworks to build a comprehensive AI Governance approach.
      </p>
      <p>
        For businesses prioritizing risk mitigation, NIST's AI RMF stands out as particularly valuable, offering
        extensive context and step-by-step implementation guidance.<sup>3</sup> Meanwhile, AWS's framework provides the
        strongest Data Management guidance, particularly when supplemented with their Well-Architected Framework Machine
        Learning Lens and paired with OECD's framework for data quality practices.<sup>8, 24, 25</sup>
      </p>
      <p>
        The varying strengths of these frameworks highlight that no single solution will meet all organizational needs.
        Instead, businesses should approach AI Governance framework selection based on their specific objectives, risk
        profiles, and operational contexts. For example, some businesses that prioritize mitigating ethical concerns and
        human rights impacts may draw from frameworks like The Alan Turing Institute's HUDERAF, while others primarily
        concerned with security risks might benefit more from Google's SAIF Approach.<sup>17, 28</sup>
      </p>
      <p>
        Businesses will approach using AI Governance frameworks differently. While some will directly apply one specific
        framework, others will use multiple frameworks for different purposes or internally develop their own framework.
        IAPP and EY{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://iapp.org/media/pdf/resource_center/iapp_ey_professionalizing_organizational_ai_governance_report.pdf"
        >
          survey results
        </a>{" "}
        from early 2023 indicate how the global region a business is based in influences their approach to implementing
        AI Governance programs.<sup>6</sup>
      </p>
      <p>
        For example, the most common approach in North America is to use the US-created NIST AI RMF (50%) while Asian
        businesses mainly use internally developed AI Governance frameworks (57%). By contrast, a majority of EU
        businesses don't even use a specific AI Governance framework and instead use existing regulation such as the
        GDPR to guide their AI Governance programs (57%).<sup>6</sup>
      </p>
      <p>
        The diversity of approaches businesses take to using AI Governance frameworks indicates that there's currently
        no general consensus on the best approach to ensuring AI Systems are deployed safely to maximize business
        benefit. Businesses should consider how their objectives and resource capacity align with different approaches
        to select the best-fit approach.
      </p>
      <br />

      <ExtendedDescription title="Endnotes" description={Endnotes()} blog={true} />
    </div>
  );
};

const Endnotes = () => {
  const endnotes = aigfcSources.filter((source) => source.id.includes("gov")).map((source) => source.id);

  return (
    <ol style={{ marginLeft: 0, paddingLeft: "1em", paddingRight: ".75em" }}>
      {endnotes.map((endnote) => (
        <EndnoteSource endnoteId={endnote} />
      ))}
    </ol>
  );
};
