const customSelector: CustomSelectors<"products"> = {
  products: [
    { title: "Data Strategy", anchor: "data-strategy" },
    { title: "CDMP Fundamentals Exam", anchor: "cdmp-fundamentals" },
    { title: "CDMP Specialist Exam", anchor: "cdmp-specialist" },
    { title: "CIPP/E Exam", anchor: "data-privacy" },
    { title: "CAP Exam", anchor: "cap" },
    { title: "Data Science", anchor: "data-science" },
    { title: "Career Services", anchor: "service" },
    { title: "Physical Products", anchor: "physical" },
  ],
};

export default customSelector;
