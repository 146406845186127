const tableStyles: { [key: string]: React.CSSProperties } = {
  tableContainer: { background: "white", border: "1px solid #c1c1c1", marginBottom: "1em" },
  grayText: { color: "#4f4e4e" },
  tealBackground: { background: "#bdd8da" },
  lightTealBackground: { background: "#dee8e9" },
  lightGrayBackground: { background: "#efefef" },
  centerText: { textAlign: "center" },
  verticalAlign: { verticalAlign: "middle" },
};

export default tableStyles;
