const series: CustomSelectors<"aigfc"> = {
  aigfc: [
    {
      title: "0. Introduction",
      anchor: "intro",
      active: true,
    },
    {
      title: "1.  Adoption of AI Systems",
      anchor: "adoption",
      active: true,
    },
    {
      title: "2.  AI Risks",
      anchor: "risks",
      active: true,
    },
    {
      title: "3.  AI Governance",
      anchor: "governance",
      active: true,
    },
    {
      title: "4.  Framework Profiles",
      anchor: "frameworks",
      active: true,
    },
    {
      title: "5.  Methodology",
      anchor: "methodology",
      active: true,
    },
  ],
};

export default series;
