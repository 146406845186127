import certifications from "../../img/blog/best-certifications.webp";
import certificationsPreview from "../../img/helmet/blog/best-certifications.png";
import cheers from "../../img/products/membership/cheers_rec.webp";
import cheersPreview from "../../img/helmet/blog/cheers_rec.png";
import coach from "../../img/blog/pushups_sm.webp";
import coachPreivew from "../../img/helmet/blog/pushups.jpeg";
import crossedArms from "../../img/blog/crossed-arms_sm.webp";
import crossedArmsPreview from "../../img/helmet/blog/crossed-arms.jpeg";
import discussion from "../../img/blog/discussion.webp";
import discussionsPreview from "../../img/helmet/blog/discussion.jpg";
import heart from "../../img/blog/heart.webp";
import heartPreview from "../../img/helmet/blog/heart.jpg";
import jobs from "../../img/blog/briefcase.webp";
import jobsPreview from "../../img/helmet/blog/briefcase.jpeg";
import milkyway from "../../img/blog/milky-way_sm.webp";
import milkwayPreview from "../../img/helmet/blog/milky-way.jpeg";
import moneyTree from "../../img/blog/money-tree.webp";
import moneyTreePreview from "../../img/helmet/blog/money-tree.jpg";
import mountain from "../../img/blog/mountain-man_sm.webp";
import mountainPreview from "../../img/helmet/blog/mountain-man.jpeg";
import office from "../../img/blog/office.webp";
import officePreview from "../../img/helmet/blog/office.jpeg";
import papers from "../../img/blog/looking-at-papers.webp";
import papersPreview from "../../img/helmet/blog/looking-at-papers.jpeg";
import pomodoro from "../../img/blog/pomodoro_sm.webp";
import pomodoroPreview from "../../img/helmet/blog/pomodoro.jpeg";
import questions from "../../img/blog/test-taker.webp";
import questionsPreview from "../../img/helmet/blog/test-taker.jpeg";
import riyadh from "../../img/blog/ksa/riyadh.webp";
import riyadhPreview from "../../img/helmet/blog/riyadh.jpg";
import swimmer from "../../img/blog/swimmer_sm.webp";
import swimmerPreview from "../../img/helmet/blog/swimmer.jpeg";
import tealBook from "../../img/blog/teal-book_sm.webp";
import tealBookPreview from "../../img/helmet/blog/teal-book.jpeg";
import tools from "../../img/blog/research-tools/tools.webp";
import toolsPreview from "../../img/helmet/blog/tools.jpg";
import training from "../../img/blog/training/training.webp";
import trainingPreview from "../../img/helmet/blog/training.png";
import backpack from "../../img/blog/backpack.webp";
import backpackPreview from "../../img/helmet/blog/backpack.jpg";
import dmboks from "../../img/blog/dmbok-stickies.webp";
import dmboksPreview from "../../img/helmet/blog/dmbok-stickies.jpg";
import convo from "../../img/blog/convo.webp";
import convoPreview from "../../img/helmet/blog/convo.jpg";
import membership from "../../img/products/membership/cheers_sq.webp";
import membershipPreview from "../../img/helmet/blog/cheers_rec.png";
import menTalking from "../../img/blog/aigfc/men-talking.webp";
import menTalkingPreview from "../../img/helmet/blog/men-talking.jpeg";
import aiAdoptionMeeting from "../../img/blog/aigfc/ai-adoption-meeting.webp";
import aiAdoptionMeetingPreview from "../../img/helmet/blog/ai-adoption-meeting.jpeg";
import aiRisks from "../../img/blog/aigfc/ai-risks.webp";
import aiRisksPreview from "../../img/helmet/blog/ai-risks.jpeg";
import governance from "../../img/blog/aigfc/governance.webp";
import governancePreview from "../../img/helmet/blog/governance.jpeg";
import frameworks from "../../img/blog/aigfc/frameworks.webp";
import frameworksPreview from "../../img/helmet/blog/frameworks.jpeg";
import meetings from "../../img/blog/aigfc/meeting.webp";
import meetingsPreview from "../../img/helmet/blog/meeting.jpeg";

const posts: Post[] = [
  {
    nickname: "aigfc-intro",
    series: "aigfc",
    title: "Introduction to AI Governance Frameworks",
    subtitle: `A brief introduction to seven leading AI Governance frameworks from public and private sector institutions,
    offering strategic insight on how to effectively deploy and operate AI systems. AI Governance Frameworks Comparison post 1 of 6.`,
    short: `Best practices from seven leading AI Governance frameworks.`,
    image: {
      img: menTalking,
      alt: "men talking",
      photog: "Henri Mathieu-Saint-Laurent",
      photogLink: "https://www.pexels.com/@henri-mathieu/",
      source: "Pexels",
      sourceLink: "https://www.pexels.com/photo/group-of-businessmen-having-a-conversation-8349432/",
      previewImage: menTalkingPreview,
    },
    link: "/resources/aigfc/intro",
    topic: "Data Strategy",
    author: ["Mac Jordan"],
    postDate: new Date("Feb 7, 2025"),
    updateDate: new Date("Feb 19, 2025"),
    related: ["dmma", "best-practices", "projections", "best-certifications", "specialist", "data-strategy"],
    showOnHomepage: true,
    showAbout: true,
  },
  {
    nickname: "aigfc-adoption",
    series: "aigfc",
    title: "Adoption of AI Systems",
    subtitle: `Exploring growth, barriers, and strategic priorities in AI system adoption. AI Governance Frameworks Comparison post 2 of 6.`,
    short: `Growth, barriers, and strategic priorities in AI adoption.`,
    image: {
      img: aiAdoptionMeeting,
      alt: "people in a meeting",
      photog: "cottonbro studio",
      photogLink: "https://www.pexels.com/@cottonbro/",
      source: "Pexels",
      sourceLink: "https://www.pexels.com/photo/people-in-a-meeting-7438102/",
      previewImage: aiAdoptionMeetingPreview,
    },
    link: "/resources/aigfc/adoption",
    topic: "Data Strategy",
    author: ["Mac Jordan"],
    postDate: new Date("Feb 14, 2025"),
    updateDate: new Date("Feb 24, 2025"),
    related: ["dmma", "best-practices", "projections", "best-certifications", "specialist", "data-strategy"],
    showOnHomepage: true,
  },
  {
    nickname: "aigfc-risks",
    series: "aigfc",
    title: "AI Risks and Trustworthiness",
    subtitle: `A discussion of how AI Governance can address potential harms from AI systems. AI Governance Frameworks Comparison post 3 of 6.`,
    short: `Using AI Governance to address potential harms from AI systems`,
    image: {
      img: aiRisks,
      alt: "group of business people talking",
      photog: "Tiger Lily",
      photogLink: "https://www.pexels.com/@tiger-lily/",
      source: "Pexels",
      sourceLink: "https://www.pexels.com/photo/woman-in-gray-blazer-sitting-by-the-table-using-laptop-7108466/",
      previewImage: aiRisksPreview,
    },
    link: "/resources/aigfc/risks",
    topic: "Data Strategy",
    author: ["Mac Jordan"],
    postDate: new Date("Feb 21, 2025"),
    updateDate: new Date("Feb 21, 2025"),
    related: ["dmma", "best-practices", "projections", "best-certifications", "specialist", "data-strategy"],
    showOnHomepage: true,
  },
  {
    nickname: "aigfc-governance",
    series: "aigfc",
    title: "Importance of AI Governance",
    subtitle: `Understanding the systems for mitigating AI risks and maximizing benefits. AI Governance Frameworks Comparison post 4 of 6.`,
    short: `Understanding the systems for mitigating AI risks and maximizing benefits.`,
    image: {
      img: governance,
      alt: "group of business people talking",
      photog: "fauxels",
      photogLink: "https://www.pexels.com/@fauxels/",
      source: "Pexels",
      sourceLink: "https://www.pexels.com/photo/photo-of-people-near-laptops-3184312/",
      previewImage: governancePreview,
    },
    link: "/resources/aigfc/governance",
    topic: "Data Strategy",
    author: ["Mac Jordan"],
    postDate: new Date("Feb 28, 2025"),
    updateDate: new Date("Mar 5, 2025"),
    related: ["dmma", "best-practices", "projections", "best-certifications", "specialist", "data-strategy"],
    showOnHomepage: true,
  },
  {
    nickname: "aigfc-frameworks",
    series: "aigfc",
    title: "AI Governance Frameworks",
    subtitle: `Value potential of seven leading frameworks drawn from public and private sector institutions. AI Governance Frameworks Comparison post 5 of 6.`,
    short: `Value potential of seven leading frameworks.`,
    image: {
      img: frameworks,
      alt: "woman writing on a blackboard",
      photog: "fauxels",
      photogLink: "https://www.pexels.com/@fauxels/",
      source: "Pexels",
      sourceLink: "https://www.pexels.com/photo/photo-of-woman-writing-on-blackboard-3184644/",
      previewImage: frameworksPreview,
    },
    link: "/resources/aigfc/frameworks",
    topic: "Data Strategy",
    author: ["Mac Jordan"],
    postDate: new Date("Mar 14, 2025"),
    updateDate: new Date("Mar 16, 2025"),
    related: ["dmma", "best-practices", "projections", "best-certifications", "specialist", "data-strategy"],
    showOnHomepage: true,
  },
  {
    nickname: "aigfc-methodology",
    series: "aigfc",
    title: "AI Governance Framework Comparison Series Methodology",
    subtitle: `Understanding assessment methodology and limitations. AI Governance Frameworks Comparison post 6 of 6.`,
    short: `Understanding assessment methodology and limitations`,
    image: {
      img: meetings,
      alt: "group of people having a meeting in the office",
      photog: "Thirdman",
      photogLink: "https://www.pexels.com/@thirdman/",
      source: "Pexels",
      sourceLink: "https://www.pexels.com/photo/a-group-of-people-having-a-meeting-in-the-office-7651922/",
      previewImage: meetingsPreview,
    },
    link: "/resources/aigfc/methodology",
    topic: "Data Strategy",
    author: ["Mac Jordan"],
    postDate: new Date("Mar 21, 2025"),
    updateDate: new Date("Mar 21, 2025"),
    related: ["dmma", "best-practices", "projections", "best-certifications", "specialist", "data-strategy"],
    showOnHomepage: true,
  },
  {
    nickname: "dmbokv2r",
    title: "Changes to DMBOK v2 Revised",
    subtitle: `A detailed look at important updates to specific chapters of the <em>DMBOK</em> v2 Revised.`,
    short: `Updates to the <em>DMBOK</em> v2 Revised`,
    image: {
      img: dmboks,
      alt: "dmboks",
      photog: "",
      photogLink: "",
      source: "",
      sourceLink: "",
      previewImage: dmboksPreview,
    },
    link: "/resources/dmbok-v2-revised",
    topic: "Data Strategy",
    author: ["Xolani Holomisa"],
    postDate: new Date("Dec 6, 2024"),
    updateDate: new Date("Dec 6, 2024"),
    related: ["cdmp", "cdmp-training", "cdmp-practice-questions", "best-certifications", "specialist", "data-strategy"],
    showOnHomepage: true,
  },
  {
    nickname: "dcam",
    title: "Comparison of CDMP and DCAM certifications",
    subtitle: `Which certification is better for your career goals?
    Should you pursue the Certified Data Management Professional (CDMP) or the Data Management Capability Assessment Model (DCAM) accreditation?`,
    short: `How does the DCAM stack up?`,
    image: {
      img: backpack,
      alt: "dcam diagram",
      photog: "Samson Katt",
      photogLink: "https://www.pexels.com/@samson-katt/",
      source: "Pexels",
      sourceLink: "https://www.pexels.com/photo/joyful-ethnic-woman-standing-on-sunny-town-street-5225493/",
      previewImage: backpackPreview,
    },
    link: "/resources/comparing-dcam-and-cdmp",
    topic: "Data Strategy",
    author: ["Nicole Janeway"],
    postDate: new Date("Sep 14, 2024"),
    updateDate: new Date("Sep 14, 2024"),
    related: ["best-certifications", "data-governance-charter", "valuation", "data-driven", "analyst", "steward"],
    showOnHomepage: true,
  },
  {
    nickname: "valuation",
    title: "Strategies for a Data Valuation Assessment",
    subtitle: `A variety of thought experiments to quantify the value of data and data-related work.`,
    short: `How to quantify the value of data.`,
    image: {
      img: moneyTree,
      alt: "small jar filled with coins",
      photog: "micheile henderson",
      photogLink: "https://unsplash.com/@micheile",
      source: "Unsplash",
      sourceLink: "https://unsplash.com",
      previewImage: moneyTreePreview,
    },
    link: "/resources/data-valuation",
    topic: "Data Strategy",
    author: ["Nicole Janeway"],
    postDate: new Date("Jul 12, 2024"),
    updateDate: new Date("Aug 12, 2024"),
    related: ["data-governance-charter", "steward", "best-certifications", "cdmp", "data-strategy", "projections"],
    showOnHomepage: true,
  },
  {
    nickname: "data-governance-charter",
    title: "How to Write a Data Governance Charter",
    subtitle: `This crucial document outlines the core strategy and processes related to the exercise of control and authority over an organization's data assets.`,
    short: `This document outlines the core strategy and processes related to Data Governance.`,
    image: {
      img: office,
      alt: "a group of business people working at a table",
      photog: "photog",
      photogLink: "https://www.pexels.com/",
      source: "Pexels",
      sourceLink: "https://www.pexels.com/",
      previewImage: officePreview,
    },
    link: "/resources/data-governance-charter",
    topic: "Data Strategy",
    author: ["Nicole Janeway"],
    postDate: new Date("May 8, 2024"),
    updateDate: new Date("May 8, 2024"),
    related: ["valuation", "steward", "best-certifications", "data-strategy", "projections", "data-driven"],
    showOnHomepage: true,
  },
  {
    nickname: "business-value-aig",
    series: "aigfc",
    title: "Business Value of Leading AI Governance Frameworks",
    subtitle:
      "AI Governance frameworks can help ensure that AI systems are as ethical, accountable, and transparent as possible.",
    short: `Comparison of seven leading AI Governance frameworks.`,
    image: {
      img: convo,
      alt: "two people having a conversation on a bench",
      photog: "The Coach Space",
      photogLink: "https://www.pexels.com/@thecoachspace/",
      source: "Pexels",
      sourceLink: "https://www.pexels.com/photo/man-and-woman-sitting-on-wooden-bench-outside-buildings-2977513/",
      previewImage: convoPreview,
    },
    link: "/landing/aigfc/slides",
    topic: "Data Strategy",
    author: ["Mac Jordan"],
    postDate: new Date("Dec 7, 2024"),
    updateDate: new Date("Dec 7, 2024"),
    related: ["projections", "valuation", "best-practices", "data-governance-charter", "steward", "data-strategy"],
    showOnHomepage: true,
  },
  {
    nickname: "best-certifications",
    title: "Best Data-Related Certifications",
    subtitle:
      "Aim higher with your career ambitions for 2025. This list of seven top certifications spans a variety of data-related domains from Data Management to Data Engineering. Check out our report to learn how to attain the credentials to break into a new field or accelerate your career trajectory.",
    short: `This list of seven top certifications will help you attain valuable credentials in 2025.`,
    image: {
      img: certifications,
      alt: "young woman writing at a table",
      previewImage: certificationsPreview,
    },
    link: "/landing/best-certifications",
    topic: "Career Services",
    author: ["Mac Jordan", "Nicole Janeway"],
    postDate: new Date("Oct 20, 2023"),
    updateDate: new Date("Dec 8, 2024"),
    related: ["projections", "valuation", "data-governance-charter", "steward", "data-strategy", "cdmp"],
    showOnHomepage: true,
  },
  {
    nickname: "projections",
    title: "Data Management Job Market Projections",
    subtitle: `An exploration of how AI is redefining data-related roles.`,
    text: `An exploration of how AI is redefining data-related roles. While the past year has seen an explosion in the use and capabilities of AI, we'll help you understand what the AI gold rush means for the Data Management job market.`,
    short: `An exploration of how AI is redefining data-related roles.`,
    image: {
      img: jobs,
      alt: "young man walking with briefcase",
      photog: "Mizuno K",
      photogLink: "https://www.pexels.com/@mizunokozuki/",
      source: "Pexels",
      sourceLink:
        "https://www.pexels.com/photo/office-worker-with-confused-facial-expression-holding-a-briefcase-in-a-city-13801600/",
      previewImage: jobsPreview,
    },
    link: "/resources/data-management-job-projections",
    topic: "Data Strategy, Data Analytics, Data Science, Career Services",
    author: ["Mac Jordan", "Nicole Janeway"],
    postDate: new Date("Jan 30, 2024"),
    updateDate: new Date("Mar 28, 2024"),
    related: [
      "best-certifications",
      "valuation",
      "business-value-aig",
      "data-governance-charter",
      "steward",
      "data-strategy",
    ],
    showOnHomepage: true,
  },
  {
    nickname: "data-strategy",
    title: "Foundations of Data Strategy",
    subtitle: "Learn the steps to roll out an effective Data Management initiative.",
    text: `Learn the steps to roll out an effective Data Management initiative by mastering Data Governance, Data Quality, Data Architecture, and more.`,
    short: `Learn the steps to roll out an effective Data Management initiative.`,
    image: {
      img: mountain,
      alt: "climber in the mountains",
      photog: "Dino Reichmuth",
      photogLink: "https://unsplash.com/@dinoreichmuth",
      source: "Unsplash",
      sourceLink: "https://unsplash.com",
      previewImage: mountainPreview,
    },

    link: "/resources/foundations-of-data-strategy",
    topic: "Data Strategy",
    author: ["Nicole Janeway"],
    postDate: new Date("Dec 8, 2020"),
    updateDate: new Date("Aug 1, 2024"),
    related: ["best-certifications", "valuation", "data-governance-charter", "steward", "cdmp", "projections"],
    showOnHomepage: true,
  },
  {
    nickname: "best-practices",
    title: "Data Strategy Best Practices",
    subtitle: "The most effective ways to manage and leverage your data to achieve your organization's objectives.",
    short: `The most effective ways to manage and leverage your data.`,
    image: {
      img: papers,
      alt: "group of people looking at papers",
      previewImage: papersPreview,
    },

    link: "/resources/data-strategy-best-practices",
    topic: "Data Strategy",
    author: ["Mac Jordan", "Nicole Janeway"],
    postDate: new Date("Mar 16, 2024"),
    updateDate: new Date("Mar 28, 2024"),
    related: [
      "data-driven",
      "best-certifications",
      "steward",
      "data-governance-charter",
      "data-strategy",
      "projections",
    ],
    showOnHomepage: true,
  },
  {
    nickname: "data-driven",
    title: "Toward Data-Driven Decision-Making",
    subtitle: "In this writeup, we explore the increasingly prominent role of data in effective decision-making.",
    short: `Explore the increasingly important role of data in effective decision-making.`,
    image: {
      img: heart,
      alt: "a red heart on a screen of binary code",
      photog: "Alexander Sinn",
      photogLink: "https://unsplash.com/@swimstaralex",
      source: "Unsplash",
      sourceLink: "https://unsplash.com/photos/a-heart-is-shown-on-a-computer-screen-KgLtFCgfC28",
      previewImage: heartPreview,
    },

    link: "/resources/data-driven-decision-making",
    topic: "Data Strategy",
    author: ["Mac Jordan", "Nicole Janeway"],
    postDate: new Date("Mar 21, 2024"),
    updateDate: new Date("Mar 30, 2024"),
    related: [
      "best-practices",
      "best-certifications",
      "data-strategy",
      "data-governance-charter",
      "steward",
      "projections",
    ],
    showOnHomepage: true,
  },
  {
    nickname: "cdmp",
    title: "Why the CDMP is the Best Certification for Data Strategists",
    subtitle: "A practical introduction to the most valuable credential in Data Management.",
    short: `An introduction to the most valuable credential in Data Management.`,
    image: {
      img: swimmer,
      alt: "swimmer in an infinity pool",
      text: "Swimming upstream to address data quality issues.",
      photog: "alleksana",
      photogLink: "https://www.pexels.com/@alleksana/",
      source: "Pexels",
      sourceLink: "https://www.pexels.com/photo/man-swimming-in-an-infinity-pool-4239547/",
      previewImage: swimmerPreview,
    },
    link: "/resources/cdmp-fundamentals-exam",
    topic: "Data Strategy",
    author: ["Nicole Janeway"],
    postDate: new Date("Nov 20, 2021"),
    updateDate: new Date("Dec 6, 2024"),
    related: [
      "cdmp-training",
      "cdmp-practice-questions",
      "specialist",
      "best-certifications",
      "data-strategy",
      "data-driven",
    ],
    showOnHomepage: true,
  },
  {
    nickname: "specialist",
    title: "Essential Guide to the CDMP Specialist Exams",
    subtitle: "Everything you need to know to attain the Practitioner and Master level certifications.",
    text: `Everything you need to know to attain the Practitioner and Master level certifications.`,
    short: `Book recommendations to guide your preparation for Practitioner and Master level certifications.`,
    image: {
      img: milkyway,
      alt: "person standing triumphantly against a backdrop of stars",
      photog: "Evgeni Tcherkasski",
      photogLink: "https://pixabay.com/users/evgenit-4930349/",
      source: "Pixabay",
      sourceLink: "https://pixabay.com/",
      previewImage: milkwayPreview,
    },
    link: "/resources/cdmp-specialist-exam",
    topic: "Data Strategy",
    author: ["Nicole Janeway"],
    postDate: new Date("Dec 27, 2021"),
    updateDate: new Date("Nov 12, 2024"),
    related: [
      "cdmp",
      "cdmp-training",
      "cdmp-practice-questions",
      "best-certifications",
      "data-strategy",
      "data-driven",
    ],
    showOnHomepage: true,
  },
  {
    nickname: "cdmp-training",
    title: "CDMP Study Guide",
    subtitle: `Preparing for the Certified Data Management Professional (<a href="https://www.datastrategypros.com/resources/cdmp-fundamentals-exam" target='_blank' rel='noreferrer'>CDMP</a>) Fundamentals Exam can be a daunting task for
    data practitioners. With over 500 pages in the <a href='https://amzn.to/32oK8hH' target='_blank' rel='noreferrer'><em>DMBOK</em></a> that span 14 Data Management knowledge areas, there's
    a lot to remember.`,
    text: `Preparing for the Certified Data Management Professional (CDMP) Fundamentals Exam can be a daunting task for data practitioners. With over 500 pages in the <em>DMBOK</em> that span 14 Data Management knowledge areas, there's a lot to remember.`,
    short: `Practical study skills for every phase of getting ready for the CDMP exam.`,
    image: {
      img: training,
      alt: "a group of business people working at a table",
      photog: "Vlada Karpovich",
      photogLink: "https://www.pexels.com/@vlada-karpovich",
      source: "Pexels",
      sourceLink: "https://www.pexels.com/photo/colleagues-looking-at-documents-7433822/",
      previewImage: trainingPreview,
    },
    link: "/resources/cdmp-study-guide",
    topic: "Data Strategy",
    author: ["Mac Jordan"],
    postDate: new Date("Feb 28, 2024"),
    updateDate: new Date("Jun 8, 2024"),
    related: ["cdmp", "specialist", "cdmp-practice-questions", "best-certifications", "data-strategy", "data-driven"],
    showOnHomepage: true,
  },
  {
    nickname: "cdmp-practice-questions",
    title: "Free CDMP Practice Questions",
    subtitle: `What is the format of the CDMP exam? What types of questions can you expect? Plus, tips and tricks for passing the exam.`,
    short: "Twelve practice questions, plus tips and tricks for passing the exam.",
    image: {
      img: questions,
      alt: "a group of business people working at a table",
      photog: "Unseen Studio",
      photogLink: "https://unsplash.com/@uns__nstudio",
      source: "Unsplash",
      sourceLink: "https://unsplash.com/photos/person-writing-on-brown-wooden-table-near-white-ceramic-mug-s9CC2SKySJM",
      previewImage: questionsPreview,
    },
    link: "/resources/free-cdmp-practice-questions",
    topic: "Data Strategy",
    author: ["Nicole Janeway"],
    postDate: new Date("Mar 28, 2024"),
    updateDate: new Date("May 2, 2024"),
    related: ["cdmp", "specialist", "cdmp-training", "best-certifications", "data-strategy", "data-driven"],
    showOnHomepage: true,
  },
  {
    nickname: "research-tools",
    title: "Online Research Tools for Data Practitioners",
    subtitle:
      "How Data Management professionals can benefit from technical tools for productivity and speed to insight.",
    short: "Tools to get your data-related work done more quickly.",
    image: {
      img: tools,
      alt: "tools on a table",
      photog: "Pixabay",
      photogLink: "https://www.pexels.com/@pixabay/",
      source: "Pexels",
      sourceLink: "https://www.pexels.com/photo/several-handheld-tools-on-white-printer-paper-301703/",
      previewImage: toolsPreview,
    },
    link: "/resources/research-tools",
    topic: "Career Services",
    author: ["Roman Hauksson", "Mac Jordan"],
    postDate: new Date("Feb 20, 2024"),
    updateDate: new Date("Apr 4, 2024"),
    related: ["best-practices", "projections", "data-driven", "best-certifications", "data-strategy", "cdmp-training"],
    showOnHomepage: true,
  },
  {
    nickname: "cap",
    title: "Should You Take the Certified Analytics Professional (CAP) or aCAP Exam?",
    subtitle:
      "Data Science was once considered the <a href='https://hbr.org/2022/07/is-data-scientist-still-the-sexiest-job-of-the-21st-century' target='_blank' rel='noopener noreferrer'>sexiest career of the 21st century</a>. Could analytics training help you stand out in the crowded field of decision science?",
    text: `Data Science was once considered the sexiest career of the 21st century. Could analytics training help you stand out in the crowded field of decision science?`,
    short: "An introduction to this valuable certification for Data Analytics professionals.",
    image: {
      img: tealBook,
      alt: "young woman holding a book",
      photog: "Sincerely Media",
      photogLink: "https://unsplash.com/@sincerelymedia",
      source: "Unsplash",
      sourceLink: "https://unsplash.com",
      previewImage: tealBookPreview,
    },
    link: "/resources/certified-analytics-professional",
    topic: "Data Analytics",
    author: ["Nicole Janeway"],
    postDate: new Date("Aug 24, 2022"),
    updateDate: new Date("Aug 7, 2024"),
    related: ["best-certifications", "cdmp-training", "analyst", "steward", "data-strategy", "data-science"],
    showOnHomepage: true,
  },
  {
    nickname: "ksa",
    title: "A National Model for Data Management Standards",
    subtitle: "Saudi Arabia has developed new standards to help organizations manage their data effectively.",
    text: `Saudi Arabia has developed new standards based on the <em>DMBOK</em> to help organizations manage their data effectively.`,
    short: "A guide to the standards produced by KSA's National Data Management Office (NDMO).",
    image: {
      img: riyadh,
      alt: "Riyadh, Saudi Arabia",
      previewImage: riyadhPreview,
    },
    link: "/resources/ksa-data-standards",
    topic: "Data Strategy",
    author: ["Nicole Janeway"],
    postDate: new Date("Aug 14, 2023"),
    updateDate: new Date("Aug 19, 2023"),
    related: ["data-strategy", "projections", "best-certifications", "data-governance-charter", "steward"],
    showOnHomepage: true,
  },
  {
    nickname: "data-standards",
    title: "15 Data Management Standards",
    subtitle: "Access the tools you need to ace the CDMP Fundamentals Exam and advance your Data Strategy career.",
    image: {
      img: membership,
      alt: "office workers making a cheer",
      previewImage: membershipPreview,
    },
    link: "/landing/ksa-data-standards",
    topic: "Data Strategy",
    author: ["Nicole Janeway"],
    postDate: new Date("Aug 14, 2023"),
    updateDate: new Date("Aug 14, 2023"),
    showOnHomepage: false,
  },
  {
    nickname: "cippe-october-23",
    title: "CIPP/E Update from IAPP — What Changed in October 2023",
    subtitle: "Latest developments in Data Privacy & Protection.",
    text: `Ensure you're fully prepared to attain the most valuable credential in Data Privacy & Protection.`,
    short: "Latest developments in Data Privacy & Protection.",
    image: {
      img: "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*kwzY8r99tC1SwETRM_7EvQ.jpeg",
      alt: "distressed-looking people at a table",
    },
    link: "/resources/cippe-update-october-2023",
    topic: "Data Privacy",
    author: ["Nicole Janeway"],
    postDate: new Date("Jun 26, 2023"),
    updateDate: new Date("Oct 2, 2023"),
    related: ["data-strategy", "projections", "best-certifications", "data-governance-charter", "steward"],
    showOnHomepage: true,
  },
  {
    nickname: "aiken",
    title: "How Data Strategists use the Aiken Pyramid to Structure their Work",
    subtitle: "The optimal structure for prioritization and communication.",
    short: "The optimal structure for prioritization and communication.",
    image: {
      img: "https://miro.medium.com/v2/resize:fit:720/format:webp/1*PqID05P-VguXO6OUlDeErQ.jpeg",
      alt: "Aiken Pyramid",
    },
    link: "/resources/aiken-pyramid",
    topic: "Data Strategy",
    author: ["Nicole Janeway"],
    postDate: new Date("May 27, 2023"),
    updateDate: new Date("Aug 13, 2023"),
    related: ["data-strategy", "projections", "best-certifications", "data-governance-charter", "steward", "cdmp"],
    showOnHomepage: true,
  },
  {
    nickname: "dmma",
    title: "Comparison of Data Management Maturity Assessments (DMMA)",
    subtitle: "A comprehensive evaluation of the different models for assessing Data Management maturity.",
    short: `A thorough look at different models for assessing Data Management maturity.`,
    image: {
      img: discussion,
      alt: "a group of people in discussion",
      photog: "Fox",
      photogLink: "https://www.pexels.com/@hillaryfox/",
      source: "Pexels",
      sourceLink: "https://www.pexels.com/photo/woman-in-green-top-using-macbook-beside-group-of-people-1595392/",
      previewImage: discussionsPreview,
    },
    link: "/resources/data-management-maturity-assessment-dmma",
    topic: "Data Strategy",
    author: ["Mac Jordan", "Nicole Janeway"],
    postDate: new Date("Feb 26, 2024"),
    updateDate: new Date("Feb 17, 2025"),
    related: ["data-governance-charter", "projections", "best-certifications", "cdmp", "steward", "aiken"],
    showOnHomepage: true,
    showAbout: true,
  },
  {
    nickname: "data-science",
    title: "An Introduction to Data Science for Technology Leaders",
    subtitle: "Leverage machine learning capabilities to fulfill your organization's mission statement.",
    short: `Leverage machine learning to get work done more effectively.`,
    image: {
      img: "https://miro.medium.com/max/2000/1*fZovDHtXZptukm5nIieivA.jpeg",
      alt: "young woman opening a door",
    },
    link: "/resources/data-science-for-leaders",
    topic: "Data Science",
    author: ["Nicole Janeway"],
    postDate: new Date("Jan 3, 2022"),
    updateDate: new Date("Jun 4, 2024"),
    related: ["analyst", "steward", "data-warehouse", "data-strategy", "aiken", "data-driven"],
    showOnHomepage: true,
  },
  {
    nickname: "oath",
    title: "Are we ready for an Oath for Data Practitioners?",
    subtitle:
      "Just as we have best practices for setting up a database or creating a machine learning model, Data Practitioners should be bound to a set of rules when it comes to ethical conduct.",
    short: "An introduction to ethics commitments in data.",
    image: {
      img: crossedArms,
      alt: "a professional man with his arms crossed",
      previewImage: crossedArmsPreview,
    },

    link: "/resources/oath",
    topic: "Data Ethics",
    author: ["Nicole Janeway"],
    postDate: new Date("Nov 20, 2021"),
    updateDate: new Date("Nov 22, 2021"),
    related: ["data-science", "analyst", "steward", "data-warehouse", "data-strategy", "aiken"],
    showOnHomepage: true,
  },
  {
    nickname: "data-warehouse",
    title: "Comprehensive Guide to the Data Warehouse",
    subtitle:
      "Data analysis can't start until the data cleaning process is complete. Learn about the role of the data warehouse as a repository of analysis-ready datasets.",
    short: "Learn about the role of the data warehouse.",
    image: {
      img: "https://miro.medium.com/max/1400/0*aLkxYbRSp_z13uS6",
      alt: "a Jeep on safari",
    },
    link: "/resources/warehouse",
    topic: "Data Analytics",
    author: ["Nicole Janeway"],
    postDate: new Date("Nov 20, 2021"),
    updateDate: new Date("Apr 20, 2023"),
    related: ["analyst", "steward", "data-science", "data-strategy", "aiken", "data-driven"],
    showOnHomepage: true,
  },
  {
    nickname: "steward",
    title: "Data Owner vs. Data Steward vs. Data Trustee",
    subtitle:
      "We'll explore how to select the right job title for your Data Management team based on the history and connotations associated with different titles.",
    short: "How to select the right job title for your Data Management team.",
    image: {
      img: cheers,
      alt: "a group of people giving a cheer",
      previewImage: cheersPreview,
    },
    link: "/resources/data-steward",
    topic: "Data Strategy",
    author: ["Nicole Janeway"],
    postDate: new Date("Dec 8, 2023"),
    updateDate: new Date("Nov 12, 2024"),
    related: ["analyst", "data-warehouse", "data-strategy", "aiken", "data-driven"],
    showOnHomepage: true,
  },
  {
    nickname: "analyst",
    title: "Data Analyst vs. Data Scientist vs. Machine Learning Engineer",
    subtitle: `Explore the distinctions between these common job titles with the analogy of a track meet.`,
    short: "Explore the distinctions between common data-related job titles.",
    image: {
      img: "https://cdn-images-1.medium.com/max/1600/1*JczrH-rvHY7Ce5wm60M8Gw.webp",
      alt: "a runner getting ready to start a race on the track",
    },
    link: "/resources/data-analyst",
    topic: "Data Analytics, Data Science",
    author: ["Nicole Janeway"],
    postDate: new Date("Aug 2, 2020"),
    updateDate: new Date("June 4, 2024"),
    related: ["data-warehouse", "steward", "data-strategy", "aiken", "data-driven"],
    showOnHomepage: true,
  },
  {
    nickname: "pomodoro",
    title: "Why the Pomodoro Technique is so Effective for Data Practitioners",
    subtitle:
      "This time management method can help increase productivity and focus while making work more fun and less stressful.",
    short: "Improve your studies with this effective time management technique.",
    image: {
      img: pomodoro,
      alt: "a pair of hands holding cherry tomatoes",
      previewImage: pomodoroPreview,
    },
    link: "/resources/pomodoro-technique",
    topic: "Career Services",
    author: ["Nicole Janeway"],
    postDate: new Date("Feb 8, 2023"),
    updateDate: new Date("Jun 4, 2024"),
    related: ["cdmp-training", "data-governance-charter", "steward", "data-strategy", "aiken", "data-driven"],
    showOnHomepage: true,
  },
  {
    nickname: "coaching",
    title: "What Coaching Can Do for Your Data-Related Career",
    subtitle:
      "Typically, we assume Career Coaching is most beneficial for someone changing careers. While that may be true, a Coach can catapult your career whether you're switching positions or looking to grow in your current role. Let's get clear about how to attain your dream job.",
    short: "Learn how a Coach can help catapult your career.",
    image: {
      img: coach,
      alt: "a woman and her coach doing pushups",
      previewImage: coachPreivew,
    },
    link: "/resources/career-coaching",
    topic: "Career Services",
    author: ["Nicole Janeway"],
    postDate: new Date("Mar 22, 2022"),
    updateDate: new Date("Aug 5, 2023"),
    related: ["projections", "best-practices", "steward", "data-strategy", "aiken", "data-driven"],
    showOnHomepage: true,
  },
];

export default posts;
