import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";

const SourceFormContainer: React.VFC<SourceFormProps> = ({ firstName, email }) => {
  const [sourceIsBlank, setSourceIsBlank] = useState(true);
  const showDesktop = useMediaQuery("(min-width: 660px)");
  const [source, setSource] = useState("");
  const [sourceSubmit, setSourceSubmit] = useState(false);

  /* submit source */
  useEffect(() => {
    if (sourceSubmit) {
      fetch("/.netlify/functions/sendinblue-submit-source", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName: firstName,
          email: email,
          source: source,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceSubmit]);

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: showDesktop ? "row" : "column",
          gap: 16,
        }}
      >
        <div style={{ textAlign: showDesktop ? "left" : "center" }}>
          <p style={{ width: 210, marginBottom: 0 }}>One question to help us out...</p>
          <p>How did you hear about us?</p>
        </div>

        {sourceSubmit ? (
          <p style={{ width: 240, fontWeight: 900, paddingLeft: 20 }}>Thanks!</p>
        ) : (
          <div style={{ width: 240, display: "flex", justifyContent: "center", alignItems: "center", gap: 4 }}>
            <input
              type="text"
              id="userSource"
              onBlur={(e) => setSource(e.target.value)}
              onChange={() => setSourceIsBlank(false)}
              className="field checkout-form-input"
              style={{ maxWidth: 300, marginBottom: 0 }}
            />
            <button
              className="btn btn-accent"
              disabled={sourceIsBlank}
              style={{ padding: "10px 10px", height: 44 }}
              onClick={() => setSourceSubmit(true)}
            >
              Send
            </button>
          </div>
        )}
        <br />
      </div>
    </div>
  );
};

export default SourceFormContainer;
