import { Link } from "react-router-dom";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";

import ProductTestimonials from "../../components/product-testimonials/ProductTestimonials";

import testimonials from "../../data/testimonials";

const PRODUCT = "CAP Bundle";

export default function CAPBundle() {
  return <MultiPriceProductPage PRODUCT={PRODUCT} description={description} rest={rest()} />;
}

const description = (
  <div className="gray-box" id="product-description">
    <h4>Description</h4>
    <div className="blog">
      <p>
        Prepare for the{" "}
        <Link to="/resources/certified-analytics-professional" target="_blank" rel="noreferrer">
          Certified Analytics Professional (CAP) Exam
        </Link>{" "}
        with a comprehensive{" "}
        <Link to="/products/cap-study-plan" target="_blank">
          65-day email course
        </Link>{" "}
        that guides your reading of the{" "}
        <a href="https://www.certifiedanalytics.org/exam-resources" target="_blank" rel="noreferrer">
          CAP Study Guide
        </a>{" "}
        as well as{" "}
        <Link to="/products/cap-question-sets" target="_blank">
          100 practice questions
        </Link>{" "}
        to challenge your knowledge of Data Analytics.
      </p>
      <p>
        The{" "}
        <Link to="/products/cap-study-plan" target="_blank">
          CAP Study Plan
        </Link>{" "}
        provides a <strong>step-by-step</strong> study schedule, <strong>learning objective summaries</strong>,{" "}
        <strong>explanations of challenging concepts</strong>, and extra resources in the form of{" "}
        <strong>videos and articles</strong> to help you remember each vocabulary word.
      </p>
      <p>
        You will also receive both{" "}
        <Link to="/products/cap-question-sets" target="_blank">
          CAP Question Sets
        </Link>{" "}
        which each contain 50 distinct, never-before-seen questions, developed by our team at Data Strategy
        Professionals. In the answers section of each document, we have identified the CAP domain and provided a
        detailed explanation for each question.
      </p>
    </div>
  </div>
);

const rest = () => {
  const testimonialsToShow = [];

  testimonialsToShow.push(
    testimonials.find((testimonial) => testimonial.id === "cap-study-plan-000"),
    testimonials.find((testimonial) => testimonial.id === "cap-study-plan-001"),
    testimonials.find((testimonial) => testimonial.id === "cap-exam-questions-001")
    // testimonials.find((testimonial) => testimonial.id === "cap-bundle-000")
  );
  return <ProductTestimonials testimonialsToShow={testimonialsToShow} />;
};
