import { Link } from "react-router-dom";

import Post from "../../components/blog/Post";
import PostImage from "../../components/blog/PostImage";
import AIGFCDownloads from "./components/AIGFCDownloads";
import AIGFCPostSignup from "./components/AIGFCPostSignup";
import AIGovernanceTable from "./components/AIGovernaceTable";

import aiGovernance from "../../img/blog/aigfc/ai-governance.webp";
import aiFrameworkTypes from "../../img/blog/aigfc/ai-framework-types.webp";
import aiga from "../../img/blog/aigfc/aiga.webp";
import wheel from "../../img/blog/aigfc/wheel.webp";

import ExtendedDescription from "../../components/ExtendedDescription";
import EndnoteSource from "../../components/blog/EndnoteSource";

import aigfcSources from "../../data/blog/aigfcSources";

const POSTNAME: postNickname = "aigfc-governance";

const AIGFCGovernance: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default AIGFCGovernance;

const Rest = () => {
  const contents = [
    { anchor: "introduction", text: "Introduction to AI Governance" },
    { anchor: "large-businesses", text: "Priority for Large Businesses" },
    { anchor: "types-of-ai-governance", text: "Types of AI Governance" },
    { anchor: "roles-and-responsibilities", text: "Roles and Responsibilities" },
    { anchor: "tasks-in-practice", text: "Tasks in Practice" },
    { anchor: "aiga-model-case-study", text: "AIGA Model Case Study" },
    { anchor: "ceos", text: "CEOs" },
    { anchor: "data-management-and-data-governance", text: "Data Management and Data Governance" },
    { anchor: "data-quality-and-data-security", text: "Data Quality and Data Security" },
    { anchor: "oversight-groups", text: "Oversight Groups" },
    { anchor: "business-benefits", text: "Business Benefits" },
    {
      anchor: "business-incentives",
      text: "Incentives and Responsibility are Aligned",
    },
    { anchor: "increased-roi", text: "Increased ROI" },
    { anchor: "building-trust", text: "Building Trust" },
    { anchor: "regulatory-compliance", text: "Regulatory Compliance" },
    { anchor: "adoption-rates-and-barriers", text: "Adoption Rates and Barriers" },
    { anchor: "rates-of-business-adoption", text: "Rates of Business Adoption" },
    { anchor: "deployment-and-operation-barriers", text: "Deployment and Operation Barriers" },
    { anchor: "planning-and-resource-barriers", text: "Planning and Resource Barriers" },
    { anchor: "discussion", text: "Discussion" },
    { anchor: "conclusion", text: "Conclusion" },
    { anchor: "related-posts", text: "Related Posts" },
  ];

  return (
    <div className="blog">
      <p>
        Drawing from the definitions of various organizations, AI Governance refers to the set of policies and
        mechanisms that help ensure AI systems remain ethical and safe throughout the AI lifecycle. AI Governance is a
        somewhat broad term encompassing the development of AI principles and their practical implementation with the
        goal both increasing benefits and decreasing risks related to AI systems.<sup>1, 2, 3, 4, 5</sup>
      </p>
      <PostImage postName={POSTNAME} />
      <p>
        Gartner highlights that AI Governance is "currently characterized by an absence of agreed-upon standards or
        regulations."<sup>7</sup> The Responsible AI Institute{" "}
        <a href="https://www.responsible.ai/navigating-organizational-ai-governance/" target="_blank" rel="noreferrer">
          describes
        </a>{" "}
        AI Governance as "an emergent but rapidly evolving field."<sup>8</sup> Significant technical AI developments and
        the proliferation of AI applications over the last 10 years have rapidly developed the field of AI Governance.
        This is especially true of the past two years given the emergence of generative AI (gen AI).
      </p>

      <AIGFCDownloads />

      <h4>Contents</h4>
      <ul>
        <li>
          <a href={"#" + contents[0].anchor}>{contents[0].text}</a>
          {contents.slice(1, 3).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>
        <li>
          <a href={"#" + contents[3].anchor}>{contents[3].text}</a>
          {contents.slice(4, 10).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>
        <li>
          <a href={"#" + contents[10].anchor}>{contents[10].text}</a>
          {contents.slice(11, 15).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>
        <li>
          <a href={"#" + contents[15].anchor}>{contents[15].text}</a>
          {contents.slice(16, 20).map((content) => (
            <li>
              <a href={"#" + content.anchor}>{content.text}</a>
            </li>
          ))}
        </li>
        <li>
          <a href={"#" + contents[20].anchor}>{contents[20].text}</a>
        </li>
      </ul>
      <h2 id={contents[0].anchor}>{contents[0].text}</h2>
      <h4 id={contents[1].anchor}>{contents[1].text}</h4>
      <p>
        Many businesses will understandably hesitate to implement AI Governance. Keeping track of best practices can be
        challenging; it impacts various parts of a business, and implementing and operating requires new expertise. It
        could be tempting to start with "informal governance" – using existing governance systems – with a plan to
        implement ad hoc systems as specific risks arise.<sup>1</sup>
      </p>
      <p>
        However, in addition to encouragement{" "}
        <a
          href="https://futurium.ec.europa.eu/en/european-ai-alliance/best-practices/implementing-ai-governance-framework-practice"
          target="_blank"
          rel="noreferrer"
        >
          from governments
        </a>
        , implementing comprehensive, purpose-built organizational AI Governance frameworks as part of the AI adoption
        process has wide support among large businesses.<sup>5</sup>{" "}
        <a
          href="https://www.spglobal.com/en/research-insights/special-reports/the-ai-governance-challenge"
          target="_blank"
          rel="noreferrer"
        >
          S&P Global
        </a>{" "}
        argues for "robust, flexible, and adaptable governance frameworks."<sup>11</sup> At the same time,{" "}
        <a href="https://www.bcg.com/publications/2023/a-guide-to-mitigating-ai-risks" target="_blank" rel="noreferrer">
          BCG
        </a>{" "}
        claims that robust AI Governance is how "organizations [can] ensure that they can deliver the benefits of AI
        while mitigating the risks."<sup>12</sup>
      </p>
      <p>
        The AI Governance frameworks produced by the three largest cloud vendors similarly emphasizes the importance of
        AI Governance:
      </p>
      <ul>
        <li>
          <a
            href="https://cdn-dynmedia-1.microsoft.com/is/content/microsoftcorp/microsoft/final/en-us/microsoft-brand/documents/Microsoft-Responsible-AI-Standard-General-Requirements.pdf?culture=en-us&country=us"
            target="_blank"
            rel="noreferrer"
          >
            Microsoft's Responsible AI Standard
          </a>
          : recognizing that AI Governance lags behind technological risks, Microsoft developed its framework to guide
          product teams; they advocate for "principled and actionable AI Governance norms" through cross-sector
          collaboration<sup>13</sup>
        </li>
        <li>
          <a
            href="https://docs.aws.amazon.com/pdfs/whitepapers/latest/aws-caf-for-ai/aws-caf-for-ai.pdf"
            target="_blank"
            rel="noreferrer"
          >
            AWS' Cloud Adoption Framework for Artificial Intelligence, Machine Learning, and Generative AI
          </a>
          : "AI Governance... is instrumental in building trust, enabling the deployment of AI technologies at scale,
          and overcoming challenges to drive business transformation and growth;" AWS emphasizes that AI Governance
          frameworks create consistent practices for managing risks, ethics, data quality, and regulatory compliance
          <sup>14</sup>
        </li>
        <li>
          <a href="https://safety.google/intl/en_us/cybersecurity-advancements/saif/" target="_blank" rel="noreferrer">
            Google's Secure AI Framework
          </a>
          : Google has established AI Governance policies covering responsible principles, safety, and security; their
          framework guides organizations in implementing "security standards for building and deploying AI responsibly"
          <sup>15</sup>
        </li>
      </ul>
      <p>
        All businesses that have, are currently using, or are considering adopting AI would benefit from learning more
        about AI Governance frameworks and how to implement them to bolster risk mitigation and value creation efforts
        related to AI.
      </p>
      <h4 id={contents[2].anchor}>{contents[2].text}</h4>
      <p>
        Multiple classification systems currently exist to categorize different types of AI Governance. We've found the
        taxonomies proposed in the separate collaborations of{" "}
        <a href="https://www.responsible.ai/navigating-organizational-ai-governance/" target="_blank" rel="noreferrer">
          BCG and RAII
        </a>{" "}
        (Figure 3.1) and{" "}
        <a
          href="https://iapp.org/media/pdf/resource_center/ai_governance_in_practice_report_2024.pdf"
          target="_blank"
          rel="noreferrer"
        >
          IAPP and FTI Consulting
        </a>{" "}
        (Figure 3.2) to be the most useful descriptions of the current AI Governance field.<sup>2, 16</sup> These
        taxonomies roughly converge on five primary types of AI Governance, complete with specific examples.
      </p>

      <figure>
        <img src={aiGovernance} alt="Types of AI Governance" />

        <figcaption>
          Figure 3.1 – Types of AI Governance from BCG and RAII's{" "}
          <a
            href="https://www.responsible.ai/navigating-organizational-ai-governance/"
            target="_blank"
            rel="noreferrer"
          >
            Navigating Organizational AI Governance
          </a>
        </figcaption>
      </figure>

      <figure>
        <img src={aiFrameworkTypes} alt="Types of AI Governance" />

        <figcaption>
          Figure 3.2 – Types of AI Governance from IAPP and FTI Consulting's{" "}
          <a
            href="https://iapp.org/media/pdf/resource_center/ai_governance_in_practice_report_2024.pdf"
            target="_blank"
            rel="noreferrer"
          >
            AI Governance in Practice
          </a>
        </figcaption>
      </figure>

      <p>
        AI principles, laws and regulations, and frameworks are likely the types of AI Governance most relevant to
        businesses. AI principles are necessary for businesses to define their ethics and safety-focused objectives for
        AI. See what some large businesses{" "}
        <a
          href="https://www.industry.gov.au/publications/australias-artificial-intelligence-ethics-framework/testing-ai-ethics-principles"
          target="_blank"
          rel="noreferrer"
        >
          experienced in a pilot test
        </a>{" "}
        of Australia's "AI Ethics Principles."<sup>17</sup> Understanding AI laws and regulations is necessary for
        businesses to maintain legal compliance. Convergence Analysis's "
        <a
          href="https://drive.google.com/file/d/13gyYbBixU75QwFQDTku0AMIovbeTp9_g/view"
          target="_blank"
          rel="noreferrer"
        >
          2024 State of the AI Regulatory Landscape
        </a>
        " offers a valuable, up-to-date overview.<sup>18</sup>
      </p>
      <p>
        We define an AI Governance framework as a document outlining formal policies and procedures for practically
        implementing AI principles and maintaining regulatory compliance. Businesses interested in improving AI
        Governance by learning from or integrating existing AI Governance frameworks will benefit from reviewing the
        list of AI Governance framework examples in Figure 3.2 as a starting point.
      </p>
      <h2 id={contents[3].anchor}>{contents[3].text}</h2>
      <h4 id={contents[4].anchor}>{contents[4].text}</h4>
      <p>
        An AI Governance framework requires that a business implements objective-aligned policies and procedures for
        governing AI across the AI lifecycle. Businesses can ideally develop policies and procedures explicitly for AI
        Governance, but Singapore AI{" "}
        <a
          href="https://www.pdpc.gov.sg/-/media/Files/PDPC/PDF-Files/Resource-for-Organisation/AI/SGModelAIGovFramework2.pdf"
          target="_blank"
          rel="noreferrer"
        >
          proposes
        </a>{" "}
        that they can also adapt existing governance structures to accommodate AI.<sup>19</sup> For example, AI risk
        mitigation processes can be developed within a business's existing risk management structure.
      </p>
      <p>
        While an AI's specific features and intended use cases will influence what governance mechanisms a business
        employs to govern it, there are many broad mechanisms that businesses are likely to practice along the AI
        lifecycle. Singapore AI outlines risk control, maintenance, monitoring, documentation, review measures, and
        reviews of how stakeholders communicate as common types of operational tasks.
      </p>
      <p>
        Although before the proliferation of gen AI,{" "}
        <a href="https://www.ibm.com/downloads/cas/KXVRM5QE" target="_blank" rel="noreferrer">
          survey results
        </a>{" "}
        from IDC in 2022 offer more granular insight into what AI Governance capabilities businesses think are critical.
        Dashboards to assess, monitor, and drive timely actions/risk management (61.7%), multi-persona collaboration
        (60%), and support governance for third-party models and applications (60%) were the three most commonly cited
        critical capabilities. However, of the nine capabilities surveyed, reports for compliance (43.1%) were the
        lowest, indicating that many businesses consider a wide range of capabilities critical.<sup>4</sup>
      </p>
      <p>
        Additionally, a 2023{" "}
        <a
          href="https://iapp.org/media/pdf/resource_center/iapp_ey_professionalizing_organizational_ai_governance_report.pdf"
          target="_blank"
          rel="noreferrer"
        >
          research
        </a>{" "}
        from IAPP-EY highlights which business functions are currently being or expected to be tasked with AI Governance
        responsibilities. Among those functions with primary responsibility, information technology (19%), privacy
        (16%), legal and compliance (14%), and data governance (11%) were the most commonly involved. Meanwhile, the
        proportion of business functions with secondary responsibility for AI Governance is far higher. Privacy (57%),
        legal and compliance (55%), security (50%), and information technology (46%) were the functions that at least
        35% of respondents indicated involvement with AI Governance.<sup>20</sup>
      </p>
      <p>
        Taking a bigger picture view, the OECD's "
        <a href="https://www.oecd-ilibrary.org/docserver/008232ec-en.pdf" target="_blank" rel="noreferrer">
          Tools for Trustworthy AI
        </a>
        " report provides a taxonomy of the types of tools involved in implementing AI principles. Here, tools are
        defined as "instruments and structured methods that can be leveraged by others to facilitate their
        implementation of the AI Principles." These give a broader context for what deployment requires in addition to
        the operational system.<sup>21</sup>
      </p>
      <h4 id={contents[5].anchor}>{contents[5].text}</h4>

      <p>
        In addition to understanding the content of AI Governance frameworks as documents, it's important to understand
        how this framework operates as a comprehensive system. As shown in Figure 3.3, the AI Governance and Auditing
        (AIGA) consortium, coordinated by the University of Turku, provides a{" "}
        <a
          href="https://ai-governance.eu/ai-governance-framework/the-ai-governance-lifecycle/"
          target="_blank"
          rel="noreferrer"
        >
          model
        </a>{" "}
        for how an AI Governance system that{" "}
        <a
          href="https://futurium.ec.europa.eu/en/european-ai-alliance/best-practices/implementing-ai-governance-framework-practice"
          target="_blank"
          rel="noreferrer"
        >
          complies
        </a>{" "}
        with the EU AI Act could operate.<sup>5, 22</sup> Although this model primarily caters to AI developers, it
        could help businesses understand what their AI Governance could involve during deployment and operation.
      </p>

      <figure>
        <img src={aiga} alt="AIGA Model" />

        <figcaption>
          Figure 3.3 – AI Governance Lifecycle from the{" "}
          <a
            href="https://ai-governance.eu/ai-governance-framework/the-ai-governance-lifecycle/"
            target="_blank"
            rel="noreferrer"
          >
            AIGA Consortium
          </a>
        </figcaption>
      </figure>

      <p>
        The AIGA model outlines individual governance tasks at the intersection of different task categories, e.g., AI
        system, and phases along the AI lifecycle, e.g., planning and design. We are particularly interested in the
        tasks during the deployment and operation phases, especially those related to data operations and risk and
        impacts. Although this model doesn't outline any tasks for the deployment phase, many tasks occur during the
        operation and monitoring phase.
      </p>
      <p>
        For data operations, data quality monitoring (task 38) and data health checks (task 39) occur during operation
        and monitoring. Data quality monitoring involves the AI system owner ensuring the implementation of planned data
        quality processes, such as disclosing standard breaches and data drift, before implementing measures to address
        problems. Meanwhile, the algorithm owner performs data health checks, assessing how the AI system data resources
        and data-related processes align with the organization's values and risk tolerance and taking appropriate
        measures to address any issues identified.
      </p>
      <p>
        The diversity of tasks that AI Governance frameworks involve means that whole-organization coordination between
        roles is necessary for the framework to function reliably. We focus on evidence for the responsibilities and
        impact of key roles. These roles include CEOs who set targets and communicate cultural norms, data practitioners
        from policy-setting to practical implementation, and the intersecting groups that can provide practical
        oversight for AI programs.
      </p>

      <h4 id={contents[6].anchor}>{contents[6].text}</h4>
      <p>
        Reliable AI adoption requires that AI Governance be aligned with a business's objective and implemented
        throughout different business functions along the AI lifecycle. BCG and RAII{" "}
        <a href="https://www.responsible.ai/navigating-organizational-ai-governance/" target="_blank" rel="noreferrer">
          determine
        </a>{" "}
        "that responsibility for AI Governance should ultimately sit with CEOs since AI Governance includes 'issues
        related to customers' trust in the company's use of the technology, experimentation with AI within the
        organization, stakeholder interest, and regulatory risks.'"<sup>2</sup>{" "}
        <a href="https://nvlpubs.nist.gov/nistpubs/ai/NIST.AI.100-1.pdf" target="_blank" rel="noreferrer">
          NIST
        </a>
        ,{" "}
        <a href="https://www.ibm.com/topics/ai-governance" target="_blank" rel="noreferrer">
          IBM
        </a>
        ,{" "}
        <a href="https://www.ibm.com/downloads/cas/KXVRM5QE" target="_blank" rel="noreferrer">
          IDC
        </a>
        , and{" "}
        <a
          href="https://tdwi.org/Research/2024/07/ADV-ALL-2024-State-of-AI-Readiness/Asset.aspx?tc=assetpg"
          target="_blank"
          rel="noreferrer"
        >
          TDWI
        </a>{" "}
        echo this basic sentiment.<sup>1, 4, 23, 25</sup>
      </p>
      <p>
        Executives, more than other roles, look to the CEO for guidance. In their{" "}
        <a href="https://www.ibm.com/downloads/cas/6JAM3VB7" target="_blank" rel="noreferrer">
          guide
        </a>{" "}
        to gen AI for CEOs, survey results from IBM show that roughly 3 times as many executives look to CEOs for
        guidance on AI ethics than various other roles, from Board of Directors to Risk and Compliance Officers.
        <sup>1</sup> Furthermore, 80% think business leaders should be primarily accountable for AI ethics, not
        technology leaders. IDC{" "}
        <a href="https://www.ibm.com/downloads/cas/KXVRM5QE" target="_blank" rel="noreferrer">
          notes
        </a>{" "}
        that regulators, too, are looking to C-level executives to ensure regulatory compliance.<sup>4</sup>
      </p>
      <p>
        CEOs are deeply involved in implementing AI Governance. Referring to the OECD's "
        <a href="https://www.oecd-ilibrary.org/docserver/008232ec-en.pdf" target="_blank" rel="noreferrer">
          Tools for Trustworthy AI
        </a>
        ," we find a range of procedural and educational tasks involving the CEO.<sup>21</sup> These include setting the
        tone for the risk management culture, developing open communication channels, dictating lines of authority,
        rolling out training, and investing resources.
      </p>
      <p>
        Furthermore, this support might need to be sustained for years. BCG{" "}
        <a href="https://www.bcg.com/publications/2023/a-guide-to-mitigating-ai-risks" target="_blank" rel="noreferrer">
          estimates
        </a>{" "}
        that businesses take an average of three years to reach high levels of responsible AI maturity.<sup>12</sup> The
        EU strongly{" "}
        <a
          href="https://futurium.ec.europa.eu/en/european-ai-alliance/best-practices/implementing-ai-governance-framework-practice"
          target="_blank"
          rel="noreferrer"
        >
          recommends
        </a>{" "}
        appointing an AI Governance lead to oversee a framework's implementation and maintenance to help maintain
        progress over time.<sup>5</sup>
      </p>
      <p>
        Hands-on CEOs seem to substantially impact AI Governance implementation. A BCG and MIT Sloan Management Review{" "}
        <a
          href="https://www.bcg.com/publications/2023/ai-responsibility-at-crossroads"
          target="_blank"
          rel="noreferrer"
        >
          2023 survey
        </a>{" "}
        found that of the 1,240 respondents, only 28% of CEOs were involved in responsible AI programs. Those businesses
        with an involved CEO saw 58% more business benefits on average, such as accelerated innovation and long-term
        profitability from their program, than those without a hands-on CEO.<sup>26</sup>
      </p>
      <h4 id={contents[7].anchor}>{contents[7].text}</h4>

      <p>
        IAPP and FTI{" "}
        <a
          href="https://iapp.org/media/pdf/resource_center/ai_governance_in_practice_report_2024.pdf"
          target="_blank"
          rel="noreferrer"
        >
          refer to
        </a>{" "}
        Data Management as "a foundational element to managing all AI systems."<sup>16</sup> At the same time, AWS{" "}
        <a
          href="https://docs.aws.amazon.com/pdfs/whitepapers/latest/aws-caf-for-ai/aws-caf-for-ai.pdf#governance-perspective-managing-an-aiml-driven-organization"
          target="_blank"
          rel="noreferrer"
        >
          asserts
        </a>{" "}
        that "the value of [AI] systems is driven largely by the data that makes it more effective."<sup>14</sup>{" "}
        Indeed, Data Management issues are among the most common{" "}
        <Link to="/resources/aigfc/adoption#barriers" target="_blank">
          barriers to AI adoption
        </Link>
        , such as the need for Data Strategy to handle high volumes of data. Although this report focuses on AI
        Governance during the deployment and operation lifecycle phases, data is also critical during an AI's training,
        making up one part of the "
        <a
          href="https://cset.georgetown.edu/publication/the-ai-triad-and-what-it-means-for-national-security-strategy/"
          target="_blank"
          rel="noreferrer"
        >
          AI triad
        </a>
        ."<sup>49</sup>
      </p>
      <p>
        IAPP and FTI propose that Data Management considerations "include understanding what data is being used in which
        system; how it is collected, retained, and disposed of; if there is lawful consent to use the data; and who is
        responsible for ensuring the appropriate oversight." Classifying the Data Management practices using DAMA's{" "}
        <em>
          <a href="http://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            DMBOK
          </a>
        </em>
        , as depicted in Figure 3.4, our research indicates that the strongest relationships between AI Governance and
        Data Management are in data governance, data quality, and data security (especially data privacy).<sup>50</sup>
      </p>

      <figure>
        <img src={wheel} alt="Types of AI Governance" />

        <figcaption>
          Figure 3.4 – Data Management Wheel from{" "}
          <a href="http://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            DAMA
          </a>
        </figcaption>
      </figure>

      <p>
        The lifecycles of AI and data are deeply intertwined, and thus, so is the governance of each. Stanford's "
        <a
          href="https://aiindex.stanford.edu/wp-content/uploads/2024/05/HAI_AI-Index-Report-2024.pdf"
          target="_blank"
          rel="noreferrer"
        >
          AI Index Report 2024
        </a>
        " emphasizes that "data governance is crucial for ensuring that the data used for training and operating AI
        systems is accurate, fair, and used responsibly and with consent."<sup>44</sup>
      </p>
      <p>
        Data Governance expert{" "}
        <a
          href="https://www.linkedin.com/pulse/ai-governance-vs-data-robert-s-seiner-duiqc/"
          target="_blank"
          rel="noreferrer"
        >
          Bob Seiner
        </a>{" "}
        refers to the synthesis of Data Governance and Data Governance as AI Data Governance, where Data Governance is a
        subset of AI Governance. Key aspects include managing data access and securing data against breaches.
        Organizations enact policies and procedures for data annotation, collection, and use to meet the operational
        needs of AI systems responsibly. Without robust AI Data Governance, Seiner highlights that AI risks operating
        with "flawed, biased, or insecure data, leading to poor and potentially harmful decisions."<sup>27</sup>
      </p>
      <p>
        TDWI's "
        <a
          href="https://tdwi.org/Research/2024/07/ADV-ALL-2024-State-of-AI-Readiness/Asset.aspx"
          target="_blank"
          rel="noreferrer"
        >
          The State of AI Readiness 2024
        </a>
        " survey results offer insight into the demand for data practitioner roles in connection with AI Governance and
        some of the challenges businesses are experiencing related to AI Data Governance. Many organizations seem
        especially interested in hiring data engineers and upskilling business analysts to work increasingly as data
        scientists who also understand business.<sup>24</sup>
      </p>
      <p>
        Lacking strong data practices has already made adopting AI challenging. TDWI found that only 20% of respondents
        reported a solid Data Governance program. In comparison, only 40% understand their data sources and have
        policies to govern different kinds of data.<sup>24</sup> These results are consistent with McKinsey's{" "}
        <a
          href="https://www.mckinsey.com/capabilities/quantumblack/our-insights/the-state-of-ai"
          target="_blank"
          rel="noreferrer"
        >
          finding
        </a>{" "}
        that 70% of respondents struggled to define processes for Data Governance and integrating data into AI models.
        <sup>28</sup>
      </p>
      <p>
        Meanwhile, less than 30% agreed they have "a company-wide Data Architecture in place for AI that can handle user
        growth." Businesses also need to learn how to store and govern new, often unstructured data types that AI often
        involves, such as PDFs and audio files.<sup>24</sup>
      </p>
      <h4 id={contents[8].anchor}>{contents[8].text}</h4>
      <p>
        IAPP-FTI's "
        <a href="https://iapp.org/media/pdf/resource_center/ai_governance_in_practice_report_2024.pdf">
          AI Governance in Practice Report 2024
        </a>
        " details AI Governance's connection to data quality and Data Security. Even during the deployment and operation
        phases of the AI lifecycle, data quality "impacts the quality of the outputs and performance," making it
        essential to creating a safe and responsible AI system. Policies and procedures should systematically measure
        data's accuracy, completeness, validity, and consistency across the AI lifecycle. These processes mitigate risks
        related to transparency and help AI perform the specific business functions assigned to it.<sup>16</sup>
      </p>
      <p>
        Businesses can use data labels as a tool in data quality assessment and review processes. Documenting and
        creating inventories for data sources can support understanding where data is acquired and help carry out legal
        due diligence. If, for example, a business uses third-party data, it must comply with the terms of service in
        any data-sharing agreement and attribute the origin of the data its AI uses.<sup>16</sup> Data operations tasks{" "}
        <Link to={"#" + contents[5].anchor}>previously highlighted</Link> from the AIGA model also demonstrate the role
        of data quality in AI Governance.<sup>22</sup>
      </p>
      <p>
        Data Security's relationship to AI Governance spans data protection and Data Privacy. With AI models vulnerable
        to hacking, data poisoning, and adversarial attacks, guarding the AI system's components is vital to maintaining
        Data Security. Failure to do so risks outcomes such as the "manipulation of outputs, stolen sensitive
        information, or interference with system operations." These, in turn, could lead to substantial financial
        losses, reputational damage to a business, widespread harm to others such as stolen customer funds, and physical
        safety concerns.<sup>16</sup>
      </p>
      <p>
        Data quality is intimately connected to Data Security in AI Governance. For example, data poisoning occurs when
        malicious actors change the labels for data being used by an AI or inject their data into the dataset.
        <sup>16</sup> Backdoors could also be created for future system access. NIST's "AI Risk Management Framework (
        <a href="https://nvlpubs.nist.gov/nistpubs/ai/NIST.AI.100-1.pdf" target="_blank" rel="noreferrer">
          AI RMF
        </a>
        )" defines AI systems as being secure when they can "maintain confidentiality, integrity, and availability
        through protection mechanisms that prevent unauthorized access."<sup>23</sup>
      </p>
      <p>
        Identifying Data Security risks is especially important when using third-party AI systems. IAPP and FTI outline
        several different contract terms that could be important to implement. These include maximizing the
        compatibility of a vendor's security practices with a business's practices, performing regular security
        assessments or audits to identify risks and ensure vendor compliance, and limiting vendor access to only the
        systems they need.<sup>16</sup>
      </p>
      <p>
        In addition to addressing other risks from poor data quality, data inventories can be designed into an AI
        Governance system that handles personal data to help keep businesses accountable for Data Privacy. Privacy
        metadata could be used to develop a single source of truth to support regulatory compliance and, if permitted,
        be used as high-quality data for an AI to make inferences about a population. AI-specific inventories might also
        be developed.
      </p>
      <p>
        Privacy leaders often already have a direct line to CEOs and the Board of Directors. Existing Data Privacy
        governance systems can be adapted to accommodate policies and procedures related to AI systems interacting with
        personal data. In previous research, IAPP found that 73% of their members implemented privacy-focused AI
        Governance policies and procedures using their existing privacy systems.<sup>16</sup>
      </p>
      <h4 id={contents[9].anchor}>{contents[9].text}</h4>
      <p>
        While CEOs provide primary leadership and data practitioners make up a large proportion of workers focused on
        practical implementation, various groups might be involved in practically overseeing AI Governance processes.
        Gartner{" "}
        <a href="https://www.gartner.com/doc/reprints?id=1-2F2UJPZR&ct=230922&st=sb" target="_blank" rel="noreferrer">
          proposes
        </a>{" "}
        that many existing review boards, privacy teams, or legal departments across businesses are overwhelmed with
        AI-related ethical concerns and then take ad hoc actions to address them.<sup>7</sup>
      </p>
      <p>
        Establishing dedicated oversight groups can more reliably accommodate rising AI incidents. BCG{" "}
        <a href="https://www.bcg.com/publications/2023/a-guide-to-mitigating-ai-risks" target="_blank" rel="noreferrer">
          argues
        </a>{" "}
        that a committee of senior leaders should oversee responsible AI program development and implementation,
        including establishing linkages to existing corporate governance structures, such as a risk committee. For
        example, Mastercard's Governance Council reviews AI applications deemed high risk, with various data
        practitioners involved, from Data Science teams to a Chief Information Security Officer, Chief Data Officer, and
        Chief Privacy Officer.<sup>12</sup>
      </p>
      <p>
        In practice, Gartner's{" "}
        <a href="https://www.gartner.com/doc/reprints?id=1-2F2UJPZR&ct=230922&st=sb" target="_blank" rel="noreferrer">
          case study
        </a>{" "}
        on IBM offers examples of what groups might exist and how their responsibilities could intersect. IBM's overall
        governance strategy follows a technology ethics framework. This case study explicitly outlines IBM's
        operationalized use-case review process for AI ethics and identifies each group involved.<sup>7</sup>
      </p>
      <p>
        The review process begins with a distributed Advocacy Network. It consists of employees across business units
        who generally promote and scale a responsible AI culture. Next, each business unit includes roles called Focal
        Points, which proactively identify concerns, support framework implementation and regulatory compliance, and
        communicate between the AI Ethics Board and each business unit.
      </p>
      <p>
        At the third level is the AI Ethics Board, co-chaired by the global AI ethics leader from IBM Research and the
        chief privacy and trust officer. It consists of a cross-functional and centralized body of representatives and
        is responsible for defining and maintaining policies, advising on AI ethics questions, and providing guidance
        through guardrails and education courses.
      </p>
      <p>
        The Policy Advisory Committee, composed of senior leaders, exists at the top. It oversees the AI Ethics Board,
        reviews the impacts of various issues throughout the business, and acts as the final escalation point for
        concerns as needed. The Chief Privacy Officer (CPO) Ethics Project Office supports each group. It acts as the
        formal liaison between each level, manages workstreams defined by the AI Ethics Board, and supports the use-case
        review process.
      </p>
      <p>
        The scale of IBM's governance structure reflects its scale as a business. For many businesses, the
        responsibilities performed at each level could likely be condensed into fewer levels and roles as needed, while
        some may not be necessary at all. Ultimately, leadership should establish the purpose and role of their AI
        Governance framework to determine which roles and responsibilities are essential to their business.
      </p>
      <h2 id={contents[10].anchor}>{contents[10].text}</h2>
      <h4 id={contents[11].anchor}>{contents[11].text}</h4>

      <p>
        The World Economic Forum's 2024 "
        <a
          href="https://www3.weforum.org/docs/WEF_Responsible_AI_Playbook_for_Investors_2024.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Responsible AI Playbook for Investors
        </a>
        " highlights natural tensions between business incentives and the aims of responsible AI. For example, whereas a
        business may want to bring a product to market rapidly, implementing AI risk mitigation policies and procedures
        may slow product development using AI. However, business incentives may align more with responsible AI than many
        businesses realize.<sup>29</sup>
      </p>
      <p>
        As a starting point, AI Governance addresses the most prevalent barriers to AI adoption. Policies and procedures
        help businesses mitigate AI risks better, manage data along the AI lifecycle, and integrate new technical and
        logistical systems during AI adoption. As outlined above, many CEO and data practitioner AI Governance
        responsibilities directly address such problems.<sup>29</sup>
      </p>
      <p>
        Implementing AI Governance further benefits businesses during the operation phase of the AI lifecycle. A{" "}
        <a href="https://arxiv.org/pdf/2309.13057" target="_blank" rel="noreferrer">
          paper
        </a>{" "}
        from IBM and the University of Notre Dame proposes a Holistic Return on Ethics (HROE) framework for
        understanding the breadth of ROI that AI ethics affords a business. These include direct economic to intangible
        (reputational) and real option (capability) improvements. We use these types of benefits to roughly categorize
        the benefits identified across sources.
      </p>
      <p>
        Direct economic benefits largely come from AI Governance, which optimizes how efficiently a business uses AI
        systems. For example,{" "}
        <a href="https://www.ibm.com/downloads/cas/KXVRM5QE" target="_blank" rel="noreferrer">
          AI Governance platforms
        </a>{" "}
        are designed to succinctly bring stakeholders together along the AI lifecycle to employ best practices that
        maximize AI's benefits and prevent risks.<sup>4</sup>
      </p>
      <p>
        Meanwhile, AI Governance's risk mitigation capabilities strongly contribute to producing intangible benefits for
        a business.<sup>30</sup> We previously explored the impact and prevalence of harms from AI on society, such as
        biased facial recognition systems, and on businesses, such as data breaches. But these are only the first-order
        impacts. In addition to avoiding the potential direct harm done by AI, mitigating AI risks can also build trust
        between a business and various target groups and ensure regulatory compliance.
      </p>
      <p>
        Former Eli Lilly CDAO Vipin Gopal{" "}
        <a
          href="https://web-assets.bcg.com/37/87/33f2ee9d4e2281e792472f4ec1bf/to-be-a-responsible-ai-leader-focus-on-being-responsible.pdf"
          target="_blank"
          rel="noreferrer"
        >
          frames
        </a>{" "}
        the alternative to adopting AI Governance as choosing to power innovation using an AI system that is more prone
        to producing biased and unfair outcomes, having its data breached, or becoming unexplainable.<sup>31</sup>
      </p>

      <h4 id={contents[12].anchor}>{contents[12].text}</h4>
      <p>
        Although many businesses may be concerned about AI Governance having an overall negative impact on their costs
        and efficiency, evidence shows that AI Governance can increase ROI. As researchers from IBM and the University
        of Notre Dame <a href="https://arxiv.org/pdf/2309.13057">argue</a>, this ROI comes in various forms,
        specifically economic, intangible (reputational), and real options (capabilities).<sup>30</sup>
      </p>
      <p>
        Direct economic ROI is among the key factors businesses will consider AI Governance's effect on. Gartner's{" "}
        <a href="https://www.gartner.com/peer-community/oneminuteinsights/ai-governance-frameworks-responsible-ai-33q">
          March 2023 survey
        </a>{" "}
        – conducted at the start of gen AI's proliferation – found various positive financial-related impacts attributed
        to a business's AI Governance framework. Forty-eight percent experienced more successful AI initiatives, 46%
        improved customer experience, 46% increased revenue, 30% decreased costs, and more.<sup>32</sup>
      </p>
      <p>
        Gartner further asked respondents whose organizations lacked AI Governance about the negative impacts of AI that
        they attribute to lacking AI Governance. Forty-five percent of respondents could specify at least one negative
        effect, with the top three impacts being increased costs (47%), failed AI initiatives (36%), and decreased
        revenue (34%).<sup>32</sup>
      </p>
      <p>
        MIT Sloan and BCG's{" "}
        <a href="https://web-assets.bcg.com/37/87/33f2ee9d4e2281e792472f4ec1bf/to-be-a-responsible-ai-leader-focus-on-being-responsible.pdf">
          2022 research
        </a>{" "}
        provides more granular insight into the direct ROI of AI Governance prior to gen AI. Specifically, they
        investigated the business benefits from responsible AI programs between responsible AI leaders and non-leaders.
        A business's responsible AI leadership level was determined by analyzing survey data about the breadth of their
        programs, implementation of best practices, and breadth of "considerations" they perceive as being a part of
        responsible AI.
      </p>
      <p>
        The report found that responsible AI leaders are far morelikely to attain various business benefits relative to
        non-leaders. Leaders experienced higher rates of better products and services (50% vs. 19%), enhanced brand
        differentiation (48% vs. 14%), and accelerated innovation (43% vs. 17%) from their responsble AI programs
        relative to non-leaders.
      </p>
      <p>
        An additional finding was that a business's AI maturity level also moderates the likelihood of attaining
        benefits from responsible AI programs. Table 3.1 shows how business leadership levels in responsible AI and AI
        maturity interact. Although leadership seems to more substantially increase the chance of benefits from a
        responsible AI program than AI maturity does, businesses that were both responsible AI leaders and had high AI
        maturity were the most likely to gain benefits.
      </p>

      <AIGovernanceTable />

      <p>
        It is nonetheless notable that MIT Sloan and BCG found that most businesses with any level of responsible AI
        leadership and AI maturity had not yet experienced AI benefits to products and services, brand differentiation,
        or innovation. Increasing AI maturity levels, plus the opportunities afforded by gen AI not available at the
        time of this survey, plausibly mean that the majority of at least responsible AI leaders with high AI maturity
        are seeing business benefits from their responsible AI program. Finally, that no comparison was made on the ROI
        between businesses using AI with or without a responsible AI program makes understanding the relative ROI of
        implementing AI Governance less clear.
      </p>
      <p>
        Intangible ROI could also be high from AI Governance.{" "}
        <a href="https://arxiv.org/pdf/2309.13057">IBM and University of Notre Dame researchers</a> define intangible
        impacts as the indirect paths to return associated with organizational reputation. From the corporate social
        responsibility (CSR) perspective, AI Governance produces considerable value for society, primarily by mitigating
        risks. The authors argue that strong CSR and environmental, social, and governance (ESG) ratings for a business
        "improve sales and reputation and can decrease the likelihood of customer churn." Such benefits can be estimated
        using the Social Return on Investment (SROI) technique.<sup>30</sup>
      </p>
      <p>
        The researchers also emphasize the potential value of real options for businesses from AI Governance. The
        authors define real options as "small investments made by organizations that generate future flexibility – they
        position managers to make choices that capitalize on future opportunities." Resources can be invested into
        actions in phases throughout a project as a business learns more about which actions might be especially
        promising for it to take. This approach is especially beneficial when investment opportunities are highly
        uncertain.
      </p>
      <p>
        For example, real options reasoning helps businesses assess and justify investments in a novel technology that
        builds business knowledge, skills, and technical infrastructure. In the case of AI ethics, the "capabilities an
        organization gains from an investment in ethical product development will further result in opportunities for
        possible product improvements, the mitigation of identified product issues, and possibly a greater awareness and
        thus improvement in the organization's employee and customer culture."
      </p>
      <p>Both intangible ROI and real options are strongly connected to building trust and regulatory compliance.</p>
      <h4 id={contents[13].anchor}>{contents[13].text}</h4>
      <p>
        KPMG's 2023 "
        <a
          href="https://assets.kpmg.com/content/dam/kpmg/au/pdf/2023/trust-in-ai-global-insights-2023.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Trust in AI
        </a>
        " study argues that there are four distinct pathways to trust. These are an institutional pathway reflecting
        beliefs such as the adequacy of current safeguards, a motivational pathway reflecting perceived benefits of AI,
        an uncertainty reduction pathway regarding the mitigation of AI risks, and a knowledge pathway reflecting
        people's ability to understand and use AI. The first and third each highlight just how important a business's
        risk mitigation capabilities are for building trust in others.<sup>33</sup>
      </p>
      <p>
        This perception of a business's commitment and ability to mitigate AI risks strongly dictates how consumers,
        employees, and other groups engage that business. Strong signals of a business's commitment and abilities are
        necessary to reliably maintain favorable perception.
      </p>

      <p>
        To highlight just how important AI Governance is perceived as being to trust in AI, a 2024{" "}
        <a href="https://www.ibm.com/downloads/cas/QJ2BYLZG" target="_blank" rel="noreferrer">
          survey
        </a>{" "}
        from IBM indicates that three-quarters of CEOs believe that trusted AI is impossible without AI Governance.
        <sup>34</sup> Many businesses might thus announce commitments to AI Governance without investing in its
        implementation. TechBetter's research emphasizes that failing to follow through with commitments to AI
        Governance amounts to "ethics washing" which misleads and potentially disillusions others about a business's
        motivations and reliability.<sup>35</sup> By having untrustworthy or no AI Governance, businesses risk losing
        support.
      </p>
      <p>
        Sentiments towards Data Privacy, for example, are strong, with IBM{" "}
        <a href="https://www.ibm.com/downloads/cas/6JAM3VB7">finding</a> that 57% of consumers are uncomfortable with
        how their data is being handled. In comparison, 37% have switched to more privacy-protective brands.
        <sup>43</sup> Consumers would also likely act based on their trust in how businesses use AI. In a separate IBM{" "}
        <a href="https://newsroom.ibm.com/2024-01-10-Data-Suggests-Growth-in-Enterprise-Adoption-of-AI-is-Due-to-Widespread-Deployment-by-Early-Adopters?sf185480910=1">
          survey
        </a>{" "}
        of IT professionals, 85% of respondents strongly or somewhat agreed that consumers are more likely to choose
        services from companies with transparent and ethical AI practices.<sup>36</sup>
      </p>
      <p>
        The trust gap between customers and businesses adopting AI seems large and possibly growing. In a{" "}
        <a
          href="https://www.salesforce.com/news/stories/customer-engagement-research-2023/"
          target="_blank"
          rel="noreferrer"
        >
          2023 survey
        </a>{" "}
        of over 14,000 international customers, research from Salesforce indicates that roughly three-quarters of
        customers were concerned about unethically using AI. Sixty-eight percent think that AI advances make it more
        important for businesses to be trustworthy. These concerns, likely amplified by the introduction of gen AI,
        might explain why consumer and business buyer openness to businesses using AI dropped between 2022 and 2023 from
        82% to 73% and 65% to 51%, respectively.<sup>37</sup>
      </p>
      <p>
        Although not specific to businesses, results from KPMG's "
        <a
          href="https://assets.kpmg.com/content/dam/kpmg/au/pdf/2023/trust-in-ai-global-insights-2023.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Trust in AI
        </a>
        " survey of over 17,000 people internationally also indicate a large trust gap around AI in general. They found
        that 61% of respondents felt unsure or disagreed that current AI safeguards are sufficient to protect from AI
        risks. These confidence levels across the Western countries surveyed have remained relatively stagnant between
        2020-2022, which is proposed to indicate that AI Governance adoption is lagging behind people's expectations.
        However, levels of trust varied considerably by geography. Most people in India (80%) and China (74%) believe
        current safeguards to be adequate.<sup>33</sup>
      </p>
      <p>
        Consumer trust erodes further when AI incidents occur. BCG{" "}
        <a
          href="https://www.bcg.com/publications/2023/ceo-agenda-must-include-responsible-use-of-ai"
          target="_blank"
          rel="noreferrer"
        >
          points out
        </a>{" "}
        that it's not only significant AI incidents, such as a data breach, that will lose a business consumer's trust
        but also individualized incidents. They give examples of someone being denied a bank loan due to a biased
        assessment of their request or a recommender algorithm suggesting Father's Day gift ideas to someone whose
        father recently died. "Today's consumers will hesitate to buy from a company that doesn't seem in control of its
        technology, or that doesn't protect values like fairness and decency."<sup>38</sup>
      </p>
      <p>
        Trust in businesses by their employees is also improved by implementing AI Governance. IAPP and EY{" "}
        <a
          href="https://iapp.org/media/pdf/resource_center/iapp_ey_professionalizing_organizational_ai_governance_report.pdf"
          target="_blank"
          rel="noreferrer"
        >
          found
        </a>{" "}
        that among Data Privacy practitioners, 65% of respondents at businesses without AI Governance don't feel
        confident about their privacy compliance.<sup>48</sup> Only 12% of respondents at businesses with AI Governance
        implemented don't feel confident. Low confidence in their business's risk mitigation capabilities could drive
        away employees. Sixty-nine percent of workers in an IBM{" "}
        <a href="https://www.ibm.com/downloads/cas/6JAM3VB7" target="_blank" rel="noreferrer">
          survey
        </a>{" "}
        would be more willing to accept job offers from socially responsible organizations, while 45% are willing to
        take a pay cut to work at one.<sup>25</sup>
      </p>
      <p>
        Boards and investors also need to trust AI risk mitigation practices. BCG{" "}
        <a
          href="https://www.bcg.com/publications/2023/ceo-agenda-must-include-responsible-use-of-ai"
          target="_blank"
          rel="noreferrer"
        >
          suggests
        </a>{" "}
        that AI incidents can harm a CEO's credibility with their board. At the same time, investors need to be
        confident that a business's use of AI is aligned with its corporate social responsibility statements.
        <sup>38</sup> At the most significant scale, KPMG{" "}
        <a
          href="https://assets.kpmg.com/content/dam/kpmg/au/pdf/2023/trust-in-ai-global-insights-2023.pdf"
          target="_blank"
          rel="noreferrer"
        >
          thinks
        </a>{" "}
        that the strength of trust's impact on people's decisions could be critical to the sustained adoption of AI by
        businesses as a whole.<sup>33</sup>
      </p>

      <h4 id={contents[14].anchor}>{contents[14].text}</h4>
      <p>
        Compliance with existing and preparation for future AI regulations increasingly make AI Governance systems{" "}
        <a
          href="https://futurium.ec.europa.eu/en/european-ai-alliance/best-practices/implementing-ai-governance-framework-practice"
          target="_blank"
          rel="noreferrer"
        >
          obligatory
        </a>
        . Businesses across all industries, sizes, and countries are increasingly asked to explain{" "}
        <a
          href="https://www.spglobal.com/en/research-insights/special-reports/the-ai-governance-challenge"
          target="_blank"
          rel="noreferrer"
        >
          how they use AI
        </a>
        . Gartner{" "}
        <a
          href="https://www.gartner.com/en/articles/what-it-takes-to-make-ai-safe-and-effective"
          target="_blank"
          rel="noreferrer"
        >
          advises
        </a>{" "}
        that businesses "be prepared to comply beyond what's already required for regulations such as those pertaining
        to privacy protection." Meanwhile, the{" "}
        <a
          href="https://www3.weforum.org/docs/WEF_Responsible_AI_Playbook_for_Investors_2024.pdf"
          target="_blank"
          rel="noreferrer"
        >
          WEF{" "}
        </a>
        argues that doing so "helps proactively mitigate regulatory and business risks, saves costs, and ultimately
        influences the regulatory landscape."
      </p>
      <p>
        Noncompliance risks substantial financial costs and legal action. For example, the most severe{" "}
        <a href="https://www.holisticai.com/blog/penalties-of-the-eu-ai-act" target="_blank" rel="noreferrer">
          penalties of the EU AI Act
        </a>{" "}
        can amount to €35 million or 7% of total worldwide annual turnover if the offender is a company. Costly
        compliance overhead, such as from audits, is also a risk to businesses unprepared for emerging regulations.
      </p>
      <p>
        While the need for regulatory compliance will be clear for many businesses, what regulations will involve is
        not. Among the key barriers to AI adoption
        <Link to="/resources/aigfc/adoption#barriers" target="_blank">
          previously identified
        </Link>{" "}
        was uncertainty about regulations. Across the surveys explored, approximately 20% and 60% of businesses struggle
        to adopt or scale AI due to uncertainty about what regulations will involve.
      </p>
      <p>
        Looking at current regulations is a start to understanding future regulations. Among the best sources of data on
        the current global regulatory landscape is the OECD Policy Observatory's database on{" "}
        <a href="https://oecd.ai/en/dashboards/overview/policy" target="_blank" rel="noreferrer">
          national AI strategies and policies
        </a>
        . It's a live repository of over 1,000 AI-related policies across 69 countries, territories, and the EU. In the
        emerging AI-related regulation{" "}
        <a
          href="https://oecd.ai/en/dashboards/policy-instruments/Emerging_technology_regulation"
          target="_blank"
          rel="noreferrer"
        >
          section
        </a>
        , we can see how many related policies different countries currently have, what groups they target, and the
        challenges they address.
      </p>
      <p>
        Of the 240 relevant policies cataloged, Saudi Arabia (21) has the most policies currently in place, followed by
        the UK (20) and then the US (18). Firms of any size are the third most commonly targeted group, with a total of
        112 related policies. Risks to human safety (81), risks to fairness (66), and data protection and right to
        privacy (61) are the most commonly targeted challenges. These statistics indicate that many emerging AI-related
        regulations strongly prioritize risk mitigation among businesses, supported by{" "}
        <a href="https://www.ibm.com/downloads/cas/KXVRM5QE" target="_blank" rel="noreferrer">
          observations from the IDC
        </a>{" "}
        that concerns about AI risks are driving an increasing amount of AI regulation.
      </p>
      <p>
        Businesses with higher levels of risk management and responsible AI seem to be more prepared for emerging
        regulations. First, IAPP and EY's{" "}
        <a
          href="https://iapp.org/media/pdf/resource_center/iapp_ey_professionalizing_organizational_ai_governance_report.pdf?referrer=content_seehereview"
          target="_blank"
          rel="noreferrer"
        >
          Autumn 2023 survey
        </a>{" "}
        found that respondents whose organizations lack AI Governance were far less confident about privacy compliance
        capabilities than those with AI Governance (65% versus 12%). This result aligns with BCG's{" "}
        <a
          href="https://web-assets.bcg.com/37/87/33f2ee9d4e2281e792472f4ec1bf/to-be-a-responsible-ai-leader-focus-on-being-responsible.pdf"
          target="_blank"
          rel="noreferrer"
        >
          2022 survey
        </a>
        , in which businesses with more mature responsible AI programs report feeling ready to meet emerging AI
        regulation requirements (51%) relative to businesses with new responsible AI programs (30%).
      </p>
      <p>
        Results from IBM's 2023{" "}
        <a href="https://www.ibm.com/downloads/documents/us-en/10a99803f9afdac2" target="_blank" rel="noreferrer">
          survey
        </a>{" "}
        tell a similar story. Fewer than 60% of executives feel prepared for gen AI regulation, while 69% expect a
        regulatory fine. This feeling of being unprepared is significantly holding businesses back. Fifty-six percent
        are delaying major investments pending clarity on AI standards and regulations, and 72% will forgo gen AI
        benefits due to ethical concerns. A lack of AI ethics is what they propose might explain these feelings. IBM
        estimates the proportion of AI budgets going to AI ethics will triple from 2018 levels of 3% to 9% in 2025.
      </p>
      <h2 id={contents[15].anchor}>{contents[15].text}</h2>
      <h4 id={contents[16].anchor}>{contents[16].text}</h4>
      <p>
        It's difficult to make confident observations about adoption trends over time due to high variance in the
        respondent audiences and AI Governance practices relevant surveys focus on. We make broad observations about
        trends in AI Governance maturity with more specific insight into recent rates of risk mitigation
        implementations.
      </p>
      <p>
        The maturity of AI Governance programs and frameworks appears to have made limited progress over 2022-2024. Four
        surveys provide moderately comparable data for understanding 2022-2023 adoption trends.
      </p>
      <p>
        First, MIT Sloan and BCG's September 2022{" "}
        <a
          href="https://www.bcg.com/publications/2023/ai-responsibility-at-crossroads"
          target="_blank"
          rel="noreferrer"
        >
          study
        </a>{" "}
        of 1000 managers found that 52% of businesses have implemented an AI Governance program.<sup>25</sup> In March
        2023, Gartner{" "}
        <a
          href="https://www.gartner.com/peer-community/oneminuteinsights/ai-governance-frameworks-responsible-ai-33q"
          target="_blank"
          rel="noreferrer"
        >
          found
        </a>{" "}
        that 46% of 200 data analytics and IT professionals had either produced a dedicated AI Governance framework or
        had finalized extending existing Data Governance policies.<sup>34</sup> Third, KPMG's{" "}
        <a
          href="https://kpmg.com/kpmg-us/content/dam/kpmg/pdf/2023/kpmg-gen-ai-survey2023.pdf"
          target="_blank"
          rel="noreferrer"
        >
          survey
        </a>{" "}
        in August 2023 found that 46% of over 200 US business leaders have a mature, responsible AI Governance program."{" "}
        <sup>48</sup> A fourth{" "}
        <a href="https://idcdocserv.com/US50056923e_IBM" target="_blank" rel="noreferrer">
          study
        </a>{" "}
        from November 2023 by IDC found that of 600 global respondents, 45% had "rules, policies, and processes to
        enforce responsible AI principles."<sup>49</sup>
      </p>
      <p>
        Despite the heterogeneity of surveys, the relative closeness in adoption rates across these four surveys makes
        it seem plausible that global AI Governance adoption rates have fallen between 45-50% between 2022-2023. Results
        for the rate of businesses actively developing or implementing AI Governance measures are less well-supported,
        but two studies put it around 40%.<sup>32, 48</sup>
      </p>
      <p>
        Data for later adoption trends over 2024 is limited. IAPP-EY's December 2023{" "}
        <a
          href="https://iapp.org/media/pdf/resource_center/iapp_ey_professionalizing_organizational_ai_governance_report_executive_summary.pdf"
          target="_blank"
          rel="noreferrer"
        >
          survey
        </a>{" "}
        of over 600 privacy professionals had 29% report having implemented AI Governance, while a July 2024 survey
        indicates that only around 30% of over 100 respondents had or were planning "to soon have the tools and skills
        for effective deployment, monitoring, and management of AI models." <sup>24, 42</sup> It's unclear what these
        figures tell us about adoption trends after November 2023; there are only two data points and the July 2024
        survey doesn't distinguish between rates of AI Governance having been adopted, in-progress adoption, or
        intentions to adopt. However, one possibility that these results support is that AI Governance adoption rates
        have declined since late 2023.
      </p>
      <p>
        The clearest insight about adoption rates comes from more granular evidence for rates of risk mitigation
        implementations. Data Stanford and Accenture's comprehensive{" "}
        <a
          href="https://aiindex.stanford.edu/wp-content/uploads/2024/05/HAI_AI-Index-Report-2024.pdf"
          target="_blank"
          rel="noreferrer"
        >
          survey
        </a>{" "}
        from April 2024 reveals the proportion of businesses that have fully, partially, or not at all implemented a
        series of best practices across five risk categories: reliability, cybersecurity, fairness, transparency and
        explainability, and data governance.<sup>28</sup>
      </p>
      <p>
        Businesses report largely similar rates of implementation of practices across all risks. For example, the
        average number of fully implemented reliability risk mitigations ranges from 36-39% across all risks, while only
        5-12% of businesses haven't implemented any best practices. The most substantial difference in adoption rates
        across all risks is that a larger proportion of businesses have implemented over 50% of cybersecurity (28%) and
        fairness (25%) practices examined compared with only 8-16% of businesses having done so across the three other
        risks.<sup>28</sup>
      </p>
      <p>
        These results overall suggest that roughly 80% of businesses using AI had been actively working towards AI
        Governance implementation or have an AI Governance program or framework already in place over 2022-2023. Trends
        for adoption since late 2023, however, remain less clear with the exception of risk mitigation practices. As of
        mid-2024, businesses report having implemented around one-third of risk mitigation practices on average across
        reliability, cybersecurity, fairness, transparency and explainability, and data governance risks. However, the
        heterogeneity of survey data makes understanding AI Governance adoption rates over time difficult to understand.
      </p>

      <h4 id={contents[17].anchor}>{contents[17].text}</h4>
      <p>
        Three key barriers identified include a lack of expertise, leadership understanding (of AI) and communication,
        and regulatory uncertainty across three surveys conducted between late 2022 and mid-2023. It's important to note
        that both ChatGPT's{" "}
        <a href="https://openai.com/index/chatgpt/" target="_blank" rel="noreferrer">
          original release
        </a>{" "}
        and the{" "}
        <a href="https://openai.com/index/gpt-4-research/" target="_blank" rel="noreferrer">
          release
        </a>{" "}
        of GPT-4 – two key events in the proliferation of gen AI – happened around the time these surveys were
        conducted.<sup>50, 51</sup> Uncertainty about how gen AI work and increasing demand for AI adoption were likely
        especially high during this period. The key barriers experienced by businesses at that time may have been
        meaningfully address for many since then.
      </p>
      <p>
        A lack of expertise was both the most widely highlighted risk across surveys examined and also the most
        frequently experienced barrier across all barriers identified. In Gartner's{" "}
        <a
          href="https://www.gartner.com/peer-community/oneminuteinsights/ai-governance-frameworks-responsible-ai-33q"
          target="_blank"
          rel="noreferrer"
        >
          survey
        </a>
        , 57% of respondents report facing a pronounced skills gap.<sup>34</sup> MIT Sloan and BCG{" "}
        <a
          href="https://web-assets.bcg.com/37/87/33f2ee9d4e2281e792472f4ec1bf/to-be-a-responsible-ai-leader-focus-on-being-responsible.pdf"
          target="_blank"
          rel="noreferrer"
        >
          find
        </a>{" "}
        that 54% of managers see a shortage in responsible AI expertise while 53% note inadequate training among staff.
        <sup>33</sup> Finally, IAPP and EY{" "}
        <a
          href="https://iapp.org/media/pdf/resource_center/iapp_ey_professionalizing_organizational_ai_governance_report_executive_summary.pdf"
          target="_blank"
          rel="noreferrer"
        >
          found
        </a>{" "}
        that 31% of respondents indicate a shortage of qualified AI governance professionals, while 33% report
        insufficient training or certification opportunities.<sup>42</sup>
      </p>
      <p>
        Leadership challenges and regulatory uncertainty are evident, too. Gartner{" "}
        <a
          href="https://www.gartner.com/peer-community/oneminuteinsights/ai-governance-frameworks-responsible-ai-33q"
          target="_blank"
          rel="noreferrer"
        >
          found
        </a>{" "}
        that 48% have high uncertainty about AI's business impact, and 37% reflect a production-first mentality.
        <sup>34</sup> Similarly, 43% of organizations in MIT Sloan and BCG's{" "}
        <a
          href="https://web-assets.bcg.com/37/87/33f2ee9d4e2281e792472f4ec1bf/to-be-a-responsible-ai-leader-focus-on-being-responsible.pdf"
          target="_blank"
          rel="noreferrer"
        >
          survey
        </a>{" "}
        experience low prioritization and funding for responsible AI initiatives, with 42% reporting insufficient
        awareness among senior leaders.<sup>33</sup> From{" "}
        <a
          href="https://iapp.org/media/pdf/resource_center/iapp_ey_professionalizing_organizational_ai_governance_report_executive_summary.pdf"
          target="_blank"
          rel="noreferrer"
        >
          IAPP and EY
        </a>
        , 56% of respondents believed a lack of understanding AI's risks and benefits to be a primary challenge with
        implementing AI Governance.<sup>42</sup>
      </p>
      <p>
        Regulatory uncertainty also remains a critical challenge. Across surveys, 42% of privacy professionals IAPP and
        EY{" "}
        <a
          href="https://iapp.org/media/pdf/resource_center/iapp_ey_professionalizing_organizational_ai_governance_report_executive_summary.pdf"
          target="_blank"
          rel="noreferrer"
        >
          surveyed
        </a>{" "}
        cite the rapid evolution of the regulatory landscape as a key challenge to implementing AI Governance, while 16%
        of data analytics and IT respondents in Gartner's{" "}
        <a
          href="https://www.gartner.com/peer-community/oneminuteinsights/ai-governance-frameworks-responsible-ai-33q"
          target="_blank"
          rel="noreferrer"
        >
          survey
        </a>{" "}
        feel there is inadequate legal pressure to enforce governance measures.<sup>34, 42</sup>
      </p>

      <h4 id={contents[18].anchor}>{contents[18].text}</h4>
      <p>
        Among deployment and operation barriers, technical and organizational appear to be the most substantial
        barriers. As many as 57% of privacy professionals IAPP and EY{" "}
        <a
          href="https://iapp.org/media/pdf/resource_center/iapp_ey_professionalizing_organizational_ai_governance_report_executive_summary.pdf"
          target="_blank"
          rel="noreferrer"
        >
          surveyed
        </a>{" "}
        think a lack of control over AI deployment is a major barrier, while 45% feel challenged by the rapid pace of
        technological change, and 39% find a lack of standardization difficult.<sup>42</sup> Data analytics and IT
        professionals meanwhile report in Gartner's{" "}
        <a
          href="https://www.gartner.com/peer-community/oneminuteinsights/ai-governance-frameworks-responsible-ai-33q"
          target="_blank"
          rel="noreferrer"
        >
          survey
        </a>{" "}
        that they're especially struggling with fragmented technologies (25%) and poor cross-functional collaboration
        (22%).<sup>34</sup> There's limited direct overlap in the specific barriers highlighted by respondents across
        surveys, which may indicate that the breadth of logistical challenges is especially large.
      </p>
      <p>
        Meanwhile, very few risk mitigation or Data Management barriers are reported. Gartner's data analytics and IT
        professionals{" "}
        <a
          href="https://www.gartner.com/peer-community/oneminuteinsights/ai-governance-frameworks-responsible-ai-33q"
          target="_blank"
          rel="noreferrer"
        >
          cited
        </a>{" "}
        a lack of Data Governance (26%) and poor Data Management (18%) as prominent barriers. On the same survey, 20% of
        respondents highlighted insufficient transparency from third-party AI solutions as a barrier. This barrier
        relates strongly to logistical barriers also.<sup>34</sup>
      </p>
      <p>
        Recent evidence for rates of AI Governance adoption among businesses indicates that progress broadly plateaued
        over 2022-2023. Roughly half of businesses have some sort of framework or program in place already while around
        40% seem to have been actively developing and implementing AI Governance as of 2023.<sup>4, 25, 34, 42</sup>{" "}
        There's weak evidence to suggest that adoption rates may have then declined closer to 30% of businesses having
        AI Governance in place from the end of 2023-2024.<sup>24, 42</sup>
      </p>
      <p>
        The most granular data available on risk mitigation indicates that although there's still considerable progress
        left for businesses to go on implementing best practices, the vast majority of large businesses globally have
        implemented at least one best practice across different AI risk categories.<sup>28</sup> More confident insights
        into recent rates of adoption are limited by the heterogeneity and limited number of surveys and other evidence.
      </p>
      <p>
        While the scale of each barrier might be different, the key barriers to AI Governance adoption can be grouped
        under similar categories to those for adopting AI itself. However, unlike with AI adoption, businesses seem to
        especially struggle more with planning and resources before deployment, such as with a lack of expertise. This
        lack of expertise could be behind downstream deployment issues.<sup>33, 34, 42</sup>
      </p>
      <p>
        Even among businesses with a framework, the cumulative effect of these barriers together has left many CEOs with
        low confidence about being able to effectively deploy AI Governance. One survey from IBM showed that of over
        2,500 CEOs globally at the beginning of 2024, only 39% whose businesses have a framework believed their
        organization's AI Governance was effective.<sup>36</sup> Evidence, therefore, indicates that not only is AI
        Governance adoption currently low and possibly static across businesses using AI but also that many existing AI
        Governance frameworks and programs might be failing to meet business needs.
      </p>

      <h4 id={contents[19].anchor}>{contents[19].text}</h4>
      <p>
        Recent evidence for rates of AI Governance adoption among businesses indicates that progress broadly plateaued
        over 2022-2023. Roughly half of businesses have some sort of framework or program in place already while around
        40% seem to have been actively developing and implementing AI Governance as of 2023.<sup>4, 25, 34, 42</sup>{" "}
        There's weak evidence to suggest that adoption rates may have then declined closer to 30% of businesses having
        AI Governance in place from the end of 2023-2024.<sup>24, 42</sup>
      </p>
      <p>
        The most granular data available on risk mitigation indicates that although there's still considerable progress
        left for businesses to go on implementing best practices, the vast majority of large businesses globally have
        implemented at least one best practice across different AI risk categories.<sup>28</sup> More confident insights
        into recent rates of adoption are limited by the heterogeneity and limited number of surveys and other evidence.
      </p>
      <p>
        While the scale of each barrier might be different, the key barriers to AI Governance adoption can be grouped
        under similar categories to those for adopting AI itself. However, unlike with AI adoption, businesses seem to
        especially struggle more with planning and resources before deployment, such as with a lack of expertise. This
        lack of expertise could be behind downstream deployment issues.<sup>33, 34, 42</sup>
      </p>
      <p>
        Even among businesses with a framework, the cumulative effect of these barriers together has left many CEOs with
        low confidence about being able to effectively deploy AI Governance. One survey from IBM showed that of over
        2,500 CEOs globally at the beginning of 2024, only 39% whose businesses have a framework believed their
        organization's AI Governance was effective.<sup>36</sup> Evidence, therefore, indicates that not only is AI
        Governance adoption currently low and possibly static across businesses using AI but also that many existing AI
        Governance frameworks and programs might be failing to meet business needs.
      </p>

      <h2 id={contents[20].anchor}>{contents[20].text}</h2>
      <p>
        AI Governance is rapidly evolving as businesses, regulators, and industry leaders work to establish effective
        frameworks that balance innovation with risk mitigation. While the adoption of AI Governance frameworks has
        progressed, evidence suggests that implementation remains inconsistent, with many organizations struggling to
        establish effective oversight and operational mechanisms.
      </p>
      <p>
        The challenges businesses face – ranging from expertise shortages to regulatory uncertainty – underscore the
        need for a proactive approach to AI Governance. Companies that invest in comprehensive, well-structured AI
        Governance frameworks not only mitigate risks but also unlock significant business benefits, including improved
        operational efficiency, enhanced trust, and stronger regulatory positioning.
      </p>
      <p>
        As AI continues to advance, organizations must move beyond viewing AI Governance as a compliance necessity and
        recognize its strategic value. By integrating AI Governance into broader corporate governance structures,
        businesses can position themselves to harness AI’s full potential while maintaining ethical integrity,
        regulatory alignment, and stakeholder confidence.
      </p>
      <p>
        For businesses at any stage of AI adoption, the next step is clear: prioritize AI Governance as a fundamental
        business function, ensuring that policies, risk management strategies, and oversight structures evolve in tandem
        with technological advancements.
      </p>

      <br />
      <ExtendedDescription title="Endnotes" description={Endnotes()} blog={true} />
    </div>
  );
};

const Endnotes = () => {
  const endnotes = aigfcSources.filter((source) => source.id.includes("gov")).map((source) => source.id);

  return (
    <ol style={{ marginLeft: 0, paddingLeft: "1em", paddingRight: ".75em" }}>
      {endnotes.map((endnote) => (
        <EndnoteSource endnoteId={endnote} />
      ))}
    </ol>
  );
};
