import { useState } from "react";
import { Link } from "react-router-dom";

import intro from "../../img/products/cap-exam-questions/cap-intro.webp";
import q1 from "../../img/products/cap-exam-questions/cap-q1.webp";
import a1 from "../../img/products/cap-exam-questions/cap-a1.webp";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";

import CustomModal from "../../components/CustomModal";
import ExtendedDescription from "../../components/ExtendedDescription";
import SubtleTestimonialCardFlip from "../../components/testimonial-flip-card/SubtleTestimonialCardFlip";

import testimonials from "../../data/testimonials";

const PRODUCT = "CAP Question Sets";

export default function CAPExamQuestions() {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);

    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}
      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        tealBox={tealBox}
        extendedDescription={extendedDescription}
        rest={rest(handleShowClick)}
      />
    </>
  );
}

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials">
    <h4>What People are Saying</h4>
    <div style={{ marginTop: "1em" }}>
      <SubtleTestimonialCardFlip
        testimonial={testimonials.find((testimonial) => testimonial.id === "cap-exam-questions-001")}
        productPage
        fontSize={1.05}
      />
    </div>
  </div>
);

const extendedDescription = (
  <div className="extended-description-container">
    <ExtendedDescription
      description={
        <div className="extended-description">
          <p>
            Test yourself for the{" "}
            <Link to="/resources/certified-analytics-professional" target="_blank" rel="noreferrer">
              Certified Analytics Professional (CAP) Exam
            </Link>{" "}
            using these helpful practice questions.
          </p>
          <p>
            Research has shown{" "}
            <a href="http://pdf.poojaagarwal.com/McDaniel_Agarwal_etal_2011_JEP.pdf" target="_blank" rel="noreferrer">
              time
            </a>{" "}
            and{" "}
            <a href="http://pdf.poojaagarwal.com/Roediger_Agarwal_etal_2011_JEPA.pdf" target="_blank" rel="noreferrer">
              time again
            </a>{" "}
            that <strong>testing ourselves</strong> is one of the most effective ways to{" "}
            <strong>achieve mastery</strong>.
          </p>
          <p>
            So if you're really interested in becoming a <strong>Data Analytics expert</strong>, doing practice
            questions like these are among the best way to get there!
          </p>
          <p>
            These Question Sets use{" "}
            <a href="http://uweb.cas.usf.edu/~drohrer/pdfs/Taylor&Rohrer2010ACP.pdf" target="_blank" rel="noreferrer">
              interleaving
            </a>{" "}
            (i.e., mixed practice) to make the long-lasting effects of studying with these practice questions even
            stronger.
          </p>
          <p>
            You'll return to them throughout your test prep until you're <strong>totally ready</strong> to take the{" "}
            <Link to="/resources/certified-analytics-professional" target="_blank" rel="noreferrer">
              CAP exam
            </Link>
            !
          </p>
          <h4>Features</h4>
          <ul>
            <li>
              <strong>50 new, never-before-seen</strong> questions for the CAP Exam
            </li>
            <li>
              Access to the practice question in an <strong>exam simulator</strong> and a <strong>PDF</strong>
            </li>
            <li>
              <strong>Detailed explanation</strong> for each answer
            </li>
            <li>
              Support for any questions about the product at{" "}
              <a href="mailto:support@datastrategypros.com?subject=CAP%20Question%20Sets">
                support@datastrategypros.com
              </a>
            </li>
          </ul>
          <p>
            Don't miss our{" "}
            <Link to="/products/cap-study-plan" target="_blank" rel="noreferrer">
              CAP Study Plan
            </Link>{" "}
            for a comprehensive guide to preparing for the test.
          </p>
        </div>
      }
    />
    <ExtendedDescription
      title="Frequently Asked Questions"
      description={
        <div className="extended-description faq">
          <p>
            <strong>Where did the CAP practice questions come from?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            The CAP practice questions were written by our team at Data Strategy Professionals. Each Question Set
            contains 50 distinct, never-before-seen questions.
          </p>

          <p>
            <strong>What is included in my purchase of the CAP Question Sets?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            The CAP Question Sets include an introduction with test preparation instructions as well as the analytics
            domain and a detailed explanation for each answer. You will also receive access to the practice questions in
            the exam simulator on our Training Site and as a PDF.
          </p>

          <p>
            <strong>
              Are these practice questions good preparation for the{" "}
              <a href="https://www.certifiedanalytics.org/acap" target="_blank" rel="noreferrer">
                aCAP
              </a>{" "}
              exam?
            </strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            Yes, practicing with the CAP Question Sets will definitely prepare you for the{" "}
            <a href="https://www.certifiedanalytics.org/acap" target="_blank" rel="noreferrer">
              aCAP exam
            </a>
            . Given that the aCAP was designed for new entrants to the field of Data Analytics, it focuses on
            foundational skills such as data interpretation, data visualization, and introductory analytical methods.
          </p>
          <p>
            <strong>What else can I do to prep for the CAP Exam?</strong>
          </p>
          <p style={{ marginBottom: "1.25em" }}>
            Check out our 65-day <Link to="/products/cap-study-plan">CAP Study Plan</Link>, as well as{" "}
            <Link to="/resources/certified-analytics-professional" target="_blank" rel="noreferrer">
              this article
            </Link>{" "}
            for more information, including a video review of the CAP Exam.
          </p>
        </div>
      }
    />
  </div>
);

const rest = (handleShowClick) => {
  return (
    <div className="white-box">
      <h4 className="box-title" style={{ textAlign: "center" }}>
        Screenshots
      </h4>
      <div className="image-display-tri">
        <figure>
          <img
            src={intro}
            alt="test prep"
            onClick={handleShowClick}
            style={{
              maxHeight: "380px",
              cursor: "pointer",
            }}
          />
        </figure>

        <figure>
          <img
            src={q1}
            alt="questions"
            onClick={handleShowClick}
            style={{
              maxHeight: "380px",
              objectFit: "cover",
              cursor: "pointer",
            }}
          />
        </figure>

        <figure>
          <img
            src={a1}
            alt="answers"
            onClick={handleShowClick}
            style={{
              maxHeight: "380px",
              cursor: "pointer",
            }}
          />
        </figure>
      </div>
    </div>
  );
};
