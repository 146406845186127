import { useHistory } from "react-router-dom";

const buttonStyle = {
  button: {
    borderRadius: 16,
    minWidth: 120,
  },
};

const Start: React.FC = () => {
  return (
    <div
      className="landing-page"
      style={{
        minHeight: "78vh",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "4em",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 8, alignItems: "center" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p style={{ fontSize: "1.25em" }}>Are you preparing for a specific certification?</p>
        </div>
        <div style={{ display: "flex", gap: 20, justifyContent: "center" }}>
          {startButtons.map((button) => (
            <StartButton key={button.title} title={button.title} link={button.link} />
          ))}
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: 8, alignItems: "center" }}>
        <p style={{ fontSize: "1.25em" }}>
          Need <strong>help</strong> with something else?
        </p>
        <p>
          Use the chat widget in the bottom right corner of the screen or send an email to{" "}
          <a href="mailto:support@datastrategypros.com">Customer Support</a>.
        </p>
      </div>
    </div>
  );
};

export default Start;

const startButtons = [
  { title: "CDMP", link: "/resources/cdmp-fundamentals-exam" },
  { title: "CAP", link: "/resources/certified-analytics-professional" },
  { title: "CIPP/E", link: "/products/#data-privacy" },
];

const StartButton: React.FC<{ title: string; link: string }> = ({ title, link }) => {
  const history = useHistory();

  return (
    <button className="btn btn-trustpilot" style={buttonStyle.button} onClick={() => history.push(link)}>
      {title}
    </button>
  );
};
