import ExtendedDescription from "../../ExtendedDescription";

const QuickStart = ({ title, description }) => {
  return (
    <div style={{ maxWidth: 1000, margin: "20px 0px" }}>
      <ExtendedDescription title={title} description={description} color="teal" />
    </div>
  );
};

export default QuickStart;
