import { useState } from "react";
import NewTestimonialCard from "../../components/new-testimonial-flip-card/NewTestimonialCard";

import testimonials from "../../data/testimonials";

import SelectorButtons from "../../components/nav/resources/SelectorButtons";

const Testimonials: React.VFC = () => {
  const topics = ["Data Strategy", "Data Privacy", "Data Analytics", "Data Science", "Career Services"];

  const [topic, setTopic] = useState(topics[0]);

  let testimonialsToShow = [];

  testimonialsToShow.push(
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-002"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-question-sets-001"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-study-plan-001"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-fundamentals-notes-001"),
    testimonials.find((testimonial) => testimonial.id === "cippe-study-plan-000"),
    testimonials.find((testimonial) => testimonial.id === "cippe-study-plan-001"),
    testimonials.find((testimonial) => testimonial.id === "cap-study-plan-001"),
    testimonials.find((testimonial) => testimonial.id === "cap-study-plan-000"),
    testimonials.find((testimonial) => testimonial.id === "cap-exam-questions-001"),
    // testimonials.find((testimonial) => testimonial.id === "cap-bundle-000"),
    testimonials.find((testimonial) => testimonial.id === "bids-email-bootcamp-001"),
    testimonials.find((testimonial) => testimonial.id === "bids-email-bootcamp-002"),
    testimonials.find((testimonial) => testimonial.id === "general-003"),
    testimonials.find((testimonial) => testimonial.id === "resume-review-000"),
    testimonials.find((testimonial) => testimonial.id === "career-coaching-000"),
    testimonials.find((testimonial) => testimonial.id === "career-coaching-001"),
    testimonials.find((testimonial) => testimonial.id === "career-coaching-002"),
    testimonials.find((testimonial) => testimonial.id === "resume-review-001")
  );

  return (
    <div>
      <div
        className="homepage"
        style={{
          padding: "20px 40px 20px 40px",
          margin: "auto",
          background: "#efefef",
        }}
      >
        <p
          style={{
            textAlign: "center",
            fontSize: "1.3em",
            marginTop: "12px",
            fontFamily: "Raleway",
          }}
          id="testimonials"
        >
          <strong>What People are Saying</strong>
        </p>
        <SelectorButtons marginTop={10} topics={topics} topic={topic} setTopic={setTopic} />
        <div
          className="center-text-box"
          style={{
            marginTop: 40,
            display: "flex",
            flexWrap: "wrap",
            justifyContent:
              testimonialsToShow.filter((testimonial) => testimonial.topic === topic).length > 2
                ? "center"
                : "space-evenly",
          }}
        >
          {testimonialsToShow
            .filter((testimonial) => testimonial.topic === topic)
            .map((testimonial) => (
              <div className="flex-item-testimonial" style={{ marginBottom: 40 }} key={testimonial.id}>
                <NewTestimonialCard testimonial={testimonial} quotes={false} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
