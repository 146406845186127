import { useEffect } from "react";
import logo from "../../img/helmet/products/logo_cdmp.png";

import CustomHelmet from "../../components/CustomHelmet";
import IntellectualProperty from "./IntellectualProperty";

const TermsAndConditions: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CustomHelmet
        description="Terms & Conditions"
        name="Terms & Conditions"
        link="/terms-and-conditions"
        img={logo}
        alt="hand holding lightbulb"
      />
      <div className="home-layout">
        <h1 className="title">Terms & Conditions</h1>
        <div className="blog">
          <div style={{ marginBottom: "2em" }}>
            <h4>Privacy Policy </h4>
            <p>
              Data Strategy Professionals is committed to protecting the privacy of our community members. We will not
              sell, rent, or disclose your information to third parties for marketing purposes. We use industry-standard
              security measures to protect your information from unauthorized access or disclosure.
            </p>
          </div>
          <IntellectualProperty />
          <h4>Payment for Goods & Services</h4>
          <p>
            We welcome customers worldwide, but checkout is only available in USD. All sales are final. When you pay
            Data Strategy Professionals by credit card, you warrant that the credit card information you provide is
            correct. You will be charged in USD according to the pricing and payment specifications outlined in the
            checkout process. Data Strategy Professionals will treat your information as confidential and will process
            it in a manner consistent with industry security standards.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
