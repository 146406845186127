import ExtendedDescription from "../ExtendedDescription";

export default function ExtendedDescriptionContainer({ items }) {
  return (
    <>
      {items.map((item, idx) => (
        <div className="extended-description-container" key={idx}>
          <ExtendedDescription description={item.description} title={item.title} />
        </div>
      ))}
    </>
  );
}
