import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import { CheckoutContext } from "../../contexts/CheckoutContext";

import CustomHelmet from "../CustomHelmet";
import ProductsModal from "../products-modal/ProductsModal";
import MultiSelect from "../checkout/MultiSelect";
import ProductImage from "../checkout/ProductImage";
import USDFlag from "./USDFlag";

import products from "../../data/products";
import payments from "../../data/payments";

import { formatCurrency, formatCurrencySimple } from "../../helpers/utils";
import { handleProductPurchase, getSelectedOptions } from "../../helpers/checkoutUtils";

import ProductDescription from "./ProductDescription";
import BundleTealBox from "./BundleTealBox";

const MultiPriceProductPage = ({
  PRODUCT,
  description = <ProductDescription PRODUCT={PRODUCT} />,
  tealBox = <BundleTealBox PRODUCT={PRODUCT} />,
  extendedDescription = <></>,
  rest = <></>,
  clickable = false,
}) => {
  const product: productType = products.find((product) => product.name === PRODUCT);
  const payment: paymentType = payments.find((payment) => payment.product === PRODUCT);

  const OPTIONS = payment.options;

  const defaultSelectValues = OPTIONS.map((option) => option.content[0].label);

  function hasMultiplePrices(payment) {
    let multiplePrices = false;

    let pricesSet = new Set(); // Use a Set to store unique prices

    payment.options.forEach((option) => {
      option.content.forEach((contentItem) => {
        if (contentItem.price) {
          // Check if the price exists and add it to the set
          pricesSet.add(contentItem.price);
        }
      });

      if (pricesSet.size > 1) {
        multiplePrices = true;
      }
    });

    return multiplePrices;
  }

  function findInitialPrice(options) {
    for (const option of options) {
      for (const item of option.content) {
        if (item.price !== undefined) {
          return item.price; // return the first encountered price and stop the search
        }
      }
    }
    return payment.price;
  }

  const [price, setPrice] = useState(findInitialPrice(OPTIONS));

  const [showProductsPopup, setShowProductsPopup] = useState(false);

  const [selectValues, setSelectValues] = useState<emailListsName[] | string[]>(defaultSelectValues);

  const history = useHistory();

  const checkoutContext = useContext(CheckoutContext) as checkoutContext;
  const { confirmationTable, setConfirmationTable, setStep } = checkoutContext.checkout;
  const { setDiscountValue, setDiscountCodeInput } = checkoutContext.discount;
  const { setDisplayPrice } = checkoutContext.price;

  useEffect(() => {
    window.scrollTo(0, 0);

    setDiscountValue(0);
    setDisplayPrice(price);
    setDiscountCodeInput("");

    const table: confirmationTableRow[] = [
      {
        selected: true,
        paymentName: payment.name as paymentName,
        type: payment.type as paymentType["type"],
        selectedOptions: getSelectedOptions(payment, selectValues),
        price: price,
        metadata: {
          count: 1,
          unit: "",
          abandonedCartList: payment.abandonedCartList,
          discountCode: "",
          discountValue: 0,
          discountAmount: 0,
          shippingAmt: 0,
          taxCode: payment.taxCode,
          taxRate: 0,
        },
      },
    ];

    console.log("table", table);

    setConfirmationTable(table);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (confirmationTable) {
      const updatedRow = {
        ...confirmationTable[0],
        paymentName: payment.name as paymentName,
        type: payment.type as paymentType["type"],
        selectedOptions: getSelectedOptions(payment, selectValues),
        price: price,
        metadata: {
          ...confirmationTable[0].metadata,
          discountValue: 0,
          discountAmount: 0,
        },
      };

      const table = [updatedRow];

      setConfirmationTable(table as confirmationTableRow[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectValues, price]);

  return (
    <>
      {showProductsPopup && (
        <ProductsModal
          setShowProductsPopup={setShowProductsPopup}
          products={["CDMP Fundamentals Bundle", "Resume & LinkedIn Review", "Foundations of Data Strategy Poster"]}
        />
      )}
      <CustomHelmet
        description={product.description}
        name={product.name}
        link={product.link}
        img={product.previewImg || product.img}
        alt={product.alt}
      />
      <USDFlag />

      <div className="product-layout">
        <h1 className="title product-title">{product.name}</h1>

        <div className="product-info thirds">
          <div id="product-left-side">
            {confirmationTable && (
              <ProductImage ROW={confirmationTable[0]} product={product} clickable={clickable} showProductImage />
            )}
            <form>
              <MultiSelect
                PRODUCT={PRODUCT}
                OPTIONS={OPTIONS}
                selectValues={selectValues}
                setSelectValues={setSelectValues}
                setPrice={setPrice}
              />
              <button
                className="btn btn-accent"
                type="submit"
                id="btn-purchase"
                onClick={(event) => {
                  handleProductPurchase(event, payment.name, history, setStep);
                }}
              >
                Purchase{" "}
                {!hasMultiplePrices(payment) && (
                  <span style={{ fontFamily: "Roboto", fontWeight: 400 }}>
                    ({payment.name === "mug" ? formatCurrency(price) : formatCurrencySimple(price)})
                  </span>
                )}
              </button>
              {payment.type === "service" && (
                <button
                  className="btn btn-trustpilot"
                  onClick={() => {
                    window.open("https://www.trustpilot.com/review/datastrategypros.com", "_blank");
                    setShowProductsPopup(true);
                  }}
                  id="btn-purchase"
                  style={{ fontSize: 14.4 }}
                >
                  Check us out on Trustpilot
                </button>
              )}
            </form>
          </div>
          {description}
          {tealBox}
          {extendedDescription}
        </div>
        {rest}
      </div>
    </>
  );
};

export default MultiPriceProductPage;
