import { useEffect } from "react";
import { Link } from "react-router-dom";

import CustomHelmet from "../CustomHelmet";
import LeadMagnetSignup from "./LeadMagnetSignup";
import LandingPageDisclaimer from "./LandingPageDisclaimer";

import posts from "../../data/blog/posts";

type LandingPageProps = {
  postName: string;
  closeLink?: string;
  description: string;
  leadMagnetName: string;
  listNumber: number;
  Rest: React.ReactNode;
};

const LandingPage: React.VFC<LandingPageProps> = ({
  postName,
  closeLink = "/resources",
  description,
  leadMagnetName,
  listNumber,
  Rest,
}) => {
  const post = posts.find((post) => post.nickname === postName);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CustomHelmet
        description={post.subtitle}
        author={post.author.map((author) => author).join(", ")}
        name={post.title}
        link={post.link}
        img={post.image.previewImage ?? post.image.img}
        alt={post.image.alt}
      />

      <div className="landing-page" style={{ minHeight: "88vh" }}>
        <div className="landing-page-inner">
          <Link to={closeLink}>
            <span className="landing-page-close-icon">x</span>
          </Link>
          <p className="title landing-page-title">{post.title}</p>
          <div className="landing-page-divider" style={{ marginTop: "-1.7em" }} />
          <p
            style={{ fontSize: "1.3em", margin: "-0.9em 0 1.5em 0" }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ margin: "20 auto" }}>
              <LeadMagnetSignup leadMagnetName={leadMagnetName} emailList={listNumber} />
            </div>
          </div>
          {Rest}
          <LandingPageDisclaimer />
        </div>
      </div>
    </>
  );
};

export default LandingPage;
