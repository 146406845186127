import { Link } from "react-router-dom";
const AboutDSP: React.FC = () => {
  return (
    <>
      <h2>About Data Strategy Professionals</h2>
      <p>Our mission is to help test takers prepare for data-related certifications.</p>
      <p>
        Since 2021, Data Strategy Professionals has offered training to data practitioners. We run several communities
        on social media, host a monthly discussion group on Zoom, and publish an{" "}
        <Link to="/newsletter/archive" target="_blank">
          email newsletter
        </Link>{" "}
        twice a month. Our study materials help data practitioners{" "}
        <Link to="/get-certified" target="_blank">
          prepare for certifications
        </Link>{" "}
        related to Data Strategy, Data Analytics, Data Science, and Data Privacy.
      </p>
      <p>
        Please don't hesitate to reach out to <a href="mailto:info@datastrategypros.com">info@datastrategypros.com</a>{" "}
        to share your goals. We look forward to assisting you on your Data Strategy journey.
      </p>
    </>
  );
};

export default AboutDSP;
