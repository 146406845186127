import { useState } from "react";
import { Link } from "react-router-dom";

import { Coaches } from "../../components/product-pages/Coaches";
import CDMPLevelsModal from "../../components/CDMPLevelsModal";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";
import VisualDescription from "../../components/product-pages/VisualDescription";
import ExtendedDescription from "../../components/ExtendedDescription";

const PRODUCT = "CDMP Tutoring" as productName;

const CDMPTutoring = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <>
      {showModal && <CDMPLevelsModal setShowModal={setShowModal} />}
      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        description={description}
        tealBox={tealBox}
        extendedDescription={extendedDescription}
        rest={rest}
      />
    </>
  );
};

export default CDMPTutoring;

const description = (
  <div className="gray-box" id="product-description">
    <h4>Description</h4>
    <div className="blog">
      <VisualDescription product={PRODUCT} />
      {/* <p>
          Are you looking for a <strong>clear pathway</strong> to attain your{" "}
          <span className="fake-link" onClick={() => setShowModal(true)}>
            goal score
          </span>{" "}
          on the <Link to="/resources/cdmp-fundamentals-exam">CDMP Fundamentals</Link> or{" "}
          <Link to="/resources/cdmp-specialist-exam">Specialist</Link> Exams? Have questions about the{" "}
          <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
            <em>DMBOK</em>
          </a>
          ? Looking for ways to study smarter not harder and save time as you get ready for this difficult exam?
        </p> */}
      <p>
        Preparing for the CDMP exam is not easy, but our CDMP Tutors are here to help through{" "}
        <strong>one-hour sessions</strong> tailored to your needs. Our Tutors are expert data practitioners who have
        attained top scores on the CDMP exam. We're excited to help you get ready for the test!
      </p>
      <p>We will help you:</p>
      <ul>
        <li>
          Review challenging concepts and topics, <strong>clarifying any uncertainties</strong> you may have
        </li>
        <li>
          Develop an effective study schedule to help you <strong>get your reading done</strong>
        </li>
        <li>
          <strong>Assess whether you're ready</strong> to take the exam, and provide guidance on how to improve your
          score
        </li>
      </ul>
    </div>
  </div>
);

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials">
    <h4>Additional ways to study for the CDMP</h4>
    <div className="blog">
      <p>
        We also offer the{" "}
        <Link to="/products/cdmp-fundamentals-bundle" target="_blank">
          CDMP Fundamentals Bundle
        </Link>{" "}
        to help you prepare efficiently for the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals Exam
        </Link>
        .
      </p>
      <p>
        Be sure to check out the{" "}
        <Link to="/products/data-management-master-class" target="_blank">
          Data Management Master Class
        </Link>{" "}
        as well, which can help you advance your understanding of more complicated topics to answer difficult exam
        questions.
      </p>

      <p>
        Finally, don't miss our{" "}
        <Link to="/products/document-checklist" target="_blank">
          Data Strategy Document Checklist
        </Link>{" "}
        for a comprehensive list of documents discussed in the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>
        .
      </p>
    </div>
  </div>
);

const extendedDescription = (
  <div className="extended-description-container">
    <ExtendedDescription
      description={
        <div className="extended-description">
          <p>
            Upon booking, you'll receive a link to our intake survey, wherein we ask you about your goals for CDMP
            Tutoring and the CDMP exam. Within the next 1-2 business days, we will then match you with the CDMP Tutor
            best suited to help you reach your goals. You can decide the best time for your session with your Tutor. In
            the hour-long sessions, your Tutor will help you address any areas of uncertainty you have about the exam.
          </p>
        </div>
      }
    />
  </div>
);

const rest = <Coaches tutoring={true} />;
