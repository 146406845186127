import { useState } from "react";
import { Link } from "react-router-dom";

import MultiPriceProductPage from "../../../components/product-pages/MultiPriceProductPage";
import ExtendedDescription from "../../../components/ExtendedDescription";
import ProductTestimonials from "../../../components/product-testimonials/ProductTestimonials";
import CDMPLevelsModal from "../../../components/CDMPLevelsModal";
import CustomModal from "../../../components/CustomModal";
import WhatPeopleAreSaying from "../../../components/product-pages/WhatPeopleAreSaying";
import { Instructors } from "../../../components/product-pages/Coaches";
import ShaulisTealBox from "../../../components/product-pages/ShaulisTealBox";
import WhatsIncluded from "../../../components/product-pages/WhatsIncluded";
import BuyNowButton from "../../../components/product-pages/BuyNowButton";

import day1 from "../../../img/products/bootcamps/day1.webp";
import mockExam from "../../../img/products/bootcamps/mock-exam.webp";
import nicoleAndJohn from "../../../img/products/bootcamps/nicole-and-john.webp";
import smallGroup from "../../../img/products/bootcamps/small-group.webp";

import testimonials from "../../../data/testimonials";

const PAYMENT = "cdmp-small-group";

const CDMP_Small_Group: React.FC<{ product: productName }> = ({ product }) => {
  const [showCDMPLevelsModal, setShowCDMPLevelsModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const handleShowClick = (event) => {
    setShowModal(!showModal);
    setImage(event.currentTarget.src);
    setAlt(event.currentTarget.alt);
  };

  return (
    <>
      {showCDMPLevelsModal && <CDMPLevelsModal setShowModal={setShowCDMPLevelsModal} />}
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}

      <MultiPriceProductPage
        PRODUCT={product}
        tealBox={<WhatPeopleAreSaying selectedTestimonials={["enterprise-custom-training-000"]} />}
        extendedDescription={extendedDescription()}
        rest={rest()}
      />
    </>
  );
};

const extendedDescription = () => {
  return (
    <div className="extended-description-container">
      <ExtendedDescription
        title="Learn More"
        description={
          <div className="extended-description blog">
            <p>
              The <strong>CDMP Small Group Sessions</strong> includes:
            </p>
            <ul>
              <li>
                Weekly <strong>1-hour Zoom session</strong> on Friday at 12pm ET with instructor-led presentation over{" "}
                <strong>13 weeks</strong>
              </li>
              <li>
                <Link to="/products/cdmp-study-plan" target="_blank">
                  CDMP Study Plan
                </Link>{" "}
                ($115 value) delivered by email over 13 weeks as well as lifetime access to the content Study Plan
                content in our Training Site
              </li>
              <li>
                Lifetime access to <strong>140 practice questions</strong> ($70 value) delivered via mock exam simulator
                on our Training Site
              </li>
              <li>
                Access to <strong>session recordings</strong> and <strong>private Slack channel</strong> for
                participants for the duration of the program plus four weeks
              </li>
            </ul>
          </div>
        }
      />
      <ExtendedDescription
        title="Frequently Asked Questions"
        description={
          <div className="extended-description faq">
            <p>
              <strong>Who are the CDMP Small Group Sessions designed for?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              This study program is designed for prospective certificants who wish to efficiently prepare for the{" "}
              <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
                CDMP Fundamentals Exam
              </Link>{" "}
              over 13 weeks.
            </p>

            <p>
              <strong>What is included in the Small Group Sessions?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              Participants will receive access to the{" "}
              <Link to="/products/cdmp-study-plan" target="_blank">
                CDMP Study Plan
              </Link>{" "}
              ($115 value), 140 practice questions ($70 value), session recordings, and a private Slack channel.
            </p>

            <p>
              <strong>What is not included in the Small Group Sessions?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              The Bootcamp does not include the{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>Data Management Body of Knowledge</em>
              </a>{" "}
              or{" "}
              <a href="https://cdmp.info/exams/" target="_blank" rel="noreferrer">
                CDMP Fundamentals Exam
              </a>
              . We recommend purchasing the <em>DMBOK</em> as a{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                hardcopy
              </a>{" "}
              or{" "}
              <a href="https://technicspub.com/dmbok2/" target="_blank" rel="noreferrer">
                ebook
              </a>
              . The{" "}
              <a href="https://cdmp.info/exams/" target="_blank" rel="noreferrer">
                CDMP exam
              </a>{" "}
              can be purchased as a standard 90-minute exam or 110-minute exam (ESL version) for non-native English
              speakers.
            </p>

            <p>
              <strong>Are the Small Group Sessions adequate preparation to pass the CDMP exam?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              Yes! The Small Group Sessions are designed to provide a structured review of the{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>DMBOK</em>
              </a>{" "}
              over 90 days, which is the recommended duration of preparation for the{" "}
              <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
                CDMP exam
              </Link>
              .
            </p>

            <p>
              <strong>
                Do I have to read the <em>DMBOK</em> before the Small Group Sessions start? Are there any other
                prerequisites for the program?
              </strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              Nope, there are no prerequisites for the Small Group Sessions. Having reviewed the{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>DMBOK</em>
              </a>{" "}
              is not a requirement given that we will be reviewing the <em>DMBOK</em> over the course of the program,
              using the{" "}
              <Link to="/products/cdmp-study-plan" target="_blank">
                CDMP Study Plan
              </Link>{" "}
              as a guide. We will discuss each of the fourteen chapters in a structured presentation as part of the
              weekly Zoom session.
            </p>

            <p>
              <strong>What can I do to prepare for the Small Group Sessions?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              We recommend joining the CDMP Study Group on{" "}
              <a
                href="https://www.facebook.com/groups/346145433213551"
                target="_blank"
                rel="noreferrer"
                style={{ whiteSpace: "nowrap" }}
              >
                Facebook
              </a>{" "}
              or{" "}
              <a href="https://www.linkedin.com/groups/13951141/" target="_blank" rel="noreferrer">
                LinkedIn
              </a>
              . These groups are a great place to ask questions, share study tips, and network with others. We also
              recommend that you go ahead and{" "}
              <a href="https://cdmp.info/exams/" target="_blank" rel="noreferrer">
                purchase the exam
              </a>
              , so you have access to the official practice questions provided by DAMA. Finally, you may benefit from
              checking out some of our{" "}
              <a href="https://www.youtube.com/@datastrategypros" target="_blank" rel="noreferrer">
                video content on YouTube
              </a>{" "}
              to prepare for the test.
            </p>

            <p>
              <strong>Will the materials be available after the Small Group Sessions?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              Participants will have lifetime access to the CDMP Study Plan and practice questions. Participants will
              have access to the session recordings and Slack channel for four weeks following the conclusion of the
              Small Group Sessions.
            </p>

            <p>
              <strong>What if I have an unexpected conflict and cannot attend a session?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              No problem, the sessions will be recorded and made available to participants through our Training Site.
            </p>

            <p>
              <strong>What is the format of the weekly Small Group Session?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              The study sessions will be conducted via Zoom every Friday for 13 weeks. Each session will include an
              instructor-led presentation, practice questions, and the opportunity to discuss the chapter as a group.
              The sessions are interactive and provide an opportunity to network with other participants.
            </p>

            <p>
              <strong>How does the CDMP Study Plan work?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              Participants will receive the{" "}
              <Link to="/products/cdmp-study-plan" target="_blank">
                CDMP Study Plan
              </Link>{" "}
              by email starting the day after registration closes. The CDMP Study Plan includes 5-6 emails per week that
              provide guidance on what to read each week in order to prepare for the study session on Friday. The Study
              Plan also includes study tips, practice questions, and other resources to help you prepare for the exam.
            </p>

            <p>
              <strong>How does the Training Site work?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              Each participant will receive a unique login to our Training Site, where you'll be able to access the
              content of the CDMP Study Plan, practice questions (mock exam simulator), and session recordings.
            </p>

            <p>
              <strong>How does the Slack channel work?</strong>
            </p>
            <p style={{ marginBottom: "1.25em" }}>
              All participants will be granted access to a Slack channel where they can ask questions of the instructor
              and network with other participants. The Slack channel will be available before the start of the program
              and for four weeks afterward. Participants are also encouraged to connect on LinkedIn for continued
              networking.
            </p>
          </div>
        }
      />
    </div>
  );
};

const rest = () => {
  const testimonialsToShowOne = [
    testimonials.find((testimonial) => testimonial.id === "cdmp-small-group-000"),
    testimonials.find((testimonial) => testimonial.id === "data-strategy-bootcamp-005"),
    testimonials.find((testimonial) => testimonial.id === "cdmp-small-group-001"),
  ];

  const testimonialsToShowTwo = [
    testimonials.find((testimonial) => testimonial.id === "cdmp-small-group-002"),
    testimonials.find((testimonial) => testimonial.id === "general-001"),
    testimonials.find((testimonial) => testimonial.id === "general-005"),
    testimonials.find((testimonial) => testimonial.id === "general-011"),
    testimonials.find((testimonial) => testimonial.id === "general-012"),
    testimonials.find((testimonial) => testimonial.id === "general-013"),
  ];

  const images = [
    {
      src: day1,
      alt: "CDMP Study Plan",
    },
    {
      src: mockExam,
      alt: "140 practice questions",
    },
    {
      src: nicoleAndJohn,
      alt: "Friendly instructors",
    },
    {
      src: smallGroup,
      alt: "Supportive cohort of peers",
    },
  ];

  return (
    <>
      <Instructors instructors={["John"]} />
      <WhatsIncluded images={images} boxStyle="white-outline-box" />
      <BuyNowButton margins="60px" paymentName={PAYMENT as paymentName} />

      <ProductTestimonials testimonialsToShow={testimonialsToShowOne} />

      <div className="white-outline-box">
        <h4 className="box-title">Features</h4>
        <div className="center-text-box two-col" id="features">
          <ul>
            <li>
              <strong>Weekly 1-hour Zoom session</strong> with instructor-led presentation over{" "}
              <strong>13 weeks</strong>
            </li>
            <li>
              Instruction on each of the <strong>14 chapters</strong> of the{" "}
              <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                <em>DMBOK</em>
              </a>
              , provided roughly in order of priority
            </li>
            <li>
              <strong>Access</strong> to our best selling{" "}
              <Link to="/products/cdmp-study-plan" target="_blank">
                CDMP Study Plan
              </Link>{" "}
              that includes 75+ emails over the duration of the CDMP Small Group Sessions
            </li>
            <li>
              <strong>140 practice questions</strong> to help you assess your progress each week
            </li>
            <li>
              Access to <strong>session recordings</strong> and private Slack channel for participants
            </li>
            <li>
              <strong>Interactive approach</strong> to ensure you're networking and learning with your peers
            </li>
            <li>
              <strong>Study tips</strong> to ensure you're maximizing your time and energy as you prepare
            </li>
          </ul>
        </div>
      </div>
      <ProductTestimonials testimonialsToShow={testimonialsToShowTwo} />
      <BuyNowButton margins="60px" paymentName={PAYMENT as paymentName} />
      <ShaulisTealBox />
    </>
  );
};

export default CDMP_Small_Group;
