const aigfcSources: AIGFCSource[] = [
  {
    id: "intro-001",
    institution: "Boston Consulting Group",
    report: "A Guide to AI Governance for Business Leaders",
    reportLink: "https://www.bcg.com/publications/2023/a-guide-to-mitigating-ai-risks",
    date: "Nov 2023",
  },
  {
    id: "intro-002",
    institution: "OECD",
    report: "Artificial Intelligence in Society",
    reportLink:
      "https://www.oecd.org/content/dam/oecd/en/publications/reports/2019/06/artificial-intelligence-in-society_c0054fa1/eedfee77-en.pdf",
    date: "Aug 2019",
  },
  {
    id: "adoption-001",
    institution: "IBM",
    report: "Global AI Adoption Index 2023",
    reportLink:
      "https://newsroom.ibm.com/2024-01-10-Data-Suggests-Growth-in-Enterprise-Adoption-of-AI-is-Due-to-Widespread-Deployment-by-Early-Adopters?sf185480910=1",
    surveyed: "8,584 global IT Professionals in manager or higher roles",
    date: "Jan 2024",
  },
  {
    id: "adoption-002",
    institution: "IBM",
    report: "The CEO’s Guide to Generative AI",
    reportLink: "https://www.ibm.com/downloads/cas/6JAM3VB7",
    surveyed: "data from three different surveys",
    date: "Oct 2023",
  },
  {
    id: "adoption-003",
    institution: "KPMG",
    report: "Generative AI Survey",
    reportLink: "https://kpmg.com/kpmg-us/content/dam/kpmg/pdf/2023/kpmg-gen-ai-survey2023.pdf",
    surveyed: "225 US business leaders",
    date: "June 2023",
  },
  {
    id: "adoption-004",
    institution: "McKinsey",
    report: "The State of AI in Early 2024",
    reportLink:
      "https://www.mckinsey.com/~/media/mckinsey/business%20functions/quantumblack/our%20insights/the%20state%20of%20ai/2024/the-state-of-ai-in-early-2024-final.pdf",
    surveyed: "1,363 participants from across roles and regions",
    date: "May 2024",
  },
  {
    id: "adoption-005",
    institution: "IDC",
    report: "Global AI Buyer Sentiment, Adoption, and Business Value Survey",
    reportLink: "https://idcdocserv.com/US50056923e_IBM",
    surveyed: "607 global respondents",
    date: "October 2023",
  },
  {
    id: "adoption-006",
    institution: "IDC",
    report: "AI Strategies View Survey",
    reportLink: "https://www.ibm.com/downloads/cas/KXVRM5QE",
    surveyed: "2,053 organizations",
  },
  {
    id: "adoption-007",
    institution: "TDWI",
    report: "The State of AI Readiness 2024",
    reportLink: "https://tdwi.org/Research/2024/07/ADV-ALL-2024-State-of-AI-Readiness.aspx",
    surveyed: "113 respondents from various industries and company sizes",
  },
  {
    id: "adoption-008",
    institution: "Expleo",
    report: "Integrating AI report",
    reportLink: "https://expleo.com/global/en/wp-content/documents/EXPLEO%20-%20AI%20REPORT.pdf",
    surveyed: "803 West European C-suite level executives",
  },
  {
    id: "adoption-009",
    institution: "Stanford University",
    report: "AI Index Report 2024",
    reportLink: "https://aiindex.stanford.edu/wp-content/uploads/2024/05/HAI_AI-Index-Report-2024.pdf",
    date: "Apr 2024",
  },
  {
    id: "adoption-010",
    institution: "Retool",
    report: "2024 State of AI in Production",
    reportLink: "https://retool.com/blog/state-of-ai-h1-2024",
    date: "June 2024",
  },
  {
    id: "adoption-011",
    institution: "Capgemini",
    report: "2020 State of AI Report",
    reportLink: "https://www.capgemini.com/wp-content/uploads/2021/02/State-of-AI_Report_Web-2.pdf",
  },
  {
    id: "adoption-012",
    institution: "TechCrunch",
    report: "China's declining investment in AI",
    reportLink: "https://techcrunch.com/2024/02/05/china-ai-investment-decline/",
    date: "Feb 2024",
  },
  {
    id: "adoption-013",
    institution: "European Investment Bank",
    report: "Europe's technology investment gap",
    reportLink:
      "https://www.eib.org/en/press/all/2021-181-new-eib-report-eur10-billion-investment-gap-in-artificial-intelligence-and-blockchain-technologies-is-holding-back-the-european-union",
    date: "June 2021",
  },
  {
    id: "adoption-014",
    institution: "OECD",
    report: "The Technical Landscape",
    reportLink: "https://read.oecd-ilibrary.org/science-and-technology/artificial-intelligence-in-society_8b303b6f-en",
    date: "Aug 2019",
  },
  {
    id: "adoption-015",
    institution: "IDC",
    report: "2022 AI Strategies View Survey",
    reportLink: "https://www.ibm.com/downloads/cas/KXVRM5QE",
    date: "Sep 2023",
  },
  {
    id: "adoption-016",
    institution: "TDWI",
    report: "State of AI Readiness 2024",
    reportLink: "https://tdwi.org/Research/2024/07/ADV-ALL-2024-State-of-AI-Readiness.aspx",
    date: "Sep 2024",
  },
  {
    id: "risk-001",
    institution: "IBM",
    report: "Overview of AI Governance",
    reportLink: "https://www.ibm.com/topics/ai-governance",
    date: "Oct 2024",
  },
  {
    id: "risk-002",
    institution: "AWS",
    report: "Whitepaper on AI Governance",
    reportLink: "https://docs.aws.amazon.com/pdfs/whitepapers/latest/aws-caf-for-ai/aws-caf-for-ai.pdf",
    date: "2024",
  },
  {
    id: "risk-003",
    institution: "AstraZeneca",
    report: "Experience with AI Governance",
    reportLink: "https://www.frontiersin.org/journals/computer-science/articles/10.3389/fcomp.2022.1068361/full",
    date: "Nov 2022",
  },
  {
    id: "risk-004",
    institution: "Stanford",
    report: "AI Index Report 2024",
    reportLink: "https://aiindex.stanford.edu/wp-content/uploads/2024/05/HAI_AI-Index-Report-2024.pdf",
    date: "2024",
  },
  {
    id: "risk-005",
    institution: "European Parliament",
    report: "The EU AI Act",
    reportLink:
      "https://www.europarl.europa.eu/topics/en/article/20230601STO93804/eu-ai-act-first-regulation-on-artificial-intelligence",
    date: "Feb 2025",
  },
  {
    id: "risk-006",
    institution: "European Best Practices for AI Governance",
    report: "Implementing AI Governance: from Framework to Practice",
    reportLink:
      "https://futurium.ec.europa.eu/en/european-ai-alliance/best-practices/implementing-ai-governance-framework-practice",
    date: "Jul 2023",
  },
  {
    id: "risk-007",
    institution: "Centre for Emerging Technology and Security Briefing",
    report: "Strengthening Resilience to AI Risk",
    reportLink: "https://cetas.turing.ac.uk/sites/default/files/2023-08/cetas-cltr_ai_risk_briefing_paper.pdf",
    date: "Aug 2023",
  },
  {
    id: "risk-008",
    institution: "IAPP–EY 2023 Survey on Organizational AI Governance",
    report: "IAPP-EY Professionalizing Organizational AI Governance Report",
    reportLink:
      "https://iapp.org/media/pdf/resource_center/iapp_ey_professionalizing_organizational_ai_governance_report_executive_summary.pdf",
    date: "Dec 2023",
  },
  {
    id: "risk-009",
    institution: "ANS",
    report: "Study of UK SMBs",
    reportLink:
      "https://wp.ans.co.uk/l/909202/2024-03-14/c4wgj4/909202/1710419434pJajeBkT/ANS_Business_Blockers_Report_March_2024_SMB.pdf",
    date: null,
  },
  {
    id: "risk-010",
    institution: "NIST AI Risk Management Framework",
    report: "Artificial Intelligence Risk Management Framework (AI RMF 1.0)",
    reportLink: "https://nvlpubs.nist.gov/nistpubs/ai/NIST.AI.100-1.pdf",
    date: "Jan 2023",
  },
  {
    id: "risk-011",
    institution: "Singapore Model AI Governance Framework",
    report: "Model Artificial Intelligence Governance Framework Second Edition",
    reportLink:
      "https://www.pdpc.gov.sg/-/media/Files/PDPC/PDF-Files/Resource-for-Organisation/AI/SGModelAIGovFramework2.pdf",
    date: "Jan 2021",
  },
  {
    id: "risk-012",
    institution: "Epoch AI",
    report: "Machine Learning Trends",
    reportLink: "https://epochai.org/trends",
    date: "2023",
  },
  {
    id: "risk-013",
    institution: "AIPI",
    report: "New Analysis Shows Over 20% of US Jobs Significantly Exposed to AI Automation In the Near Future",
    reportLink: "https://theaipi.org/ai-interactive-map/",
    date: null,
  },
  {
    id: "risk-014",
    institution: "World Economic Forum",
    report: "AI Governance Alliance Briefing Paper Series 2024",
    reportLink: "https://www3.weforum.org/docs/WEF_AI_Governance_Alliance_Briefing_Paper_Series_2024.pdf",
    date: "Jan 2024",
  },
  {
    id: "risk-015",
    institution: "Stanford University",
    report: "Privacy in an AI Era: How Do We Protect Our Personal Information?",
    reportLink: "https://hai.stanford.edu/news/privacy-ai-era-how-do-we-protect-our-personal-information",
    date: "Mar 2024",
  },
  {
    id: "risk-016",
    institution: "UK Interim Report on the Safety of Advanced AI",
    report: "International Scientific Report on the Safety of Advanced AI",
    reportLink:
      "https://assets.publishing.service.gov.uk/media/6655982fdc15efdddf1a842f/international_scientific_report_on_the_safety_of_advanced_ai_interim_report.pdf",
    date: "May 2024",
  },
  {
    id: "risk-017",
    institution: "Cornell University",
    report: "HarmBench: A Standardized Evaluation Framework for Automated Red Teaming and Robust Refusal",
    reportLink: "https://arxiv.org/abs/2402.04249",
    date: "Feb 2024",
  },
  {
    id: "risk-018",
    institution: "DataRobot",
    report: "2022 State of AI Bias Report",
    reportLink:
      "https://www.datarobot.com/newsroom/press/datarobots-state-of-ai-bias-report-reveals-81-of-technology-leaders-want-government-regulation-of-ai-bias/",
    date: "Jan 2022",
  },
  {
    id: "risk-019",
    institution: "McKinsey",
    report: "The State of AI in Early 2024",
    reportLink:
      "https://www.mckinsey.com/~/media/mckinsey/business%20functions/quantumblack/our%20insights/the%20state%20of%20ai/2024/the-state-of-ai-in-early-2024-final.pdf",
    date: "May 2024",
  },
  {
    id: "risk-020",
    institution: "IDC",
    report: "2022 AI StrategiesView Survey",
    reportLink: "https://www.ibm.com/downloads/documents/us-en/10c31775a1540291",
    date: "Sep 2023",
  },
  {
    id: "risk-021",
    institution: "PCMag News",
    report: "Samsung Bans ChatGPT After Engineers Use it to Fix Proprietary Code",
    reportLink: "https://www.pcmag.com/news/samsung-bans-chatgpt-after-engineers-use-it-to-fix-proprietary-code",
    date: "May 2023",
  },
  {
    id: "risk-022",
    institution: "Business Insider",
    report:
      "Leaked Walmart memo warns employees not to share 'any information about Walmart's business' with ChatGPT or other AI bots",
    reportLink:
      "https://www.businessinsider.com/walmart-warns-workers-dont-share-sensitive-information-chatgpt-generative-ai-2023-2",
    date: "Mar 2023",
  },
  {
    id: "risk-023",
    institution: "Retool",
    report: "2024 State of AI in Production Survey",
    reportLink:
      "https://www.mongodb.com/resources/solutions/use-cases/retool-2024-state-of-ai-in-production?tck=retool_report_blog",
    date: "Jun 2024",
  },
  {
    id: "risk-024",
    institution: "Responsible AI Collaborative",
    report: "AI Incident Database",
    reportLink: "https://incidentdatabase.ai/",
    date: null,
  },
  {
    id: "risk-025",
    institution: "OECD",
    report: "AI Incident Monitoring Database",
    reportLink: "https://web.archive.org/web/20241011020236/https://oecd.ai/en/incidents",
    date: null,
  },
  {
    id: "risk-026",
    institution: "Google DeepMind",
    report: "Generative AI Misuse: A Taxonomy of Tactics and Insights from Real-World Data",
    reportLink: "https://arxiv.org/pdf/2406.13843",
    date: "Jun 2024",
  },
  {
    id: "risk-027",
    institution: "KPMG",
    report: "KPMG Generative AI Survey",
    reportLink: "https://kpmg.com/kpmg-us/content/dam/kpmg/pdf/2023/kpmg-gen-ai-survey2023.pdf",
    date: "Aug 2023",
  },
  {
    id: "risk-028",
    institution: "Stanford",
    report: "AI Index Report 2023",
    reportLink: "https://aiindex.stanford.edu/wp-content/uploads/2023/04/HAI_AI-Index-Report_2023.pdf",
    date: "2023",
  },
  {
    id: "risk-029",
    institution: "Responsible AI Collaborative",
    report: "Incident Summaries",
    reportLink: "https://incidentdatabase.ai/summaries/wordcounts/",
    date: null,
  },
  {
    id: "risk-030",
    institution: "McKinsey",
    report: "The State of AI in 2022 and a Half Decade in Review",
    reportLink:
      "https://www.mckinsey.com/capabilities/quantumblack/our-insights/the-state-of-ai-in-2022-and-a-half-decade-in-review",
    date: "Dec 2022",
  },
  {
    id: "risk-031",
    institution: "KPMG Gen AI Survey 2023 March",
    report: "Generative AI: From buzz to business value",
    reportLink: "https://kpmg.com/kpmg-us/content/dam/kpmg/pdf/2023/generative-ai-survey.pdf",
    date: "May 2023",
  },
  {
    id: "risk-032",
    institution: "Boston Consulting Group",
    report: "Responsible AI Belongs on the CEO Agenda",
    reportLink: "https://www.bcg.com/publications/2023/ceo-agenda-must-include-responsible-use-of-ai",
    date: "Mar 2023",
  },
  {
    id: "risk-033",
    institution: "IBM",
    report: "CEO’s Guide to Generative AI",
    reportLink: "https://www.ibm.com/downloads/cas/6JAM3VB7",
    date: null,
  },
  {
    id: "risk-034",
    institution: "IBM",
    report:
      "Data Suggests Growth in Enterprise Adoption of AI is Due to Widespread Deployment by Early Adopters, But Barriers Keep 40% in the Exploration and Experimentation Phases",
    reportLink:
      "https://newsroom.ibm.com/2024-01-10-Data-Suggests-Growth-in-Enterprise-Adoption-of-AI-is-Due-to-Widespread-Deployment-by-Early-Adopters?sf185480910=1",
    date: "Jan 2024",
  },
  {
    id: "risk-035",
    institution: "TechBetter Report on Public AI Governance Disclosures",
    report: "Evaluating AI Governance: Insights from Public Disclosures",
    reportLink: "https://www.techbetter.ai/_files/ugd/f83391_539908448a6e428da80177b14717074c.pdf",
    date: null,
  },
  {
    id: "gov-001",
    institution: "IBM",
    report: "What is AI governance?",
    reportLink: "https://www.ibm.com/topics/ai-governance",
    date: "Oct 2024",
  },

  {
    id: "gov-002",
    institution: "Responsible AI",
    report: "Navigating Organizational AI Governance",
    reportLink: "https://www.responsible.ai/navigating-organizational-ai-governance/",
    date: "Nov 2023",
  },

  {
    id: "gov-003",
    institution: "IAPP",
    report: "AI governance:  What is being governed?",
    reportLink: "https://iapp.org/news/a/ai-governance-what-is-being-governed",
    date: "Oct 2023",
  },

  {
    id: "gov-004",
    institution: "IDC",
    report: "Why AI Governance Is a Business Imperative for Scaling Enterprise Artificial Intelligence",
    reportLink: "https://www.ibm.com/downloads/cas/KXVRM5QE",
    date: "Sep 2023",
  },

  {
    id: "gov-005",
    institution: "EU AI Alliance",
    report: "Implementing an AI Governance Framework",
    reportLink:
      "https://futurium.ec.europa.eu/en/european-ai-alliance/best-practices/implementing-ai-governance-framework-practice",
    date: "July 2023",
  },

  {
    id: "gov-006",
    institution: "IAPP",
    report: "Key Terms for AI Governance",
    reportLink: "https://iapp.org/resources/article/key-terms-for-ai-governance/",
    date: "July 2024",
  },
  {
    id: "gov-007",
    institution: "Gartner",
    report: "Reprint on AI Governance",
    reportLink: "https://www.gartner.com/doc/reprints?id=1-2F2UJPZR&ct=230922&st=sb",
    date: null,
  },
  {
    id: "gov-008",
    institution: "Responsible AI",
    report: "Responsible.ai Homepage",
    reportLink: "https://www.responsible.ai/",
    date: null,
  },
  {
    id: "gov-009",
    institution: "European Union",
    report:
      "Laying Down Harmonised Rules On Artificial Intelligence (Artificial Intelligence Act) And Amending Certain Union Legislative Acts",
    reportLink: "https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex:52021PC0206",
    date: "Apr 2021",
  },
  {
    id: "gov-010",
    institution: "Anthropic",
    report: "Responsible Scaling Policy",
    reportLink:
      "https://assets.anthropic.com/m/24a47b00f10301cd/original/Anthropic-Responsible-Scaling-Policy-2024-10-15.pdf",
    date: "Oct 2024",
  },
  {
    id: "gov-011",
    institution: "S&P Global",
    report: "The AI Governance Challenge",
    reportLink: "https://www.spglobal.com/en/research-insights/special-reports/the-ai-governance-challenge",
    date: "Nov 2023",
  },
  {
    id: "gov-012",
    institution: "BCG",
    report: "A Guide to AI Governance for Business Leaders",
    reportLink: "https://www.bcg.com/publications/2023/a-guide-to-mitigating-ai-risks",
    date: "Nov 2023",
  },
  {
    id: "gov-013",
    institution: "Microsoft",
    report: "Microsoft Responsible AI Standard, v2",
    reportLink:
      "https://cdn-dynmedia-1.microsoft.com/is/content/microsoftcorp/microsoft/final/en-us/microsoft-brand/documents/Microsoft-Responsible-AI-Standard-General-Requirements.pdf",
    date: "June 2022",
  },
  {
    id: "gov-014",
    institution: "AWS",
    report: "AWS Cloud Adoption Framework for Artificial Intelligence, Machine Learning, and Generative AI",
    reportLink:
      "https://docs.aws.amazon.com/pdfs/whitepapers/latest/aws-caf-for-ai/aws-caf-for-ai.pdf#governance-perspective-managing-an-aiml-driven-organization",
    date: "Feb 2024",
  },
  {
    id: "gov-015",
    institution: "Google",
    report: "Secure AI Framework Approach",
    reportLink: "https://safety.google/intl/en_us/cybersecurity-advancements/saif/",
    date: "June 2023",
  },
  {
    id: "gov-016",
    institution: "IAPP-FTI",
    report: "AI Governance in Practice Report 2024",
    reportLink: "https://iapp.org/media/pdf/resource_center/ai_governance_in_practice_report_2024.pdf",
    date: "June 2024",
  },
  {
    id: "gov-017",
    institution: "Australian Government",
    report: "Australia's Artificial Intelligence Ethics Framework",
    reportLink:
      "https://www.industry.gov.au/publications/australias-artificial-intelligence-ethics-framework/testing-ai-ethics-principles",
    date: "July 2021",
  },
  {
    id: "gov-018",
    institution: "Convergence Analysis",
    report: "2024 State of the AI Regulatory Landscape",
    reportLink: "https://drive.google.com/file/d/13gyYbBixU75QwFQDTku0AMIovbeTp9_g/view",
    date: "May 2024",
  },
  {
    id: "gov-019",
    institution: "PDPC Singapore Government",
    report: "Model Artificial Intelligence Governance Framework",
    reportLink:
      "https://www.pdpc.gov.sg/-/media/Files/PDPC/PDF-Files/Resource-for-Organisation/AI/SGModelAIGovFramework2.pdf",
    date: "Jan 2019",
  },
  {
    id: "gov-020",
    institution: "IAPP-EY",
    report: "Professionalizing Organizational AI Governance Report",
    reportLink:
      "https://iapp.org/media/pdf/resource_center/iapp_ey_professionalizing_organizational_ai_governance_report.pdf?referrer=content_seehereview",
    date: "Dec 2023",
  },
  {
    id: "gov-021",
    institution: "OECD",
    report: "Tools for Trustworthy AI",
    reportLink:
      "https://www.oecd-ilibrary.org/docserver/008232ec-en.pdf?expires=1730238317&id=id&accname=guest&checksum=BEB1A6DA71952C260493D2FE17BB1F81",
    date: "June 2021",
  },

  {
    id: "gov-022",
    institution: "University of Turku",
    report: "The AI Governance Lifecycle",
    reportLink: "https://ai-governance.eu/ai-governance-framework/the-ai-governance-lifecycle/",
    date: "2022",
  },

  {
    id: "gov-023",
    institution: "NIST",
    report: "AI Risk Management Framework",
    reportLink: "https://nvlpubs.nist.gov/nistpubs/ai/NIST.AI.100-1.pdf",
    date: "Jan 2023",
  },
  {
    id: "gov-024",
    institution: "TDWI",
    report: "The State of AI Readiness 2024",
    reportLink: "https://tdwi.org/Research/2024/07/ADV-ALL-2024-State-of-AI-Readiness/Asset.aspx?tc=assetpg",
    date: "Jul 2024",
  },
  {
    id: "gov-025",
    institution: "IBM",
    report: "IBM AI Governance Download (Alternate)",
    reportLink: "https://www.ibm.com/downloads/cas/6JAM3VB7",
    date: "Oct 2023",
  },

  {
    id: "gov-026",
    institution: "BCG",
    report: "AI Responsibility at Crossroads",
    reportLink: "https://www.bcg.com/publications/2023/ai-responsibility-at-crossroads",
    date: "June 2023",
  },

  {
    id: "gov-027",
    institution: "Robert Seiner",
    report: "AI Governance vs. Data",
    reportLink: "https://www.linkedin.com/pulse/ai-governance-vs-data-robert-s-seiner-duiqc/",
    date: "May 2024",
  },

  {
    id: "gov-028",
    institution: "McKinsey & Co.",
    report: "The State of AI in Early 2024",
    reportLink:
      "https://www.mckinsey.com/capabilities/quantumblack/our-insights/the-state-of-ai#/download/%2F~%2Fmedia%2Fmckinsey%2Fbusiness%20functions%2Fquantumblack%2Four%20insights%2Fthe%20state%20of%20ai%2F2024%2Fthe-state-of-ai-in-early-2024-final.pdf",
    date: "May 2024",
  },

  {
    id: "gov-029",
    institution: "World Economic Forum",
    report: "Responsible AI Playbook for Investors 2024",
    reportLink: "https://www3.weforum.org/docs/WEF_Responsible_AI_Playbook_for_Investors_2024.pdf",
    date: "2024",
  },

  {
    id: "gov-030",
    institution: "University of Notre Dame and IBM",
    report: "The Return on Investment in AI Ethics: A Holistic Framework",
    reportLink: "https://arxiv.org/pdf/2309.13057",
    date: "2024",
  },

  {
    id: "gov-031",
    institution: "BCG",
    report: "To Be a Responsible AI Leader, Focus on Being Responsible",
    reportLink:
      "https://web-assets.bcg.com/37/87/33f2ee9d4e2281e792472f4ec1bf/to-be-a-responsible-ai-leader-focus-on-being-responsible.pdf",
    date: "Sep 2022",
  },

  {
    id: "gov-032",
    institution: "Gartner",
    report: "AI Governance Frameworks For Responsible AI",
    reportLink: "https://www.gartner.com/peer-community/oneminuteinsights/ai-governance-frameworks-responsible-ai-33q",
    date: "2023",
  },

  {
    id: "gov-033",
    institution: "KPMG and University of Queensland",
    report: "Trust in Artificial Intelligence",
    reportLink: "https://assets.kpmg.com/content/dam/kpmg/au/pdf/2023/trust-in-ai-global-insights-2023.pdf",
    date: "2023",
  },

  {
    id: "gov-034",
    institution: "IBM Institute for Business Value",
    report: "6 hard truths CEOs must face",
    reportLink: "https://www.ibm.com/downloads/cas/QJ2BYLZG",
    date: "May 2024",
  },

  {
    id: "gov-035",
    institution: "TechBetter",
    report: "Evaluating AI Governance:  Insights from Public Disclosures",
    reportLink: "https://www.techbetter.ai/_files/ugd/f83391_539908448a6e428da80177b14717074c.pdf",
    date: "2023",
  },

  {
    id: "gov-036",
    institution: "IBM",
    report:
      "Data Suggests Growth in Enterprise Adoption of AI is Due to Widespread Deployment by Early Adopters, But Barriers Keep 40% in the Exploration and Experimentation Phases",
    reportLink:
      "https://newsroom.ibm.com/2024-01-10-Data-Suggests-Growth-in-Enterprise-Adoption-of-AI-is-Due-to-Widespread-Deployment-by-Early-Adopters?sf185480910=1",
    date: "Jan 2024",
  },

  {
    id: "gov-037",
    institution: "Salesforce",
    report: "Businesses Adopting AI Risk a ‘Trust Gap’ with Customers – Salesforce Report",
    reportLink: "https://www.salesforce.com/news/stories/customer-engagement-research-2023/",
    date: "Aug 2023",
  },

  {
    id: "gov-038",
    institution: "BCG",
    report: "Responsible AI Belongs on the CEO Agenda",
    reportLink: "https://www.bcg.com/publications/2023/ceo-agenda-must-include-responsible-use-of-ai",
    date: "March 2023",
  },

  {
    id: "gov-039",
    institution: "Gartner",
    report: "Tackling Trust, Risk and Security in AI Models",
    reportLink: "https://www.gartner.com/en/articles/what-it-takes-to-make-ai-safe-and-effective",
    date: "Sep 2023",
  },

  {
    id: "gov-040",
    institution: "Holistic AI",
    report: "Penalties of the EU AI Act:  The High Cost of Non-Compliance",
    reportLink: "https://www.holisticai.com/blog/penalties-of-the-eu-ai-act",
    date: "Jan 2025",
  },

  {
    id: "gov-041",
    institution: "OECD",
    report: "National AI policies & strategies",
    reportLink: "https://oecd.ai/en/dashboards/overview/policy",
    date: null,
  },

  {
    id: "gov-042",
    institution: "OECD",
    report: "Emerging AI-related regulation",
    reportLink: "https://oecd.ai/en/dashboards/policy-instruments/Emerging_technology_regulation",
    date: null,
  },

  {
    id: "gov-043",
    institution: "IBM",
    report: "Ethics can’t be delegated",
    reportLink: "https://www.ibm.com/downloads/documents/us-en/10a99803f9afdac2",
    date: "Oct 2023",
  },

  {
    id: "gov-044",
    institution: "Stanford University Human-Centred Artificial Intelligence",
    report: "Artificial Intelligence Index Report 2024",
    reportLink: "https://aiindex.stanford.edu/wp-content/uploads/2024/05/HAI_AI-Index-Report-2024.pdf",
    date: "2024",
  },

  {
    id: "gov-045",
    institution: "IDC-IBM",
    report: "IDC MarketScape Worldwide AI Governance Platforms 2023 Vendor Assessment",
    reportLink: "https://idcdocserv.com/US50056923e_IBM",
    date: "2023",
  },

  {
    id: "gov-046",
    institution: "KPMG",
    report: "KPMG Generative AI Survey",
    reportLink: "https://kpmg.com/kpmg-us/content/dam/kpmg/pdf/2023/kpmg-gen-ai-survey2023.pdf",
    date: "Aug 2023",
  },

  {
    id: "gov-047",
    institution: "IAPP and FTI",
    report: "Privacy and AI Governance Report",
    reportLink: "https://iapp.org/resources/article/ai-governance-report-summary/",
    date: "Jan 2023",
  },

  {
    id: "gov-048",
    institution: "IAPP and EY",
    report: "Organizational AI Governance Report Executive Summary",
    reportLink:
      "https://iapp.org/media/pdf/resource_center/iapp_ey_professionalizing_organizational_ai_governance_report_executive_summary.pdf",
    date: "Dec 2023",
  },
  {
    id: "frameworks-001",
    institution: "IAPP and FTI",
    report: "AI Governance in Practice Report 2024",
    reportLink: "https://iapp.org/media/pdf/resource_center/ai_governance_in_practice_report_2024.pdf",
    date: "June 2024",
  },
  {
    id: "frameworks-002",
    institution: "NIST",
    report: "Towards a Standard for Identifying and Managing Bias in Artificial Intelligence",
    reportLink: "https://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.1270.pdf",
    date: "March 2022",
  },
  {
    id: "frameworks-003",
    institution: "NIST",
    report: "Artificial Intelligence Risk Management Framework (AI RMF 1.0)",
    reportLink: "https://nvlpubs.nist.gov/nistpubs/ai/NIST.AI.100-1.pdf",
    date: "Jan 2023",
  },
  {
    id: "frameworks-004",
    institution: "PDPC and IMDA Singapore",
    report: "Model Artificial Intelligence Governance Framework Second Edition",
    reportLink:
      "https://www.pdpc.gov.sg/-/media/Files/PDPC/PDF-Files/Resource-for-Organisation/AI/SGModelAIGovFramework2.pdf",
    date: "Jan 2020",
  },
  {
    id: "frameworks-005",
    institution: "AI Verify Foundation and IMDA Singapore",
    report: "Model AI Governance Framework for Generative AI",
    reportLink:
      "https://aiverifyfoundation.sg/wp-content/uploads/2024/06/Model-AI-Governance-Framework-for-Generative-AI-19-June-2024.pdf",
    date: "May 2024",
  },
  {
    id: "frameworks-006",
    institution: "IAPP and EY",
    report: "IAPP-EY Professionalizing Organizational AI Governance Report",
    reportLink:
      "https://iapp.org/media/pdf/resource_center/iapp_ey_professionalizing_organizational_ai_governance_report.pdf",
    date: "Dec 2023",
  },
  {
    id: "frameworks-007",
    institution: "OECD",
    report: "Tools for Trustworthy AI",
    reportLink: "https://www.oecd-ilibrary.org/docserver/008232ec-en.pdf",
    date: "June 2021",
  },
  {
    id: "frameworks-008",
    institution: "OECD",
    report: "OECD Framework for the Classification of AI Systems",
    reportLink:
      "https://www.oecd.org/en/publications/oecd-framework-for-the-classification-of-ai-systems_cb6d9eca-en.html",
    date: "Feb 2022",
  },
  {
    id: "frameworks-009",
    institution: "OECD",
    report: "OECD AI Principles overview",
    reportLink: "https://oecd.ai/en/ai-principles",
    date: "May 2019",
  },
  {
    id: "frameworks-010",
    institution: "OECD",
    report: "Science, Technology and Innovation",
    reportLink: "https://www.oecd-ilibrary.org/science-and-technology/ai-data-gov",
    date: null,
  },
  {
    id: "frameworks-011",
    institution: "OECD",
    report: "Using AI in the Workplace",
    reportLink: "https://www.oecd-ilibrary.org/docserver/73d417f9-en.pdf",
    date: "Mar 2024",
  },
  {
    id: "frameworks-012",
    institution: "NIST",
    report: "AI RMF Playbook",
    reportLink: "https://airc.nist.gov/AI_RMF_Knowledge_Base/Playbook",
    date: "Jan 2023",
  },
  {
    id: "frameworks-013",
    institution: "NIST",
    report: "Artificial Intelligence Risk Management Framework: Generative Artificial Intelligence Profile",
    reportLink: "https://nvlpubs.nist.gov/nistpubs/ai/NIST.AI.600-1.pdf",
    date: "July 2024",
  },
  {
    id: "frameworks-014",
    institution: "NIST",
    report: "The NIST Cybersecurity Framework (CSF) 2.0",
    reportLink: "https://nvlpubs.nist.gov/nistpubs/CSWP/NIST.CSWP.29.pdf",
    date: "Feb 2024",
  },
  {
    id: "frameworks-015",
    institution: "World Economic Forum, PDPC Singapore, and IMDA Singapore",
    report: "Companion to the Model AI Governance Framework",
    reportLink: "https://www.pdpc.gov.sg/-/media/Files/PDPC/PDF-Files/Resource-for-Organisation/AI/SGIsago.pdf",
    date: "Jan 2020",
  },
  {
    id: "frameworks-016",
    institution: "AI Verify",
    report: "Binary Classification Model for Credit Risk",
    reportLink: "https://aiverifyfoundation.sg/downloads/AI_Verify_Sample_Report.pdf",
    date: "June 2023",
  },
  {
    id: "frameworks-017",
    institution: "The Alan Turing Institute",
    report: "Human Rights, Democracy, and the Rule of Law Assurance Framework for AI Systems",
    reportLink: "https://arxiv.org/pdf/2202.02776",
    date: "Sep 2021",
  },
  {
    id: "frameworks-018",
    institution: "The Alan Turing Institute",
    report: "Human Rights by Design",
    reportLink: "https://rm.coe.int/0900001680ab2279",
    date: "May 2023",
  },
  {
    id: "frameworks-019",
    institution: "Council of Europe",
    report: "Ad hoc Committee on Artificial Intelligence (CAHAI)",
    reportLink: "https://rm.coe.int/0900001680a1293a",
    date: "Mar 2021",
  },
  {
    id: "frameworks-020",
    institution: "Microsoft",
    report: "Microsoft Responsible AI Standard, v2",
    reportLink:
      "https://cdn-dynmedia-1.microsoft.com/is/content/microsoftcorp/microsoft/final/en-us/microsoft-brand/documents/Microsoft-Responsible-AI-Standard-General-Requirements.pdf",
    date: "June 2022",
  },
  {
    id: "frameworks-021",
    institution: "Microsoft",
    report: "Microsoft Responsible AI Impact Assessment Guide",
    reportLink: "https://query.prod.cms.rt.microsoft.com/cms/api/am/binary/RE4ZzOI",
    date: "June 2022",
  },
  {
    id: "frameworks-022",
    institution: "Microsoft",
    report: "2024 Responsible AI Transparency Report",
    reportLink:
      "https://www.microsoft.com/en-us/corporate-responsibility/responsible-ai-transparency-reporthttps://cdn-dynmedia-1.microsoft.com/is/content/microsoftcorp/microsoft/msc/documents/presentations/CSR/Responsible-AI-Transparency-Report-2024.pdf",
    date: "May 2024",
  },
  {
    id: "frameworks-023",
    institution: "Microsoft",
    report: "Microsoft’s AI Safety Policies",
    reportLink: "https://blogs.microsoft.com/on-the-issues/2023/10/26/microsofts-ai-safety-policies/",
    date: "Oct 2023",
  },
  {
    id: "frameworks-024",
    institution: "AWS",
    report: "AWS Cloud Adoption Framework for Artificial Intelligence, Machine Learning, and Generative AI",
    reportLink: "https://docs.aws.amazon.com/pdfs/whitepapers/latest/aws-caf-for-ai/aws-caf-for-ai.pdf",
    date: "Feb 2024",
  },
  {
    id: "frameworks-025",
    institution: "AWS",
    report: "Machine Learning Lens",
    reportLink:
      "https://docs.aws.amazon.com/pdfs/wellarchitected/latest/machine-learning-lens/wellarchitected-machine-learning-lens.pdf",
    date: "July 2023",
  },
  {
    id: "frameworks-026",
    institution: "AWS",
    report: "Responsible Use of Machine Learning",
    reportLink: "https://d1.awsstatic.com/responsible-machine-learning/AWS_Responsible_Use_of_ML_Whitepaper_1.2.pdf",
    date: "June 2023",
  },
  {
    id: "frameworks-027",
    institution: "AWS",
    report: "AI Safety Summit - Enhancing Frontier AI Safety",
    reportLink: "https://aws.amazon.com/uki/cloud-services/uk-gov-ai-safety-summit/",
    date: "Oct 2023",
  },
  {
    id: "frameworks-028",
    institution: "Google",
    report: "Google Secure AI Framework Approach",
    reportLink: "https://services.google.com/fh/files/blogs/google_secure_ai_framework_approach.pdf",
    date: "June 2023",
  },
  {
    id: "frameworks-029",
    institution: "Google",
    report: "Our Principles",
    reportLink: "https://ai.google/responsibility/responsible-ai-practices/",
    date: null,
  },
  {
    id: "frameworks-030",
    institution: "DeepMind",
    report: "DeepMind AI Summit Policies",
    reportLink: "https://deepmind.google/public-policy/ai-summit-policies/",
    date: "Oct 2023",
  },
  {
    id: "frameworks-031",
    institution: "DAMA",
    report: "DAMA DMBOK",
    reportLink: "http://amzn.to/32oK8hH",
    date: "July 2017",
  },
];

export default aigfcSources;
