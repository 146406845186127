import { useState } from "react";
import { Link } from "react-router-dom";

import BookCard from "../components/blog/BookCard";
import Advice from "../components/blog/Advice";
import FundamentalsAd from "../components/blog/FundamentalsAd";
import CDMPLevelsModal from "../components/CDMPLevelsModal";
import Post from "../components/blog/Post";
import PostImage from "../components/blog/PostImage";
import CustomModal from "../components/CustomModal";

import { useModal } from "../helpers/utils";

import levels from "../img/cdmp/levels.webp";

import books from "../data/books";

const POSTNAME = "specialist";

const Specialist: React.FC = () => {
  return <Post postName={POSTNAME} rest={<Rest />} />;
};

export default Specialist;

const Rest = () => {
  const [showCDMPModal, setShowCDMPModal] = useState(false);
  const { showModal, image, alt, handleShowClick } = useModal();

  return (
    <>
      {showCDMPModal && <CDMPLevelsModal setShowModal={setShowCDMPModal} />}
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}
      <PostImage postName={POSTNAME} />
      <p>
        The CDMP Specialist Exams offer data practitioners the opportunity to advance their credentials and knowledge of
        specific Data Strategy knowledge areas.{" "}
        <a href="https://dama.org/" target="_blank" rel="noreferrer">
          DAMA International
        </a>{" "}
        offers seven options for Specialist Exams, of which aspiring CDMP Practitioners and Masters will pick two.
      </p>
      <ul style={{ marginTop: "1em" }}>
        <li>
          <a href="#dg">Data Governance</a>
        </li>
        <li>
          <a href="#dq">Data Quality</a>
        </li>
        <li>
          <a href="#dmd">Data Modeling & Design</a>
        </li>
        <li>
          <a href="#mm">Metadata Management</a>
        </li>
        <li>
          <a href="#mdm">Master & Reference Data Management</a>
        </li>
        <li>
          <a href="#dii">Data Integration & Interoperability</a>
        </li>
        <li>
          <a href="#dwbi">Data Warehousing & Business Intelligence</a>
        </li>
      </ul>
      <p>
        After getting a 70%+ on the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals Exam
        </Link>
        , the{" "}
        <span className="fake-link-1" onClick={() => setShowCDMPModal(true)}>
          CDMP Practitioner
        </span>{" "}
        requires that you pass two Specialist Exams with a score of <strong>70%+</strong>.
      </p>
      <p>
        To attain recognition at the{" "}
        <span className="fake-link-1" onClick={() => setShowCDMPModal(true)}>
          CDMP Master
        </span>{" "}
        level, you must pass the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals Exam
        </Link>{" "}
        and two Specialist Exams with a score of <strong>80%+</strong>. You must also submit 10 years of relevant work
        experience for DAMA's review.
      </p>
      <p>
        All the CDMP exams are <strong>100 questions</strong> that you must answer in <strong>90 minutes</strong>. (The
        exception to this is if you purchase an English as a Second Language (ESL) version of the exams, in which case,
        you'll have 110 minutes.) DAMA specifies that you can only use <strong>one</strong> book while taking the exam,
        and we strongly recommend the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>
        . You can use a digital copy so long as it's on a second device that's not connected to the internet.
      </p>
      <p>
        It's important to note that while the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals Exam
        </Link>{" "}
        is essentially a critical reading test of how well you understood the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>{" "}
        and how quickly you can look up answers to more esoteric questions in the book, the{" "}
        <strong>Specialist Exams are quite different in that they incorporate scenario-based questions</strong> designed
        to test your experience and decision-making as a Data Management professional. Depending on the exam, you may
        also see somewhat esoteric questions that require outside knowledge.
      </p>{" "}
      <p>
        When it comes to the Specialist Exams, about <strong>85% of questions</strong> require outside knowledge from
        experience or{" "}
        <a href="https://www.dama.org/cpages/books-referenced-in-dama-dmbok" target="_blank" rel="noreferrer">
          works cited in the <em>DMBOK</em>
        </a>
        . Accordingly, only about <strong>15% of questions</strong> can be looked up in the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>
        . You may see questions from the official practice test for the CDMP Fundamentals Exam, so it's worth giving the
        relevant sample questions a review as well, and perhaps documenting them in your one book for easy reference.
      </p>{" "}
      <p>
        Given the challenge presented by these exams, Data Strategy Professionals provides very thorough{" "}
        <Link to="/products/specialist-guides">Specialist Guides</Link> to help you prepare.
      </p>
      <p>
        Each{" "}
        <Link to="/products/specialist-guides" target="_blank">
          Specialist Guide
        </Link>{" "}
        includes the following:
      </p>
      <ul>
        <li>Introduction to the CDMP Specialist Exams</li>
        <li>Topic Summary</li>
        <li>Practice Questions & Answers</li>
        <li>Further Reading</li>
        <li>Real World Examples</li>
        <li>Tips from CDMP Study Group</li>
        <li>Next Steps</li>
      </ul>
      <p>
        We also offer{" "}
        <Link to="/products/specialist-question-sets" target="_blank">
          Specialist Question Sets
        </Link>{" "}
        (50 practice questions each), or you can pick up{" "}
        <strong>two Specialist Guides and two Specialist Question Sets</strong> through the{" "}
        <Link to="/products/specialist-bundle" target="_blank">
          Specialist Bundle
        </Link>
        .
      </p>
      <p>
        In addition to reading (<span style={{ fontStyle: "italic" }}>or very thoroughly skimming</span>) the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>{" "}
        before you take the Specialist Exams, you may choose to study <strong>additional reading materials</strong>{" "}
        covered in this article. These books will help you on the scenario-based questions on the test, as well as other
        questions that may not rely fully on the content of the{" "}
        <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>
        . For in depth summaries of some of the books featured here, check out{" "}
        <a
          href="https://medium.com/@nicolejaneway/15-data-strategy-books-6aef2a8b3472"
          target="_blank"
          rel="noreferrer"
        >
          Top 15 Books to Master Data Strategy
        </a>
        . You may also enjoy the articles{" "}
        <a
          href="https://nicolejaneway.medium.com/12-books-expand-worldview-ea1fe6d37c54"
          target="_blank"
          rel="noreferrer"
        >
          12 Books to Expand Your Worldview as a Data Professional
        </a>{" "}
        and{" "}
        <a
          href="https://nicolejaneway.medium.com/13-books-to-master-data-analytics-a1f5ceb59480"
          target="_blank"
          rel="noreferrer"
        >
          13 Books to Master Data Analytics
        </a>
        .
      </p>
      <figure>
        <img src={levels} alt="CDMP certification levels" onClick={handleShowClick} style={{ maxWidth: "100%" }} />
        <figcaption>
          via{" "}
          <a href="https://cdmp.info/about/" target="_blank" rel="noreferrer">
            CDMP
          </a>
        </figcaption>
      </figure>
      <h2 id="dg">Data Governance</h2>
      <p>
        This is a fairly popular Specialist Exam among{" "}
        <a href="https://www.facebook.com/groups/346145433213551/" target="_blank" rel="noreferrer">
          CDMP Study Group
        </a>{" "}
        members. Of course, Data Governance lays the foundation for all Data Management work to follow. You'll need to
        read quickly to be successful on this exam, as the scenario-based questions are particularly wordy. To prepare,
        we recommend the following resources:
      </p>
      {books
        .filter((book) => book.topic === "Data Governance")
        .map((book) => (
          <BookCard bookObj={book} key={book.title} />
        ))}
      <Advice items={["specialist-000", "specialist-001", "specialist-011", "specialist-010"]} group="CDMP" />
      <h2 id="dq">Data Quality</h2>
      <p>
        Advance your understanding of this critical field by acing the Specialist Exam on Data Quality. To prepare for
        this one, we recommend the following resources:
      </p>
      {books
        .filter((book) => book.topic === "Data Quality")
        .map((book) => (
          <BookCard bookObj={book} key={book.title} />
        ))}
      <Advice items={["specialist-002", "specialist-009"]} group="CDMP" />
      <h2 id="dmd">Data Modeling & Design</h2>
      <p>
        For a major career boost as a Data Architect, consider making Data Modeling & Design one of your selections for
        the CDMP Specialist Exams. To prep, we recommend the following resources:
      </p>
      {books
        .filter((book) => book.topic === "Data Modelling & Design")
        .map((book) => (
          <BookCard bookObj={book} key={book.title} />
        ))}
      <FundamentalsAd />
      <h2 id="mm">Metadata Management</h2>
      <p>
        A very popular choice among members of the{" "}
        <a href="https://www.facebook.com/groups/346145433213551/" target="_blank" rel="noreferrer">
          CDMP Study Group
        </a>
        . To prep for the Metadata Exam, we recommend the following resources:
      </p>
      {books
        .filter((book) => book.topic === "Metadata")
        .map((book) => (
          <BookCard bookObj={book} key={book.title} />
        ))}
      <Advice items={["specialist-003", "specialist-004"]} group="CDMP" />
      <h2 id="mdm">Master & Reference Data Management</h2>
      <p>
        A Specialist Exam that's great for data practitioners aspiring to leadership roles. To prep for the Master &
        Reference Data Management Exam, we recommend the following resources:
      </p>
      {books
        .filter((book) => book.topic === "Master & Reference Data Management")
        .map((book) => (
          <BookCard bookObj={book} key={book.title} />
        ))}
      <Advice items={["specialist-005"]} group="CDMP" />
      <h2 id="dii">Data Integration & Interoperability</h2>
      <p>
        A lesser studied topic, but perhaps it's the right fit for you? To prepare for the Data Integration &
        Interoperability exam, we recommend the following resources:
      </p>
      {books
        .filter((book) => book.topic === "Data Integration & Interoperability")
        .map((book) => (
          <BookCard bookObj={book} key={book.title} />
        ))}
      <Advice items={["specialist-006"]} group="CDMP" />
      <h2 id="dwbi">Data Warehousing & Business Intelligence</h2>
      <p>
        To wrap up this list, here are our recommendations to prepare for the Data Warehousing & Business Intelligence
        exam:
      </p>
      {books
        .filter((book) => book.topic === "Data Warehousing & Business Intelligence")
        .map((book) => (
          <BookCard bookObj={book} key={book.title} />
        ))}
      <Advice items={["specialist-007", "specialist-008"]} group="CDMP" />
      <h2>Conclusion</h2>
      <p>
        If you benefited from these book summaries, you may consider purchasing the{" "}
        <Link to="/products/specialist-guides" target="_blank">
          Specialist Guides
        </Link>{" "}
        that offer detailed insights for each of the books outlined here along with{" "}
        <strong>key concepts, best practices, and real world examples</strong> for every{" "}
        <a href="https://cdmp.info/exams/" target="_blank" rel="noreferrer">
          Specialist Exam
        </a>{" "}
        topic.
      </p>
      <p>
        As a reminder, you must pass the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals Exam
        </Link>{" "}
        with at least a 70% in order to be eligible for the{" "}
        <span className="fake-link-1" onClick={() => setShowCDMPModal(true)}>
          CDMP Practitioner and Master
        </span>{" "}
        badges. That said, it is possible to take any of the CDMP Specialist Exams on its own without first having taken
        the{" "}
        <Link to="/resources/cdmp-fundamentals-exam" target="_blank">
          CDMP Fundamentals Exam
        </Link>
        . If you pass, you will receive the Associate, Practitioner, or Master badge for that specific exam.
      </p>
      <p>
        Attaining a 70% on all three tests is required for recognition as a{" "}
        <span className="fake-link-1" onClick={() => setShowCDMPModal(true)}>
          CDMP Practitioner
        </span>
        . To attain the{" "}
        <span className="fake-link-1" onClick={() => setShowCDMPModal(true)}>
          CDMP Master
        </span>{" "}
        accreditation, you must pass all three exams with a score of at least 80%.
      </p>
      <p>
        Another distinction between these two certification levels is the amount of relevant experience required: DAMA
        recognizes{" "}
        <span className="fake-link-1" onClick={() => setShowCDMPModal(true)}>
          CDMP Practitioners
        </span>{" "}
        as anyone with <strong>2-10 years of Data Management experience</strong>. DAMA grants the{" "}
        <span className="fake-link-1" onClick={() => setShowCDMPModal(true)}>
          CDMP Master
        </span>{" "}
        to those with <strong>10+ years of experience</strong>. You can also pursue the{" "}
        <a href="https://www.dama.org/team/cdmp-fellows" target="_blank" rel="noreferrer">
          CDMP Fellow
        </a>{" "}
        through<strong> contributions to DAMA and the broader Data Management community</strong>.
      </p>
      <p>
        Finally, we'd love to hear from you! Please let us know your experience with the Specialist Exams by posting in
        the CDMP Study Group on{" "}
        <a href="https://www.facebook.com/groups/346145433213551/" target="_blank" rel="noreferrer">
          Facebook
        </a>{" "}
        or{" "}
        <a href="https://www.linkedin.com/groups/12965811/" target="_blank" rel="noreferrer">
          LinkedIn
        </a>
        .
      </p>
    </>
  );
};
