import { useState } from "react";
import { Collapse, IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const ExtendedDescription: React.FC<{ title: string; description: JSX.Element; color?: "string"; blog?: boolean }> = ({
  title = "Learn More",
  description,
  color = "white",
  blog = false,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      style={{
        background: color === "teal" ? "#e9eff1" : "#f9f9f9",
        border: "3px solid #efefef",
        padding: "8px 4px",
        marginBottom: 5,
        maxWidth: blog ? 1000 : "100%",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: 4, cursor: "pointer" }} onClick={() => setOpen(!open)}>
        <IconButton aria-label="expand" size="small">
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>{" "}
        <h4>{title}</h4>
      </div>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <div
          style={{
            width: "calc(100%-36px)",
            height: ".02m",
            marginLeft: 38,
            borderBottom: "2px solid #dbdbdb",
            textAlign: "center",
            position: "relative",
            zIndex: 0,
          }}
        />
        <div style={{ paddingLeft: 40, paddingTop: 4 }}>{description}</div>
      </Collapse>
    </div>
  );
};

export default ExtendedDescription;
