import { useState } from "react";
import { Link } from "react-router-dom";

import Carousel from "react-multi-carousel";

import scr0 from "../../img/products/cdmp-exam-readiness-check/scr0.webp";
import scr1 from "../../img/products/cdmp-exam-readiness-check/scr1.0.webp";
import scr11 from "../../img/products/cdmp-exam-readiness-check/scr1.1.webp";
import scr2 from "../../img/products/cdmp-exam-readiness-check/scr2.webp";
import scr3 from "../../img/products/cdmp-exam-readiness-check/scr3.webp";
import scr4 from "../../img/products/cdmp-exam-readiness-check/scr4.webp";
import scr5 from "../../img/products/cdmp-exam-readiness-check/scr5.webp";
import scr6 from "../../img/products/cdmp-exam-readiness-check/scr6.0.webp";
import scr7 from "../../img/products/cdmp-exam-readiness-check/scr7.webp";
import scr61 from "../../img/products/cdmp-exam-readiness-check/scr6.1_fade.webp";

import { responsive } from "../../helpers/carouselStyles";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";

import TestimonialCard from "../../components/testimonial-flip-card/TestimonialCard";
import CDMPLevelsModal from "../../components/CDMPLevelsModal";
import VisualDescription from "../../components/product-pages/VisualDescription";
import ExtendedDescription from "../../components/ExtendedDescription";

import testimonials from "../../data/testimonials";

const PRODUCT = "CDMP Exam Readiness Check";

export default function CDMPExamReadinessCheck() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && <CDMPLevelsModal setShowModal={setShowModal} />}
      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        description={description(setShowModal)}
        tealBox={tealBox}
        extendedDescription={extendedDescription(setShowModal)}
        rest={rest()}
      />
    </>
  );
}

const description = (setShowModal) => {
  return (
    <div className="gray-box" id="product-description">
      <h4>Description</h4>
      <VisualDescription product={PRODUCT} />
      <div className="blog">
        <p>
          Ensure you're ready to ace the{" "}
          <Link to="/resources/cdmp" target="_blank" rel="noreferrer">
            CDMP Fundamentals Exam
          </Link>{" "}
          with this two part online assessment:
        </p>
        <ul>
          <li>
            <strong>Part 1</strong> walks you through various exercises to help you{" "}
            <strong>identify potential areas of weakness</strong>.
          </li>
          <li>
            <strong>Part 2</strong> is a <strong>mock exam</strong> composed of 20 multiple choice questions in the
            style of the CDMP Fundamentals Exam. At the end, you'll receive a{" "}
            <span className="fake-link-1" onClick={() => setShowModal(true)}>
              projected score
            </span>
            , as well as a pdf that contains an explanation of each correct answer along with the reference chapter and
            section so you can review the relevant content in the{" "}
            <a href="http://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
              <em>DMBOK v2 Revised</em>
            </a>
            .
          </li>
        </ul>
        <p>
          <em>
            Note:&nbsp; This product is available as part of the{" "}
            <Link to="/products/cdmp-fundamentals-bundle" target="_blank">
              CDMP Fundamentals Bundle
            </Link>
            . Contents have been updated to reflect the latest material in the{" "}
            <a href="https://amzn.to/32oK8hH" target="_blank" rel="noreferrer" style={{ fontStyle: "normal" }}>
              DMBOK v2 Revised
            </a>
            .
          </em>
        </p>
      </div>
    </div>
  );
};

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials">
    <h4>
      Don't miss our other resources for the <Link to="/resources/cdmp-fundamentals-exam">CDMP</Link>
    </h4>
    <div className="blog">
      <p>
        Our <Link to="/products/cdmp-study-plan">Study Plan</Link> includes 90 days worth of emails to guide your
        reading of the{" "}
        <a href="http://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
          <em>DMBOK</em>
        </a>
        .
      </p>
      <p>
        Check out our <Link to="/products/cdmp-fundamentals-notes">Fundamentals Notes</Link> for 30+ pages of guidance
        on the 14 tested chapters.
      </p>
      <p>
        Practice with our <Link to="/products/cdmp-question-sets">Question Sets</Link>, which each contain 50
        never-before-seen practice questions idn the style of the test.
      </p>
      <p>
        Finally, you can get all our best selling study materials by purchasing the{" "}
        <Link to="/products/cdmp-fundamentals-bundle">CDMP Fundamentals Bundle</Link>.
      </p>
    </div>
  </div>
);

const extendedDescription = (setShowModal) => {
  return (
    <div className="extended-description-container">
      <ExtendedDescription
        description={
          <div className="extended-description">
            <p style={{ marginBottom: 0 }}>
              As a result of the <strong>CDMP Exam Readiness Check</strong>, you'll receive a projected score:
            </p>
            <ul>
              <li>Less than 60%: study more</li>
              <li>
                60-69%: ready to pass at the{" "}
                <span className="fake-link-1" onClick={() => setShowModal(true)}>
                  Associate level
                </span>
              </li>
              <li>
                70-79%: ready to pass at the{" "}
                <span className="fake-link-1" onClick={() => setShowModal(true)}>
                  Practitioner level
                </span>
              </li>
              <li>
                80% or greater: ready to pass at the{" "}
                <span className="fake-link-1" onClick={() => setShowModal(true)}>
                  Master level
                </span>
              </li>
            </ul>

            <br />
            <h4>Benefits</h4>
            <ul>
              <li>
                Delivered to your email as a link to the online portion of the assessment and a link to download the pdf
                containing the correct answers and explanations
              </li>
              <li>Part 1 contains exercises to assess your readiness for the test</li>
              <li>
                Part 2 features 20 new, never-before-seen practice questions developed by Data Strategy Professionals
                based on the{" "}
                <a href="http://amzn.to/32oK8hH" target="_blank" rel="noreferrer">
                  <em>DMBOK</em>
                </a>
              </li>
              <li>
                Support for any questions about the product at{" "}
                <a href="mailto:support@datastrategypros.com?subject=CDMP%20Exam%20Readiness%20Check">
                  support@datastrategypros.com
                </a>
              </li>
            </ul>
          </div>
        }
      />
    </div>
  );
};

const rest = () => {
  const images = [scr1, scr11, scr2, scr3, scr4, scr5, scr6, scr7, scr61, scr0];
  let testimonialsToShow = [testimonials.find((testimonial) => testimonial.id === "cdmp-exam-readiness-check-000")];

  return (
    <>
      <div className="white-box carousel-container">
        <h4 className="box-title" style={{ textAlign: "center" }}>
          Screenshots
        </h4>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          keyBoardControl={true}
          infinite={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          // @ts-ignore
          dotListclassName="custom-dot-list-style"
          itemclassName="carousel-item"
          shouldResetAutoplay={false}
        >
          {images.map((image) => (
            <img
              src={image}
              alt="screenshot"
              style={{
                objectFit: "contain",
              }}
              key={image}
            />
          ))}
        </Carousel>
      </div>

      <div className="teal-box" style={{ background: "#efefef", marginBottom: 40, paddingBottom: "2.5em" }}>
        <div className="center-text-box">
          <h4 className="box-title">What People are Saying</h4>
          {testimonialsToShow.map((testimonial) => (
            <div key={testimonial.id}>
              <TestimonialCard testimonial={testimonial} productPage={true} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
