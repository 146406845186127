import { Table, TableContainer, TableRow, TableCell } from "@mui/material";

import tableStyles from "../../../../components/tableStyles";
import { frameworksData, assessmentComparisonData, colors } from "./aigfcFrameworksData";

const TableHeader: React.FC<{
  tableNumber: string;
  tableTitle: "Best Practices" | "Risk Mitigation" | "Data Management";
  framework: AIGFCFrameworks;
  subtitle?: string | null;
  assessment?: boolean;
}> = ({ tableNumber, tableTitle, framework, subtitle, assessment = false }) => {
  return (
    <>
      <HeaderRow tableNumber={tableNumber} tableTitle={tableTitle} framework={framework} />
      {subtitle && <SubtitleRow subtitle={subtitle} />}
      {assessment && <AssessementRow />}
    </>
  );
};

const HeaderRow: React.FC<{ tableNumber: string; tableTitle: any; framework: AIGFCFrameworks }> = ({
  tableNumber,
  tableTitle,
  framework,
}) => {
  return (
    <TableRow style={{ ...tableStyles.centerText, ...tableStyles.tealBackground }}>
      <TableCell colSpan={3}>
        <strong>
          Table {tableNumber} – {tableTitle} ({framework})
        </strong>
      </TableCell>
    </TableRow>
  );
};

const SubtitleRow: React.FC<{ subtitle: string }> = ({ subtitle }) => {
  return (
    <TableRow style={{ ...tableStyles.centerText, ...tableStyles.lightTealBackground }}>
      <TableCell>
        <strong>{subtitle}</strong>
      </TableCell>
    </TableRow>
  );
};

const AssessementRow: React.FC = () => {
  return (
    <TableRow style={tableStyles.lightTealBackground}>
      <TableCell>
        <strong>Rating</strong>
      </TableCell>
      <TableCell>
        <strong>Criteria</strong>
      </TableCell>
      <TableCell>
        <strong>Reasoning</strong>
      </TableCell>
    </TableRow>
  );
};

const AssessmentBody: React.FC<{ rows: AIGFCTableRow[] }> = ({ rows }) => {
  return (
    <>
      {rows.map((row) =>
        row.criteria !== "Average score" ? (
          <TableRow>
            <TableCell
              style={{
                ...tableStyles.centerText,
                ...tableStyles.verticalAlign,
                background: colors[row.score],
                borderRight: row.score === 5 ? "1px solid rgba(224, 224, 224, 1)" : null,
                fontWeight: "bold",
              }}
            >
              {row.score}
            </TableCell>
            <TableCell style={{ ...tableStyles.verticalAlign, fontWeight: "bold" }}>{row.criteria}</TableCell>
            <TableCell>
              {typeof row?.details === "string" ? (
                <p dangerouslySetInnerHTML={{ __html: row.details }} />
              ) : (
                <ul>
                  {row?.details.map((detail) => (
                    <li>{detail}</li>
                  ))}
                </ul>
              )}
            </TableCell>
          </TableRow>
        ) : (
          <TableRow style={{ ...tableStyles.centerText, background: colors[row.score] }}>
            <TableCell colSpan={3}>
              <strong>Average score:&nbsp; {row.score}</strong>
            </TableCell>
          </TableRow>
        )
      )}
    </>
  );
};

const AssessmentTable: React.FC<{ framework: AIGFCFrameworks; tableNumber: string }> = ({ framework, tableNumber }) => {
  const table: AIGFCTable = frameworksData
    .find((f) => f.framework === framework)
    ?.tables.find((t) => t.tableNumber === tableNumber) as AIGFCTable | undefined;
  return (
    <TableWrapper>
      <TableHeader
        tableNumber={table.tableNumber}
        tableTitle={table.tableTitle as AIGFCTableTitle}
        framework={framework}
        assessment
      />
      <AssessmentBody rows={table.rows} />
    </TableWrapper>
  );
};

const TableWrapper: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div className="table">
      <TableContainer style={tableStyles.tableContainer}>
        <Table size="small">{children}</Table>
      </TableContainer>
    </div>
  );
};

const AssessmentComparisonTable: React.FC = () => {
  return (
    <TableWrapper>
      <TableRow style={{ ...tableStyles.centerText, ...tableStyles.tealBackground }}>
        <TableCell colSpan={7}>
          <strong>Table 4.22 – Assessments Comparison</strong>
        </TableCell>
      </TableRow>
      <TableRow style={tableStyles.lightTealBackground}>
        <TableCell>
          <strong>Frameworks</strong>
        </TableCell>
        <TableCell colSpan={3}>
          <strong> Risk Mitigation </strong>
        </TableCell>
        <TableCell colSpan={3}>
          <strong> Data Management </strong>
        </TableCell>
      </TableRow>
      <TableRow style={{ fontWeight: 900, ...tableStyles.centerText }}>
        <TableCell></TableCell>
        <TableCell style={{ width: 90, fontWeight: "bold" }}>Breadth</TableCell>
        <TableCell style={{ width: 90, fontWeight: "bold" }}>Depth</TableCell>
        <TableCell style={{ width: 90, fontWeight: "bold" }}>Practicality</TableCell>
        <TableCell style={{ width: 90, fontWeight: "bold" }}>Breadth</TableCell>
        <TableCell style={{ width: 90, fontWeight: "bold" }}>Depth</TableCell>
        <TableCell style={{ width: 90, fontWeight: "bold" }}>Practicality</TableCell>
      </TableRow>
      {assessmentComparisonData.map((row) => (
        <>
          <TableRow style={tableStyles.centerText}>
            <TableCell style={{ ...tableStyles.verticalAlign, textAlign: "left", fontWeight: "bold" }} rowSpan={2}>
              {row.framework}
            </TableCell>
            <TableCell style={{ background: colors[row.riskMitigation.breadth], borderBottom: "none" }}>
              {row.riskMitigation.breadth}
            </TableCell>
            <TableCell style={{ background: colors[row.riskMitigation.depth], borderBottom: "none" }}>
              {row.riskMitigation.depth}
            </TableCell>
            <TableCell style={{ background: colors[row.riskMitigation.practicality], borderBottom: "none" }}>
              {row.riskMitigation.practicality}
            </TableCell>
            <TableCell style={{ background: colors[row.dataManagement.breadth], borderBottom: "none" }}>
              {row.dataManagement.breadth}
            </TableCell>
            <TableCell style={{ background: colors[row.dataManagement.depth], borderBottom: "none" }}>
              {row.dataManagement.depth}
            </TableCell>
            <TableCell
              style={{
                background: colors[row.dataManagement.practicality],
                borderBottom: "none",
              }}
            >
              {row.dataManagement.practicality}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              colSpan={3}
              style={{ ...tableStyles.centerText, background: colors[row.average.riskMitigation] }}
            >
              <strong>{row.average.riskMitigation}</strong>
            </TableCell>
            <TableCell
              colSpan={3}
              style={{ ...tableStyles.centerText, background: colors[row.average.dataManagement] }}
            >
              <strong>{row.average.dataManagement}</strong>
            </TableCell>
          </TableRow>
        </>
      ))}
    </TableWrapper>
  );
};

export { TableHeader, AssessmentTable, AssessmentComparisonTable };
