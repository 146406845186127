const CustomSelector: React.FC<{
  pageType: "page" | "post";
  value: string;
  setValue: (selectedValue: string) => void;
  sections: SelectorItem[];
}> = ({ pageType, value, setValue, sections }) => {
  return (
    <div style={{ float: "right", position: "absolute", bottom: 0, right: 0 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 10,
          marginBottom: pageType === "page" ? 0 : 6,
        }}
      >
        Jump to:
        <select name="options" onChange={(event) => setValue(event.target.value)} value={value}>
          <option value="" />
          {sections.map((section) => {
            return (
              <option key={section.anchor} value={section.anchor} disabled={section.active === false}>
                {section.title}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default CustomSelector;
