import aigfcSources from "../../data/blog/aigfcSources";

const Source: React.FC<{ endnoteId: string }> = ({ endnoteId }) => {
  const endnote = aigfcSources.find((source) => source.id === endnoteId);
  const { institution, report, reportLink, surveyed, date } = endnote;

  return (
    <li>
      "
      <a href={reportLink} target="_blank" rel="noreferrer">
        {report}
      </a>
      ," {institution} {date && ` (${date})`}
    </li>
  );
};

export default Source;
