import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import tableStyles from "../../../components/tableStyles";

const AIGovernanceTable: React.FC = () => {
  return (
    <div className="table">
      <TableContainer style={tableStyles.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow style={{ ...tableStyles.tealBackground, ...tableStyles.centerText }}>
              <TableCell colSpan={3} align="center">
                <strong>Table 3.1</strong>
              </TableCell>
            </TableRow>
            <TableRow style={tableStyles.centerText}>
              <TableCell></TableCell>
              <TableCell>
                <strong>High commitment (Leader)</strong>
              </TableCell>
              <TableCell>
                <strong>Low commitment (Non-leader)</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <strong>High program maturity</strong>
              </TableCell>
              <TableCell style={tableStyles.centerText}>49%</TableCell>
              <TableCell style={tableStyles.centerText}>23%</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Low program maturity</strong>
              </TableCell>
              <TableCell style={tableStyles.centerText}>30%</TableCell>
              <TableCell style={tableStyles.centerText}>11%</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AIGovernanceTable;
