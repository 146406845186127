const frameworksData = [
  {
    framework: "OECD Framework for the Classification of AI Systems",
    tables: [
      {
        tableNumber: "4.2",
        tableTitle: "Risk Mitigation",
        rows: [
          {
            score: 10,
            criteria: "Breadth",
            details: [
              "Valid and reliable (e.g., p. 27)",
              "Accountable and transparent (e.g., p. 27)",
              "Fair (e.g., p. 28)",
              "Safe (e.g., p. 32)",
              "Secure and resilient (e.g., p. 34)",
              "Privacy-enhanced (e.g., p. 38)",
              "Explainable and interpretable (e.g., p. 43)",
            ],
          },
          {
            score: 6,
            criteria: "Depth",
            details:
              "A fair amount of detail is given to mitigating risks associated with data, such as bias, data privacy, and transparency. Other risks, such as to well-being and society as covered under People & Planet have far less detail.",
          },
          {
            score: 3,
            criteria: "Practicality",
            details:
              "Even among the most detailed practices outlined, relatively little of this would be immediately usable to mitigate risks. The content is more descriptive than procedural.",
          },
          {
            score: 6.3,
            criteria: "Average score",
            details: null,
          },
        ],
      },
      {
        tableNumber: "4.3",
        tableTitle: "Data Management",
        rows: [
          {
            score: 5.5,
            criteria: "Breadth",
            details: [
              "Data Storage & Operations (e.g., p. 36)",
              "Data Security (e.g., p. 38)",
              "Data Quality (e.g., p. 39)",
              "Data Integration & Interoperability (e.g., p. 40)",
              "Data Modeling & Design (e.g., p. 40)",
              "Metadata (e.g., p. 41)",
            ],
          },
          {
            score: 6,
            criteria: "Depth",
            details:
              "For each characteristic under Data & Input, many elements and key terms are identified with details. However, the amount of detail varies widely.",
          },
          {
            score: 5,
            criteria: "Practicality",
            details:
              "Responsibilities related to data collection and checking Data Quality are practical, but most other practices are more descriptive than procedural.",
          },
          {
            score: 5.5,
            criteria: "Average score",
            details: null,
          },
        ],
      },
    ],
  },
  {
    framework: "NIST AI Risk Management Framework",
    tables: [
      {
        tableNumber: "4.5",
        tableTitle: "Risk Management",
        rows: [
          {
            score: 10,
            criteria: "Breadth",
            details: [
              "Valid and reliable (i.e., p. 13)",
              "Safe (e.g., p. 14)",
              "Secure and resilient (e.g., p. 15)",
              "Accountable and transparent (e.g., p. 15)",
              "Explainable and interpretable (e.g., p. 16)",
              "Privacy-enhanced (e.g., p. 17)",
              "Fair (e.g., p. 18)",
            ],
          },
          {
            score: 9,
            criteria: "Depth",
            details:
              "Includes an introduction to the scope of each risk with key terms, roles, and responsibilities.  Actions and outcomes for four core functions are outlined in sequence.",
          },
          {
            score: 8,
            criteria: "Practicality",
            details: `A thorough range of actions and outcomes in a clear, logical sequence is provided for each function, but their lack of detail limits their direct implementability.  The <a href="https://airc.nist.gov/AI_RMF_Knowledge_Base/Playbook">AI RMF Playbook</a> is an especially useful supplementary resource for this purpose.<sup>12</sup>`,
          },
          {
            score: 9,
            criteria: "Average score",
            details: null,
          },
        ],
      },
      {
        tableNumber: "4.6",
        tableTitle: "Data Management",
        rows: [
          {
            score: 1.8,
            criteria: "Breadth",
            details: ["Data Quality (e.g., p. 27)", "Data Security (e.g., p. 30)"],
          },
          {
            score: 2,
            criteria: "Depth",
            details:
              "There is minimal explicit focus on Data Management practices, although Data Quality and Data Security practices are implied in relation to specific risks.",
          },
          {
            score: 2,
            criteria: "Practicality",
            details:
              "Most of the relevant guidance offered is in the overview for risks rather than as a practice in the four core functions.",
          },
          {
            score: 1.9,
            criteria: "Average score",
            details: null,
          },
        ],
      },
    ],
  },
  {
    framework: "Singapore Model AI Governance Framework",
    tables: [
      {
        tableNumber: "4.8",
        tableTitle: "Risk Mitigation",
        rows: [
          {
            score: 7.1,
            criteria: "Breadth",
            details: [
              "Fair (e.g., p. 38)",
              "Explainable and interpretable (e.g., p. 44)",
              "Valid and reliable (e.g., p. 46)",
              "Secure and resilient (e.g., p. 47)",
              "Accountable and transparent (e.g., p. 48)",
            ],
          },
          {
            score: 5,
            criteria: "Depth",
            details:
              "Trustworthy AI features are introduced with moderate levels of detail on average but with high variance between them; explainability has more than twice as much coverage as robustness.",
          },
          {
            score: 6,
            criteria: "Practicality",
            details:
              "Some specific actions or steps are included for most of the trustworthiness features covered. A brief real-world case study on Pymetrics managing bias and guidance on using the probability-severity of harm matrix are also offered.",
          },
          {
            score: 6,
            criteria: "Average score",
            details: null,
          },
        ],
      },
      {
        tableNumber: "4.9",
        tableTitle: "Data Management",
        rows: [
          {
            score: 0.9,
            criteria: "Breadth",
            details: ["Data Quality (e.g., p. 38)"],
          },
          {
            score: 5,
            criteria: "Depth",
            details:
              "Moderate coverage of Data Quality considerations is offered, such as data lineage, keeping a data provenance record, and reviewing dataset quality.",
          },
          {
            score: 3,
            criteria: "Practicality",
            details:
              "Guidance is largely given within the context of model development and is descriptive rather than procedural. However, the Data Quality considerations covered would likely apply to AI deployment and operation.",
          },
          {
            score: 3,
            criteria: "Average score",
            details: null,
          },
        ],
      },
    ],
  },
  {
    framework: "The Council of Europe's HUDERAF for AI Systems",
    tables: [
      {
        tableNumber: "4.11",
        tableTitle: "Risk Mitigation",
        rows: [
          {
            score: 10,
            criteria: "Breadth",
            details: [
              "Safe (e.g., p. 284)",
              "Fair (e.g., p. 285)",
              "Explainable and interpretable (e.g., p. 286)",
              "Valid and reliable (e.g., p. 285)",
              "Secure and resilient (e.g., p. 284)",
              "Accountable and transparent (e.g., p. 285)",
              "Privacy-enhanced (e.g., p. 288)",
            ],
          },
          {
            score: 6,
            criteria: "Depth",
            details:
              "Substantial depth is offered on what human rights, democracy, and rule of law specifically entail (e.g., p. 23-46) and how potential adverse impacts (risks) can be identified, assessed, and generally mitigated (e.g., p. 238-250). However, no guidance is given on how specific risks may be mitigated on the basis that 'specifying the goals [of an assurance case for mitigating risks] and operationalizing the relevant properties is highly contextual.'",
          },
          {
            score: 6,
            criteria: "Practicality",
            details:
              "Extensive step-by-step instructions, corresponding resources, and illustrative examples are offered to identify, evaluate, and mitigate AI risks. Although this guidance thoroughly supports businesses in conducting their own risk assessments, the lack of detailed guidance on practices or risk mitigation significantly undermines the degree to which businesses can directly use this framework to mitigate risks in practice.",
          },
          {
            score: 7.3,
            criteria: "Average score",
            details: null,
          },
        ],
      },
      {
        tableNumber: "4.12",
        tableTitle: "Data Management",
        rows: [
          {
            score: 2.9,
            criteria: "Breadth",
            details: ["Data Quality (e.g., p. 287)", "Data Security (e.g., p. 288)"],
          },
          {
            score: 3,
            criteria: "Depth",
            details:
              "Although Data Management practices are referred to or implied throughout the framework, most explicit Data Management practices are only explored briefly as goals for the framework.",
          },
          {
            score: 4,
            criteria: "Practicality",
            details:
              "The most practical guidance offered is for identifying risks over the data lifecycle as part of a preliminary context-based based analysis for which there is a step-by-step template (e.g., p. 106). Most of the guidance associated with Data Quality and Data Security practices isn't focused on actions.",
          },
          {
            score: 3.3,
            criteria: "Average score",
            details: null,
          },
        ],
      },
    ],
  },
  {
    framework: "Microsoft's Responsible AI Standards",
    tables: [
      {
        tableNumber: "4.14",
        tableTitle: "Risk Mitigation",
        rows: [
          {
            score: 8.6,
            criteria: "Breadth",
            details: [
              "Safe (e.g., p. 21)",
              "Fair (e.g., p. 13)",
              "Explainable and interpretable (e.g., p. 9)",
              "Valid and reliable (e.g., p. 24)",
              "Secure and resilient (e.g., p. 23)",
              "Accountable and transparent (e.g., p. 9)",
            ],
          },
          {
            score: 7,
            criteria: "Depth",
            details:
              "Several pages of instructions related to most risks covered, including several goals for which a series of requirements, tools, and practices are identified. However, much of this specifically relates to model training and testing rather than deployment or operation, and very little guidance is offered on understanding risks themselves.",
          },
          {
            score: 8,
            criteria: "Practicality",
            details:
              "The format of guidance is nearly entirely practice-focused, with specific targets and actions being outlined in sequence.",
          },
          {
            score: 7.9,
            criteria: "Average score",
            details: null,
          },
        ],
      },
      {
        tableNumber: "4.15",
        tableTitle: "Data Management",
        rows: [
          {
            score: 1.8,
            criteria: "Breadth",
            details: ["Data Governance (e.g., p. 7)", "Data Quality (e.g., p. 13)"],
          },
          {
            score: 1,
            criteria: "Depth",
            details:
              "Nearly all references to data explicitly relate to model training or testing. Some guidance on Data Governance is relevant across the AI lifecycle.",
          },
          {
            score: 3,
            criteria: "Practicality",
            details:
              "The relevant guidance on Data Governance offers actionable, though brief, instructions on governing data across the AI lifecycle.",
          },
          {
            score: 1.9,
            criteria: "Average score",
            details: null,
          },
        ],
      },
    ],
  },
  {
    framework: "AWS Cloud Adoption Framework for AI, ML, and Gen AI",
    tables: [
      {
        tableNumber: "4.17",
        tableTitle: "Risk Mitigation",
        rows: [
          {
            score: 8.6,
            criteria: "Breadth",
            details: [
              "Fair (e.g., p. 39)",
              "Explainable and interpretable (e.g., p. 28)",
              "Valid and reliable (e.g., p. 37)",
              "Secure and resilient (e.g., p. 40)",
              "Accountable and transparent (e.g., p. 42)",
              "Privacy-enhanced (e.g., p. 42)",
            ],
          },
          {
            score: 5,
            criteria: "Depth",
            details:
              "Under the Security perspective, considerable detail is offered on various risks. However, this detail is dominantly relevant to model training. Outside of this, detailed coverage is very limited.",
          },
          {
            score: 6,
            criteria: "Practicality",
            details:
              "Guidance under the Security perspective is highly practical, but, as corresponding with our assessment of depth, guidance outside of this is largely limited to basic references and descriptions.",
          },
          {
            score: 6.5,
            criteria: "Average score",
            details: null,
          },
        ],
      },
      {
        tableNumber: "4.18",
        tableTitle: "Data Management",
        rows: [
          {
            score: 7.3,
            criteria: "Breadth",
            details: [
              "Data Quality (e.g., p. 26)",
              "Metadata (e.g., p. 26)",
              "Data Storage & Operations (e.g., p. 32)",
              "Data Architecture (e.g., p. 33)",
              "Data Warehousing & Business Intelligence (e.g., p. 34)",
              "Data Modeling & Design (e.g., 36)",
              "Data Integration & Interoperability (e.g., p. 36)",
              "Data Security (e.g., p. 44)",
            ],
          },
          {
            score: 8,
            criteria: "Depth",
            details:
              "Although the amount of detail offered on any one area of Data Management varies greatly, much of the content from the Governance, Platform, and Security sections explains how Data Management connects to AI with a high level of specificity.",
          },
          {
            score: 6,
            criteria: "Practicality",
            details:
              "There's a mix of descriptive and procedural guidance. The document is not formatted with direct practical application in mind but nonetheless specifies many actions that can be taken.",
          },
          {
            score: 7.1,
            criteria: "Average score",
            details: null,
          },
        ],
      },
    ],
  },
  {
    framework: "Google's Secure AI Framework Approach",
    tables: [
      {
        tableNumber: "4.20",
        tableTitle: "Risk Mitigation",
        rows: [
          {
            score: 4.3,
            criteria: "Breadth",
            details: ["Safe (e.g., p. 6)", "Secure and resilient (e.g., p. 4)", "Privacy-enhanced (e.g., p. 5)"],
          },
          {
            score: 5,
            criteria: "Depth",
            details:
              "Guidance for secure and resilient AI is offered in moderate to high depth. Various other risks, however, are mentioned only as examples or in a single substantive sentence.",
          },
          {
            score: 6,
            criteria: "Practicality",
            details:
              "The guidance related to secure and resilient AI is action-focused, but much of it is generic, and there's no clear sequence of actions.",
          },
          {
            score: 5.1,
            criteria: "Average score",
            details: null,
          },
        ],
      },
      {
        tableNumber: "4.21",
        tableTitle: "Data Management",
        rows: [
          {
            score: 2.7,
            criteria: "Breadth",
            details: ["Data Governance (e.g., p. 5)", "Data Quality (e.g., p. 287)", "Data Security (e.g., p. 5)"],
          },
          {
            score: 2,
            criteria: "Depth",
            details:
              "The primary guidance on Data Management relates to an overview of Data Governance for training data. Very limited guidance is otherwise offered.",
          },
          {
            score: 1,
            criteria: "Practicality",
            details:
              "The guidance on Data Governance is largely descriptive. Only one action-based piece of guidance for Data Security is offered.",
          },
          {
            score: 1.9,
            criteria: "Average score",
            details: null,
          },
        ],
      },
    ],
  },
  {
    framework: "Google's Secure AI Framework Approach",
    tables: [
      {
        tableNumber: "4.20",
        tableTitle: "Risk Mitigation",
        rows: [
          {
            score: 4.3,
            criteria: "Breadth",
            details: ["Safe (e.g., p. 6)", "Secure and resilient (e.g., p. 4)", "Privacy-enhanced (e.g., p. 5)"],
          },
          {
            score: 5,
            criteria: "Depth",
            details:
              "Guidance for secure and resilient AI is offered in moderate to high depth. Various other risks, however, are mentioned only as examples or in a single substantive sentence.",
          },
          {
            score: 6,
            criteria: "Practicality",
            details:
              "The guidance related to secure and resilient AI is action-focused, but much of it is generic, and there's no clear sequence of actions.",
          },
          {
            score: 5.1,
            criteria: "Average score",
            details: null,
          },
        ],
      },
      {
        tableNumber: "4.21",
        tableTitle: "Data Management",
        rows: [
          {
            score: 2.7,
            criteria: "Breadth",
            details: ["Data Governance (e.g., p. 5)", "Data Quality (e.g., p. 287)", "Data Security (e.g., p. 5)"],
          },
          {
            score: 2,
            criteria: "Depth",
            details:
              "The primary guidance on Data Management relates to an overview of Data Governance for training data. Very limited guidance is otherwise offered.",
          },
          {
            score: 1,
            criteria: "Practicality",
            details:
              "The guidance on Data Governance is largely descriptive. Only one action-based piece of guidance for Data Security is offered.",
          },
          {
            score: 1.9,
            criteria: "Average score",
            details: null,
          },
        ],
      },
    ],
  },
];

const assessmentComparisonData = [
  {
    framework: "OECD Framework for the Classification of AI Systems",
    riskMitigation: {
      breadth: 10,
      depth: 6,
      practicality: 3,
    },
    dataManagement: {
      breadth: 5.5,
      depth: 6,
      practicality: 5,
    },
    average: {
      riskMitigation: 6.3,
      dataManagement: 5.5,
    },
  },
  {
    framework: "NIST AI Risk Management Framework",
    riskMitigation: {
      breadth: 10,
      depth: 9,
      practicality: 8,
    },
    dataManagement: {
      breadth: 1.8,
      depth: 2,
      practicality: 2,
    },
    average: {
      riskMitigation: 9,
      dataManagement: 1.9,
    },
  },
  {
    framework: "Singapore Model AI Governance Framework",
    riskMitigation: {
      breadth: 7.1,
      depth: 5,
      practicality: 6,
    },
    dataManagement: {
      breadth: 0.9,
      depth: 5,
      practicality: 3,
    },
    average: {
      riskMitigation: 6,
      dataManagement: 3,
    },
  },
  {
    framework: "The Alan Turing Institute's Assurance Framework for AI Systems",
    riskMitigation: {
      breadth: 10,
      depth: 6,
      practicality: 6,
    },
    dataManagement: {
      breadth: 2.9,
      depth: 3,
      practicality: 4,
    },
    average: {
      riskMitigation: 7.3,
      dataManagement: 3.3,
    },
  },
  {
    framework: "Microsoft Responsible AI Standard, v2",
    riskMitigation: {
      breadth: 8.6,
      depth: 7,
      practicality: 8,
    },
    dataManagement: {
      breadth: 1.8,
      depth: 1,
      practicality: 3,
    },
    average: {
      riskMitigation: 7.9,
      dataManagement: 1.9,
    },
  },
  {
    framework: "AWS Cloud Adoption Framework for AI, ML, and Gen AI",
    riskMitigation: {
      breadth: 8.6,
      depth: 5,
      practicality: 6,
    },
    dataManagement: {
      breadth: 7.3,
      depth: 8,
      practicality: 6,
    },
    average: {
      riskMitigation: 6.5,
      dataManagement: 7.1,
    },
  },
  {
    framework: "Google's Secure AI Framework Approach",
    riskMitigation: {
      breadth: 4.3,
      depth: 5,
      practicality: 6,
    },
    dataManagement: {
      breadth: 2.7,
      depth: 2,
      practicality: 1,
    },
    average: {
      riskMitigation: 5.1,
      dataManagement: 1.9,
    },
  },
];

const colors = {
  0.9: "#ff0000",
  1: "#ff0606",
  1.8: "#fe3636",
  1.9: "#ff3e3e",
  2: "#ff3e3e",
  2.7: "#ff6f6f",
  2.9: "#ff7c7c",
  3: "#ff8282",
  3.3: "#ff9595",
  4: "#ffc0c0",
  4.3: "#ffd3d3",
  5: "#fff",
  5.1: "#fafffa",
  5.5: "#e6ffe6",
  6: "#cdffcd",
  6.3: "#bdffbd",
  6.5: "#b3ffb3",
  7: "#9aff9a",
  7.1: "#94ff94",
  7.3: "#8aff8a",
  7.9: "#6cff6c",
  8: "#66ff66",
  8.6: "#48ff48",
  9: "#33ff33",
  10: "#00ff00",
};

export { frameworksData, colors, assessmentComparisonData };
