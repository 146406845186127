import { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import MultiPriceProductPage from "../../components/product-pages/MultiPriceProductPage";

import ExtendedDescription from "../../components/ExtendedDescription";
import CustomModal from "../../components/CustomModal";
import VisualDescription from "../../components/product-pages/VisualDescription";
import ProductTestimonials from "../../components/product-testimonials/ProductTestimonials";

import testimonials from "../../data/testimonials";

const PRODUCT = "Resume & LinkedIn Review";

export default function ResumeReview() {
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const desktop = useMediaQuery("(min-width:640px)");

  const handleShowClick = (image, alt) => {
    setImage(image);
    setAlt(alt);
    setShowModal(!showModal);
  };

  return (
    <>
      {showModal && <CustomModal image={image} alt={alt} handleShowClick={handleShowClick} />}
      <MultiPriceProductPage
        PRODUCT={PRODUCT}
        tealBox={tealBox}
        extendedDescription={extendedDescription}
        description={description}
        rest={rest(desktop)}
      />
      ;
    </>
  );
}

const description = (
  <div className="gray-box" id="product-description">
    <h4>Description</h4>
    <VisualDescription product={PRODUCT} />
  </div>
);

const tealBox = (
  <div className="teal-box cdmp" id="product-testimonials">
    <h4>Results</h4>
    <div className="blog">
      <p>Each Resume Review results in a document with the following sections:</p>
      <p>
        <strong>Goals</strong> — outlines our understanding of your unique career goals
      </p>
      <p>
        <strong>Action items</strong> — describes the specific steps you can take to improve your resume and LinkedIn
      </p>
      <p>
        <strong>Recommended qualifications</strong> — list of recommended skills and credentials
      </p>
      <p>
        <strong>Jobs near you</strong> — list of available roles in your area that match your specifications
      </p>
      <p>
        <strong>Remote jobs</strong> — list of available remote roles that match your specifications
      </p>
    </div>
  </div>
);

const extendedDescription = (
  <div className="extended-description-container">
    <ExtendedDescription
      description={
        <div className="extended-description">
          <p>
            Our Career Coach will review your resume and LinkedIn profile to provide you with the
            <strong>best advice</strong> for the <strong>next step</strong> in your career.{" "}
            <strong>Take the stress out of applications</strong> — our Career Coach will help you craft your resume and
            LinkedIn profile.
          </p>
          <p>
            Our Coach will work <strong>asynchronously</strong> to help you build your job search documents. For more{" "}
            <Link to="/products/career-coaching" target="_blank">
              <strong>Career Coaching</strong>
            </Link>
            , be sure to book a <strong>Zoom session</strong> with our one of our experts.
          </p>
          <p>
            You will receive an <strong>action-oriented pdf</strong> that will help you make{" "}
            <strong>specific improvements</strong> to your job search documents.
          </p>
        </div>
      }
    />
  </div>
);

const rest = (desktop) => {
  const testimonialsToShow = testimonials.filter((testimonial) => testimonial.id.includes("resume-review"));
  testimonialsToShow.push(testimonials.find((testimonial) => testimonial.id === "general-012"));

  return (
    <>
      <div
        className="white-outline-box"
        style={{
          maxWidth: "1400px",
          padding: "30px 40px",
          margin: "40px auto",
        }}
      >
        <div style={{ maxWidth: 1200, margin: "auto", textAlign: "center" }}>
          <p style={{ marginBottom: ".5em", textWrap: "balance" }}>
            We feel that <strong>Resume & LinkedIn Review offers immense value</strong> whether you're just starting
            out, transitioning into a data-related career, or moving to a better opportunity. Let us help you accelerate
            your career.
          </p>
          <p>
            Send us an email at{" "}
            <a href="mailto:coaching@datastrategypros.com?subject=Resume%20Review%20inquiry">
              coaching@datastrategypros.com
            </a>{" "}
            to discuss whether <strong>Resume Review</strong> is a good next step for you.
          </p>
        </div>
      </div>

      {!desktop && (
        <div className="teal-box" style={{ background: "#efefef", marginBottom: 40 }}>
          <div className="center-text-box">
            <div className="blog">
              <h4>Benefits</h4>
              <ul>
                <li>
                  Clear instruction from an experienced professional on how to write <strong>your best resume</strong>{" "}
                  and LinkedIn profile
                </li>
                <li>
                  <strong>1-2 page pdf</strong> that offers detailed guidance, including goals, action items, available
                  opportunities, and next steps
                </li>
                <li>
                  Tips and tricks to <strong>modernize your online presence</strong> for a{" "}
                  <strong>significant boost</strong> to application success
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      <ProductTestimonials testimonialsToShow={testimonialsToShow} />
    </>
  );
};
